/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiInterfaceVenusGetPoDataPost } from '../fn/ve-nus-interface/api-interface-venus-get-po-data-post';
import { ApiInterfaceVenusGetPoDataPost$Params } from '../fn/ve-nus-interface/api-interface-venus-get-po-data-post';
import { apiInterfaceVenusGetPoFinalFileGet } from '../fn/ve-nus-interface/api-interface-venus-get-po-final-file-get';
import { ApiInterfaceVenusGetPoFinalFileGet$Params } from '../fn/ve-nus-interface/api-interface-venus-get-po-final-file-get';
import { apiInterfaceVenusGetPoFinalFileNameGet } from '../fn/ve-nus-interface/api-interface-venus-get-po-final-file-name-get';
import { ApiInterfaceVenusGetPoFinalFileNameGet$Params } from '../fn/ve-nus-interface/api-interface-venus-get-po-final-file-name-get';
import { VeNusPoFinalFileItem } from '../models/ve-nus-po-final-file-item';
import { VeNusPoReferenceDataItem } from '../models/ve-nus-po-reference-data-item';

@Injectable({ providedIn: 'root' })
export class VeNusInterfaceAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInterfaceVenusGetPoDataPost()` */
  static readonly ApiInterfaceVenusGetPoDataPostPath = '/api/interface/venus/GetPOData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceVenusGetPoDataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceVenusGetPoDataPost$Response(params?: ApiInterfaceVenusGetPoDataPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<VeNusPoReferenceDataItem>>> {
    return apiInterfaceVenusGetPoDataPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceVenusGetPoDataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceVenusGetPoDataPost(params?: ApiInterfaceVenusGetPoDataPost$Params, context?: HttpContext): Observable<Array<VeNusPoReferenceDataItem>> {
    return this.apiInterfaceVenusGetPoDataPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<VeNusPoReferenceDataItem>>): Array<VeNusPoReferenceDataItem> => r.body)
    );
  }

  /** Path part for operation `apiInterfaceVenusGetPoFinalFileNameGet()` */
  static readonly ApiInterfaceVenusGetPoFinalFileNameGetPath = '/api/interface/venus/GetPOFinalFileName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceVenusGetPoFinalFileNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceVenusGetPoFinalFileNameGet$Response(params?: ApiInterfaceVenusGetPoFinalFileNameGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiInterfaceVenusGetPoFinalFileNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceVenusGetPoFinalFileNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceVenusGetPoFinalFileNameGet(params?: ApiInterfaceVenusGetPoFinalFileNameGet$Params, context?: HttpContext): Observable<string> {
    return this.apiInterfaceVenusGetPoFinalFileNameGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiInterfaceVenusGetPoFinalFileGet()` */
  static readonly ApiInterfaceVenusGetPoFinalFileGetPath = '/api/interface/venus/GetPOFinalFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceVenusGetPoFinalFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceVenusGetPoFinalFileGet$Response(params?: ApiInterfaceVenusGetPoFinalFileGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<VeNusPoFinalFileItem>> {
    return apiInterfaceVenusGetPoFinalFileGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceVenusGetPoFinalFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceVenusGetPoFinalFileGet(params?: ApiInterfaceVenusGetPoFinalFileGet$Params, context?: HttpContext): Observable<VeNusPoFinalFileItem> {
    return this.apiInterfaceVenusGetPoFinalFileGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<VeNusPoFinalFileItem>): VeNusPoFinalFileItem => r.body)
    );
  }

}
