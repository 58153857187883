/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiFileConversionStartConversionGet } from '../fn/file-conversion/api-file-conversion-start-conversion-get';
import { ApiFileConversionStartConversionGet$Params } from '../fn/file-conversion/api-file-conversion-start-conversion-get';

@Injectable({ providedIn: 'root' })
export class FileConversionAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiFileConversionStartConversionGet()` */
  static readonly ApiFileConversionStartConversionGetPath = '/api/FileConversion/StartConversion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileConversionStartConversionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileConversionStartConversionGet$Response(params?: ApiFileConversionStartConversionGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiFileConversionStartConversionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFileConversionStartConversionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileConversionStartConversionGet(params?: ApiFileConversionStartConversionGet$Params, context?: HttpContext): Observable<void> {
    return this.apiFileConversionStartConversionGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
