/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataVendorAllGet } from '../fn/master-vendor/api-master-data-vendor-all-get';
import { ApiMasterDataVendorAllGet$Params } from '../fn/master-vendor/api-master-data-vendor-all-get';
import { apiMasterDataVendorByCriteriaPost } from '../fn/master-vendor/api-master-data-vendor-by-criteria-post';
import { ApiMasterDataVendorByCriteriaPost$Params } from '../fn/master-vendor/api-master-data-vendor-by-criteria-post';
import { apiMasterDataVendorContainByItemSourceGet } from '../fn/master-vendor/api-master-data-vendor-contain-by-item-source-get';
import { ApiMasterDataVendorContainByItemSourceGet$Params } from '../fn/master-vendor/api-master-data-vendor-contain-by-item-source-get';
import { apiMasterDataVendorContainForBuyerWorksheetGet } from '../fn/master-vendor/api-master-data-vendor-contain-for-buyer-worksheet-get';
import { ApiMasterDataVendorContainForBuyerWorksheetGet$Params } from '../fn/master-vendor/api-master-data-vendor-contain-for-buyer-worksheet-get';
import { apiMasterDataVendorContainGet } from '../fn/master-vendor/api-master-data-vendor-contain-get';
import { ApiMasterDataVendorContainGet$Params } from '../fn/master-vendor/api-master-data-vendor-contain-get';
import { apiMasterDataVendorEmailListPost } from '../fn/master-vendor/api-master-data-vendor-email-list-post';
import { ApiMasterDataVendorEmailListPost$Params } from '../fn/master-vendor/api-master-data-vendor-email-list-post';
import { apiMasterDataVendorExpireVendorBlockchainGet } from '../fn/master-vendor/api-master-data-vendor-expire-vendor-blockchain-get';
import { ApiMasterDataVendorExpireVendorBlockchainGet$Params } from '../fn/master-vendor/api-master-data-vendor-expire-vendor-blockchain-get';
import { apiMasterDataVendorGet } from '../fn/master-vendor/api-master-data-vendor-get';
import { ApiMasterDataVendorGet$Params } from '../fn/master-vendor/api-master-data-vendor-get';
import { apiMasterDataVendorGetVendorContactGet } from '../fn/master-vendor/api-master-data-vendor-get-vendor-contact-get';
import { ApiMasterDataVendorGetVendorContactGet$Params } from '../fn/master-vendor/api-master-data-vendor-get-vendor-contact-get';
import { apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet } from '../fn/master-vendor/api-master-data-vendor-get-vendor-document-company-certificate-and-vat-register-get';
import { ApiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet$Params } from '../fn/master-vendor/api-master-data-vendor-get-vendor-document-company-certificate-and-vat-register-get';
import { apiMasterDataVendorGetVendorVeNusCertificateGet } from '../fn/master-vendor/api-master-data-vendor-get-vendor-ve-nus-certificate-get';
import { ApiMasterDataVendorGetVendorVeNusCertificateGet$Params } from '../fn/master-vendor/api-master-data-vendor-get-vendor-ve-nus-certificate-get';
import { apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet } from '../fn/master-vendor/api-master-data-vendor-get-vendor-venus-master-company-type-get';
import { ApiMasterDataVendorGetVendorVenusMasterCompanyTypeGet$Params } from '../fn/master-vendor/api-master-data-vendor-get-vendor-venus-master-company-type-get';
import { apiMasterDataVendorInfoByCodeGet } from '../fn/master-vendor/api-master-data-vendor-info-by-code-get';
import { ApiMasterDataVendorInfoByCodeGet$Params } from '../fn/master-vendor/api-master-data-vendor-info-by-code-get';
import { apiMasterDataVendorInfoContainForBuyerWorkSheetGet } from '../fn/master-vendor/api-master-data-vendor-info-contain-for-buyer-work-sheet-get';
import { ApiMasterDataVendorInfoContainForBuyerWorkSheetGet$Params } from '../fn/master-vendor/api-master-data-vendor-info-contain-for-buyer-work-sheet-get';
import { apiMasterDataVendorInfoContainGet } from '../fn/master-vendor/api-master-data-vendor-info-contain-get';
import { ApiMasterDataVendorInfoContainGet$Params } from '../fn/master-vendor/api-master-data-vendor-info-contain-get';
import { apiMasterDataVendorInfoFtaContainGet } from '../fn/master-vendor/api-master-data-vendor-info-fta-contain-get';
import { ApiMasterDataVendorInfoFtaContainGet$Params } from '../fn/master-vendor/api-master-data-vendor-info-fta-contain-get';
import { apiMasterDataVendorVendorBlockChainGet } from '../fn/master-vendor/api-master-data-vendor-vendor-block-chain-get';
import { ApiMasterDataVendorVendorBlockChainGet$Params } from '../fn/master-vendor/api-master-data-vendor-vendor-block-chain-get';
import { MasterVendorContractItem } from '../models/master-vendor-contract-item';
import { MasterVendorDocumentItem } from '../models/master-vendor-document-item';
import { MasterVendorEmailItem } from '../models/master-vendor-email-item';
import { MasterVendorInfoItem } from '../models/master-vendor-info-item';
import { MasterVendorItem } from '../models/master-vendor-item';
import { MasterVeNusVendorCertificateItem } from '../models/master-ve-nus-vendor-certificate-item';
import { VenusMasterVendorCompanyType } from '../models/venus-master-vendor-company-type';

@Injectable({ providedIn: 'root' })
export class MasterVendorAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataVendorGet()` */
  static readonly ApiMasterDataVendorGetPath = '/api/MasterData/Vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGet$Response(params?: ApiMasterDataVendorGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<MasterVendorItem>> {
    return apiMasterDataVendorGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGet(params?: ApiMasterDataVendorGet$Params, context?: HttpContext): Observable<MasterVendorItem> {
    return this.apiMasterDataVendorGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<MasterVendorItem>): MasterVendorItem => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorAllGet()` */
  static readonly ApiMasterDataVendorAllGetPath = '/api/MasterData/Vendor/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorAllGet$Response(params?: ApiMasterDataVendorAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorItem>>> {
    return apiMasterDataVendorAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorAllGet(params?: ApiMasterDataVendorAllGet$Params, context?: HttpContext): Observable<Array<MasterVendorItem>> {
    return this.apiMasterDataVendorAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorItem>>): Array<MasterVendorItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorByCriteriaPost()` */
  static readonly ApiMasterDataVendorByCriteriaPostPath = '/api/MasterData/Vendor/ByCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorByCriteriaPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorByCriteriaPost$Response(params?: ApiMasterDataVendorByCriteriaPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorItem>>> {
    return apiMasterDataVendorByCriteriaPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorByCriteriaPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorByCriteriaPost(params?: ApiMasterDataVendorByCriteriaPost$Params, context?: HttpContext): Observable<Array<MasterVendorItem>> {
    return this.apiMasterDataVendorByCriteriaPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorItem>>): Array<MasterVendorItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorContainGet()` */
  static readonly ApiMasterDataVendorContainGetPath = '/api/MasterData/Vendor/Contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorContainGet$Response(params?: ApiMasterDataVendorContainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorItem>>> {
    return apiMasterDataVendorContainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorContainGet(params?: ApiMasterDataVendorContainGet$Params, context?: HttpContext): Observable<Array<MasterVendorItem>> {
    return this.apiMasterDataVendorContainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorItem>>): Array<MasterVendorItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorContainForBuyerWorksheetGet()` */
  static readonly ApiMasterDataVendorContainForBuyerWorksheetGetPath = '/api/MasterData/Vendor/ContainForBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorContainForBuyerWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorContainForBuyerWorksheetGet$Response(params?: ApiMasterDataVendorContainForBuyerWorksheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorItem>>> {
    return apiMasterDataVendorContainForBuyerWorksheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorContainForBuyerWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorContainForBuyerWorksheetGet(params?: ApiMasterDataVendorContainForBuyerWorksheetGet$Params, context?: HttpContext): Observable<Array<MasterVendorItem>> {
    return this.apiMasterDataVendorContainForBuyerWorksheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorItem>>): Array<MasterVendorItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorContainByItemSourceGet()` */
  static readonly ApiMasterDataVendorContainByItemSourceGetPath = '/api/MasterData/Vendor/ContainByItemSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorContainByItemSourceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorContainByItemSourceGet$Response(params?: ApiMasterDataVendorContainByItemSourceGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorItem>>> {
    return apiMasterDataVendorContainByItemSourceGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorContainByItemSourceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorContainByItemSourceGet(params?: ApiMasterDataVendorContainByItemSourceGet$Params, context?: HttpContext): Observable<Array<MasterVendorItem>> {
    return this.apiMasterDataVendorContainByItemSourceGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorItem>>): Array<MasterVendorItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorInfoByCodeGet()` */
  static readonly ApiMasterDataVendorInfoByCodeGetPath = '/api/MasterData/Vendor/InfoByCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorInfoByCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoByCodeGet$Response(params?: ApiMasterDataVendorInfoByCodeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<MasterVendorInfoItem>> {
    return apiMasterDataVendorInfoByCodeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorInfoByCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoByCodeGet(params?: ApiMasterDataVendorInfoByCodeGet$Params, context?: HttpContext): Observable<MasterVendorInfoItem> {
    return this.apiMasterDataVendorInfoByCodeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<MasterVendorInfoItem>): MasterVendorInfoItem => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorInfoContainGet()` */
  static readonly ApiMasterDataVendorInfoContainGetPath = '/api/MasterData/Vendor/InfoContain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorInfoContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoContainGet$Response(params?: ApiMasterDataVendorInfoContainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorInfoItem>>> {
    return apiMasterDataVendorInfoContainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorInfoContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoContainGet(params?: ApiMasterDataVendorInfoContainGet$Params, context?: HttpContext): Observable<Array<MasterVendorInfoItem>> {
    return this.apiMasterDataVendorInfoContainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorInfoItem>>): Array<MasterVendorInfoItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorInfoContainForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataVendorInfoContainForBuyerWorkSheetGetPath = '/api/MasterData/Vendor/InfoContainForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorInfoContainForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoContainForBuyerWorkSheetGet$Response(params?: ApiMasterDataVendorInfoContainForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorInfoItem>>> {
    return apiMasterDataVendorInfoContainForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorInfoContainForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoContainForBuyerWorkSheetGet(params?: ApiMasterDataVendorInfoContainForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<MasterVendorInfoItem>> {
    return this.apiMasterDataVendorInfoContainForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorInfoItem>>): Array<MasterVendorInfoItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorInfoFtaContainGet()` */
  static readonly ApiMasterDataVendorInfoFtaContainGetPath = '/api/MasterData/Vendor/InfoFTAContain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorInfoFtaContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoFtaContainGet$Response(params?: ApiMasterDataVendorInfoFtaContainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorInfoItem>>> {
    return apiMasterDataVendorInfoFtaContainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorInfoFtaContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorInfoFtaContainGet(params?: ApiMasterDataVendorInfoFtaContainGet$Params, context?: HttpContext): Observable<Array<MasterVendorInfoItem>> {
    return this.apiMasterDataVendorInfoFtaContainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorInfoItem>>): Array<MasterVendorInfoItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorEmailListPost()` */
  static readonly ApiMasterDataVendorEmailListPostPath = '/api/MasterData/Vendor/EmailList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorEmailListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorEmailListPost$Response(params?: ApiMasterDataVendorEmailListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorEmailItem>>> {
    return apiMasterDataVendorEmailListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorEmailListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorEmailListPost(params?: ApiMasterDataVendorEmailListPost$Params, context?: HttpContext): Observable<Array<MasterVendorEmailItem>> {
    return this.apiMasterDataVendorEmailListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorEmailItem>>): Array<MasterVendorEmailItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorVendorBlockChainGet()` */
  static readonly ApiMasterDataVendorVendorBlockChainGetPath = '/api/MasterData/Vendor/VendorBlockChain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorVendorBlockChainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorVendorBlockChainGet$Response(params?: ApiMasterDataVendorVendorBlockChainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataVendorVendorBlockChainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorVendorBlockChainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorVendorBlockChainGet(params?: ApiMasterDataVendorVendorBlockChainGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataVendorVendorBlockChainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorExpireVendorBlockchainGet()` */
  static readonly ApiMasterDataVendorExpireVendorBlockchainGetPath = '/api/MasterData/Vendor/ExpireVendorBlockchain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorExpireVendorBlockchainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorExpireVendorBlockchainGet$Response(params?: ApiMasterDataVendorExpireVendorBlockchainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataVendorExpireVendorBlockchainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorExpireVendorBlockchainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorExpireVendorBlockchainGet(params?: ApiMasterDataVendorExpireVendorBlockchainGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataVendorExpireVendorBlockchainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorGetVendorContactGet()` */
  static readonly ApiMasterDataVendorGetVendorContactGetPath = '/api/MasterData/Vendor/GetVendorContact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorGetVendorContactGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorContactGet$Response(params?: ApiMasterDataVendorGetVendorContactGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorContractItem>>> {
    return apiMasterDataVendorGetVendorContactGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorGetVendorContactGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorContactGet(params?: ApiMasterDataVendorGetVendorContactGet$Params, context?: HttpContext): Observable<Array<MasterVendorContractItem>> {
    return this.apiMasterDataVendorGetVendorContactGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorContractItem>>): Array<MasterVendorContractItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet()` */
  static readonly ApiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGetPath = '/api/MasterData/Vendor/GetVendorDocumentCompanyCertificateAndVATRegister';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet$Response(params?: ApiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorDocumentItem>>> {
    return apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet(params?: ApiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet$Params, context?: HttpContext): Observable<Array<MasterVendorDocumentItem>> {
    return this.apiMasterDataVendorGetVendorDocumentCompanyCertificateAndVatRegisterGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorDocumentItem>>): Array<MasterVendorDocumentItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet()` */
  static readonly ApiMasterDataVendorGetVendorVenusMasterCompanyTypeGetPath = '/api/MasterData/Vendor/GetVendorVenusMasterCompanyType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet$Response(params?: ApiMasterDataVendorGetVendorVenusMasterCompanyTypeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<VenusMasterVendorCompanyType>>> {
    return apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet(params?: ApiMasterDataVendorGetVendorVenusMasterCompanyTypeGet$Params, context?: HttpContext): Observable<Array<VenusMasterVendorCompanyType>> {
    return this.apiMasterDataVendorGetVendorVenusMasterCompanyTypeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<VenusMasterVendorCompanyType>>): Array<VenusMasterVendorCompanyType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorGetVendorVeNusCertificateGet()` */
  static readonly ApiMasterDataVendorGetVendorVeNusCertificateGetPath = '/api/MasterData/Vendor/GetVendorVeNusCertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorGetVendorVeNusCertificateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorVeNusCertificateGet$Response(params?: ApiMasterDataVendorGetVendorVeNusCertificateGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVeNusVendorCertificateItem>>> {
    return apiMasterDataVendorGetVendorVeNusCertificateGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorGetVendorVeNusCertificateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorGetVendorVeNusCertificateGet(params?: ApiMasterDataVendorGetVendorVeNusCertificateGet$Params, context?: HttpContext): Observable<Array<MasterVeNusVendorCertificateItem>> {
    return this.apiMasterDataVendorGetVendorVeNusCertificateGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVeNusVendorCertificateItem>>): Array<MasterVeNusVendorCertificateItem> => r.body)
    );
  }

}
