/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataPortActiveByFreightModePost } from '../fn/master-port/api-master-data-port-active-by-freight-mode-post';
import { ApiMasterDataPortActiveByFreightModePost$Params } from '../fn/master-port/api-master-data-port-active-by-freight-mode-post';
import { apiMasterDataPortActiveDistinctPortNamePost } from '../fn/master-port/api-master-data-port-active-distinct-port-name-post';
import { ApiMasterDataPortActiveDistinctPortNamePost$Params } from '../fn/master-port/api-master-data-port-active-distinct-port-name-post';
import { apiMasterDataPortActivePost } from '../fn/master-port/api-master-data-port-active-post';
import { ApiMasterDataPortActivePost$Params } from '../fn/master-port/api-master-data-port-active-post';
import { apiMasterDataPortAddPost } from '../fn/master-port/api-master-data-port-add-post';
import { ApiMasterDataPortAddPost$Params } from '../fn/master-port/api-master-data-port-add-post';
import { apiMasterDataPortAllGet } from '../fn/master-port/api-master-data-port-all-get';
import { ApiMasterDataPortAllGet$Params } from '../fn/master-port/api-master-data-port-all-get';
import { apiMasterDataPortDeleteGet } from '../fn/master-port/api-master-data-port-delete-get';
import { ApiMasterDataPortDeleteGet$Params } from '../fn/master-port/api-master-data-port-delete-get';
import { apiMasterDataPortEditPost } from '../fn/master-port/api-master-data-port-edit-post';
import { ApiMasterDataPortEditPost$Params } from '../fn/master-port/api-master-data-port-edit-post';
import { apiMasterDataPortExportGet } from '../fn/master-port/api-master-data-port-export-get';
import { ApiMasterDataPortExportGet$Params } from '../fn/master-port/api-master-data-port-export-get';
import { apiMasterDataPortImportPost } from '../fn/master-port/api-master-data-port-import-post';
import { ApiMasterDataPortImportPost$Params } from '../fn/master-port/api-master-data-port-import-post';
import { apiMasterDataPortMaintainGet } from '../fn/master-port/api-master-data-port-maintain-get';
import { ApiMasterDataPortMaintainGet$Params } from '../fn/master-port/api-master-data-port-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterPortItem } from '../models/master-port-item';

@Injectable({ providedIn: 'root' })
export class MasterPortAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataPortActivePost()` */
  static readonly ApiMasterDataPortActivePostPath = '/api/MasterData/Port/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortActivePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortActivePost$Response(params?: ApiMasterDataPortActivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPortItem>>> {
    return apiMasterDataPortActivePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortActivePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortActivePost(params?: ApiMasterDataPortActivePost$Params, context?: HttpContext): Observable<Array<MasterPortItem>> {
    return this.apiMasterDataPortActivePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPortItem>>): Array<MasterPortItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortActiveByFreightModePost()` */
  static readonly ApiMasterDataPortActiveByFreightModePostPath = '/api/MasterData/Port/ActiveByFreightMode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortActiveByFreightModePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortActiveByFreightModePost$Response(params?: ApiMasterDataPortActiveByFreightModePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPortItem>>> {
    return apiMasterDataPortActiveByFreightModePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortActiveByFreightModePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortActiveByFreightModePost(params?: ApiMasterDataPortActiveByFreightModePost$Params, context?: HttpContext): Observable<Array<MasterPortItem>> {
    return this.apiMasterDataPortActiveByFreightModePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPortItem>>): Array<MasterPortItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortActiveDistinctPortNamePost()` */
  static readonly ApiMasterDataPortActiveDistinctPortNamePostPath = '/api/MasterData/Port/ActiveDistinctPortName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortActiveDistinctPortNamePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortActiveDistinctPortNamePost$Response(params?: ApiMasterDataPortActiveDistinctPortNamePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataPortActiveDistinctPortNamePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortActiveDistinctPortNamePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortActiveDistinctPortNamePost(params?: ApiMasterDataPortActiveDistinctPortNamePost$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataPortActiveDistinctPortNamePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortMaintainGet()` */
  static readonly ApiMasterDataPortMaintainGetPath = '/api/MasterData/Port/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortMaintainGet$Response(params?: ApiMasterDataPortMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPortItem>>> {
    return apiMasterDataPortMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortMaintainGet(params?: ApiMasterDataPortMaintainGet$Params, context?: HttpContext): Observable<Array<MasterPortItem>> {
    return this.apiMasterDataPortMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPortItem>>): Array<MasterPortItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortAllGet()` */
  static readonly ApiMasterDataPortAllGetPath = '/api/MasterData/Port/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortAllGet$Response(params?: ApiMasterDataPortAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPortItem>>> {
    return apiMasterDataPortAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortAllGet(params?: ApiMasterDataPortAllGet$Params, context?: HttpContext): Observable<Array<MasterPortItem>> {
    return this.apiMasterDataPortAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPortItem>>): Array<MasterPortItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortAddPost()` */
  static readonly ApiMasterDataPortAddPostPath = '/api/MasterData/Port/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPortAddPost$Response(params?: ApiMasterDataPortAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPortAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPortAddPost(params?: ApiMasterDataPortAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPortAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortEditPost()` */
  static readonly ApiMasterDataPortEditPostPath = '/api/MasterData/Port/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPortEditPost$Response(params?: ApiMasterDataPortEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPortEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPortEditPost(params?: ApiMasterDataPortEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPortEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortDeleteGet()` */
  static readonly ApiMasterDataPortDeleteGetPath = '/api/MasterData/Port/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortDeleteGet$Response(params?: ApiMasterDataPortDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPortDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortDeleteGet(params?: ApiMasterDataPortDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPortDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortImportPost()` */
  static readonly ApiMasterDataPortImportPostPath = '/api/MasterData/Port/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortImportPost$Response(params?: ApiMasterDataPortImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataPortImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortImportPost(params?: ApiMasterDataPortImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataPortImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPortExportGet()` */
  static readonly ApiMasterDataPortExportGetPath = '/api/MasterData/Port/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPortExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortExportGet$Response(params?: ApiMasterDataPortExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataPortExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPortExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPortExportGet(params?: ApiMasterDataPortExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataPortExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
