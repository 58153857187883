/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataUnitActiveForBuyerWorkSheetGet } from '../fn/master-unit/api-master-data-unit-active-for-buyer-work-sheet-get';
import { ApiMasterDataUnitActiveForBuyerWorkSheetGet$Params } from '../fn/master-unit/api-master-data-unit-active-for-buyer-work-sheet-get';
import { apiMasterDataUnitActiveGet } from '../fn/master-unit/api-master-data-unit-active-get';
import { ApiMasterDataUnitActiveGet$Params } from '../fn/master-unit/api-master-data-unit-active-get';

@Injectable({ providedIn: 'root' })
export class MasterUnitAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataUnitActiveGet()` */
  static readonly ApiMasterDataUnitActiveGetPath = '/api/MasterData/Unit/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataUnitActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataUnitActiveGet$Response(params?: ApiMasterDataUnitActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataUnitActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataUnitActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataUnitActiveGet(params?: ApiMasterDataUnitActiveGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataUnitActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataUnitActiveForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataUnitActiveForBuyerWorkSheetGetPath = '/api/MasterData/Unit/ActiveForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataUnitActiveForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataUnitActiveForBuyerWorkSheetGet$Response(params?: ApiMasterDataUnitActiveForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataUnitActiveForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataUnitActiveForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataUnitActiveForBuyerWorkSheetGet(params?: ApiMasterDataUnitActiveForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataUnitActiveForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
