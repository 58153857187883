/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestReqDataBrandForPrGet } from '../fn/req-data/api-request-req-data-brand-for-pr-get';
import { ApiRequestReqDataBrandForPrGet$Params } from '../fn/req-data/api-request-req-data-brand-for-pr-get';
import { apiRequestReqDataBrandGet } from '../fn/req-data/api-request-req-data-brand-get';
import { ApiRequestReqDataBrandGet$Params } from '../fn/req-data/api-request-req-data-brand-get';
import { apiRequestReqDataCategoryForPrGet } from '../fn/req-data/api-request-req-data-category-for-pr-get';
import { ApiRequestReqDataCategoryForPrGet$Params } from '../fn/req-data/api-request-req-data-category-for-pr-get';
import { apiRequestReqDataCategoryGet } from '../fn/req-data/api-request-req-data-category-get';
import { ApiRequestReqDataCategoryGet$Params } from '../fn/req-data/api-request-req-data-category-get';
import { apiRequestReqDataInitialVendorFromRfqSynergyGet } from '../fn/req-data/api-request-req-data-initial-vendor-from-rfq-synergy-get';
import { ApiRequestReqDataInitialVendorFromRfqSynergyGet$Params } from '../fn/req-data/api-request-req-data-initial-vendor-from-rfq-synergy-get';
import { apiRequestReqDataOnBehalfForPrGet } from '../fn/req-data/api-request-req-data-on-behalf-for-pr-get';
import { ApiRequestReqDataOnBehalfForPrGet$Params } from '../fn/req-data/api-request-req-data-on-behalf-for-pr-get';
import { apiRequestReqDataOnBehalfGet } from '../fn/req-data/api-request-req-data-on-behalf-get';
import { ApiRequestReqDataOnBehalfGet$Params } from '../fn/req-data/api-request-req-data-on-behalf-get';
import { apiRequestReqDataPlantForPrGet } from '../fn/req-data/api-request-req-data-plant-for-pr-get';
import { ApiRequestReqDataPlantForPrGet$Params } from '../fn/req-data/api-request-req-data-plant-for-pr-get';
import { apiRequestReqDataPlantGet } from '../fn/req-data/api-request-req-data-plant-get';
import { ApiRequestReqDataPlantGet$Params } from '../fn/req-data/api-request-req-data-plant-get';
import { apiRequestReqDataServiceForPrGet } from '../fn/req-data/api-request-req-data-service-for-pr-get';
import { ApiRequestReqDataServiceForPrGet$Params } from '../fn/req-data/api-request-req-data-service-for-pr-get';
import { apiRequestReqDataServiceGet } from '../fn/req-data/api-request-req-data-service-get';
import { ApiRequestReqDataServiceGet$Params } from '../fn/req-data/api-request-req-data-service-get';
import { apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-and-vendor-from-new-rfq-request-for-pr-get';
import { ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet$Params } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-and-vendor-from-new-rfq-request-for-pr-get';
import { apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-and-vendor-from-new-rfq-request-get';
import { ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet$Params } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-and-vendor-from-new-rfq-request-get';
import { apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-for-pr-get';
import { ApiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet$Params } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-for-pr-get';
import { apiRequestReqDataSuggestionVendorFromDailyInfoItemGet } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-get';
import { ApiRequestReqDataSuggestionVendorFromDailyInfoItemGet$Params } from '../fn/req-data/api-request-req-data-suggestion-vendor-from-daily-info-item-get';
import { apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-by-working-group-for-pr-get';
import { ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet$Params } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-by-working-group-for-pr-get';
import { apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-by-working-group-get';
import { ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet$Params } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-by-working-group-get';
import { apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-for-pr-get';
import { ApiRequestReqDataSynergyVendorFromRfqSynergyForPrGet$Params } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-for-pr-get';
import { apiRequestReqDataSynergyVendorFromRfqSynergyGet } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-get';
import { ApiRequestReqDataSynergyVendorFromRfqSynergyGet$Params } from '../fn/req-data/api-request-req-data-synergy-vendor-from-rfq-synergy-get';
import { apiRequestReqDataVendorFromNewRfqRequestGet } from '../fn/req-data/api-request-req-data-vendor-from-new-rfq-request-get';
import { ApiRequestReqDataVendorFromNewRfqRequestGet$Params } from '../fn/req-data/api-request-req-data-vendor-from-new-rfq-request-get';

@Injectable({ providedIn: 'root' })
export class ReqDataAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestReqDataPlantGet()` */
  static readonly ApiRequestReqDataPlantGetPath = '/api/request/req-data/Plant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataPlantGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataPlantGet$Response(params?: ApiRequestReqDataPlantGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataPlantGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataPlantGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataPlantGet(params?: ApiRequestReqDataPlantGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataPlantGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataPlantForPrGet()` */
  static readonly ApiRequestReqDataPlantForPrGetPath = '/api/request/req-data/PlantForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataPlantForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataPlantForPrGet$Response(params?: ApiRequestReqDataPlantForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataPlantForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataPlantForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataPlantForPrGet(params?: ApiRequestReqDataPlantForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataPlantForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataCategoryGet()` */
  static readonly ApiRequestReqDataCategoryGetPath = '/api/request/req-data/Category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataCategoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataCategoryGet$Response(params?: ApiRequestReqDataCategoryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataCategoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataCategoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataCategoryGet(params?: ApiRequestReqDataCategoryGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataCategoryGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataCategoryForPrGet()` */
  static readonly ApiRequestReqDataCategoryForPrGetPath = '/api/request/req-data/CategoryForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataCategoryForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataCategoryForPrGet$Response(params?: ApiRequestReqDataCategoryForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataCategoryForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataCategoryForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataCategoryForPrGet(params?: ApiRequestReqDataCategoryForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataCategoryForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataServiceGet()` */
  static readonly ApiRequestReqDataServiceGetPath = '/api/request/req-data/Service';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataServiceGet$Response(params?: ApiRequestReqDataServiceGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataServiceGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataServiceGet(params?: ApiRequestReqDataServiceGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataServiceGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataServiceForPrGet()` */
  static readonly ApiRequestReqDataServiceForPrGetPath = '/api/request/req-data/ServiceForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataServiceForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataServiceForPrGet$Response(params?: ApiRequestReqDataServiceForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataServiceForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataServiceForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataServiceForPrGet(params?: ApiRequestReqDataServiceForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataServiceForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSuggestionVendorFromDailyInfoItemGet()` */
  static readonly ApiRequestReqDataSuggestionVendorFromDailyInfoItemGetPath = '/api/request/req-data/SuggestionVendorFromDailyInfoItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSuggestionVendorFromDailyInfoItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemGet$Response(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSuggestionVendorFromDailyInfoItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSuggestionVendorFromDailyInfoItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemGet(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSuggestionVendorFromDailyInfoItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet()` */
  static readonly ApiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGetPath = '/api/request/req-data/SuggestionVendorFromDailyInfoItemForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet$Response(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSuggestionVendorFromDailyInfoItemForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataVendorFromNewRfqRequestGet()` */
  static readonly ApiRequestReqDataVendorFromNewRfqRequestGetPath = '/api/request/req-data/VendorFromNewRfqRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataVendorFromNewRfqRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataVendorFromNewRfqRequestGet$Response(params?: ApiRequestReqDataVendorFromNewRfqRequestGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataVendorFromNewRfqRequestGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataVendorFromNewRfqRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataVendorFromNewRfqRequestGet(params?: ApiRequestReqDataVendorFromNewRfqRequestGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataVendorFromNewRfqRequestGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataInitialVendorFromRfqSynergyGet()` */
  static readonly ApiRequestReqDataInitialVendorFromRfqSynergyGetPath = '/api/request/req-data/InitialVendorFromRfqSynergy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataInitialVendorFromRfqSynergyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataInitialVendorFromRfqSynergyGet$Response(params?: ApiRequestReqDataInitialVendorFromRfqSynergyGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataInitialVendorFromRfqSynergyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataInitialVendorFromRfqSynergyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataInitialVendorFromRfqSynergyGet(params?: ApiRequestReqDataInitialVendorFromRfqSynergyGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataInitialVendorFromRfqSynergyGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSynergyVendorFromRfqSynergyGet()` */
  static readonly ApiRequestReqDataSynergyVendorFromRfqSynergyGetPath = '/api/request/req-data/SynergyVendorFromRfqSynergy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSynergyVendorFromRfqSynergyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyGet$Response(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSynergyVendorFromRfqSynergyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSynergyVendorFromRfqSynergyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyGet(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSynergyVendorFromRfqSynergyGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet()` */
  static readonly ApiRequestReqDataSynergyVendorFromRfqSynergyForPrGetPath = '/api/request/req-data/SynergyVendorFromRfqSynergyForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet$Response(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSynergyVendorFromRfqSynergyForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet()` */
  static readonly ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGetPath = '/api/request/req-data/SynergyVendorFromRfqSynergyByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet$Response(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet()` */
  static readonly ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGetPath = '/api/request/req-data/SynergyVendorFromRfqSynergyByWorkingGroupForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet$Response(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet(params?: ApiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSynergyVendorFromRfqSynergyByWorkingGroupForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet()` */
  static readonly ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGetPath = '/api/request/req-data/SuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet$Response(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet()` */
  static readonly ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGetPath = '/api/request/req-data/SuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet$Response(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet(params?: ApiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataSuggestionVendorFromDailyInfoItemAndVendorFromNewRfqRequestForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataOnBehalfGet()` */
  static readonly ApiRequestReqDataOnBehalfGetPath = '/api/request/req-data/OnBehalf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataOnBehalfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataOnBehalfGet$Response(params?: ApiRequestReqDataOnBehalfGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataOnBehalfGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataOnBehalfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataOnBehalfGet(params?: ApiRequestReqDataOnBehalfGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataOnBehalfGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataOnBehalfForPrGet()` */
  static readonly ApiRequestReqDataOnBehalfForPrGetPath = '/api/request/req-data/OnBehalfForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataOnBehalfForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataOnBehalfForPrGet$Response(params?: ApiRequestReqDataOnBehalfForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataOnBehalfForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataOnBehalfForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataOnBehalfForPrGet(params?: ApiRequestReqDataOnBehalfForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataOnBehalfForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataBrandGet()` */
  static readonly ApiRequestReqDataBrandGetPath = '/api/request/req-data/Brand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataBrandGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataBrandGet$Response(params?: ApiRequestReqDataBrandGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataBrandGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataBrandGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataBrandGet(params?: ApiRequestReqDataBrandGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataBrandGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestReqDataBrandForPrGet()` */
  static readonly ApiRequestReqDataBrandForPrGetPath = '/api/request/req-data/BrandForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestReqDataBrandForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataBrandForPrGet$Response(params?: ApiRequestReqDataBrandForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestReqDataBrandForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestReqDataBrandForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestReqDataBrandForPrGet(params?: ApiRequestReqDataBrandForPrGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestReqDataBrandForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
