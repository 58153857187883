/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataRoleResponsibleActiveGet } from '../fn/master-role-responsible/api-master-data-role-responsible-active-get';
import { ApiMasterDataRoleResponsibleActiveGet$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-active-get';
import { apiMasterDataRoleResponsibleActiveRfqUserGet } from '../fn/master-role-responsible/api-master-data-role-responsible-active-rfq-user-get';
import { ApiMasterDataRoleResponsibleActiveRfqUserGet$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-active-rfq-user-get';
import { apiMasterDataRoleResponsibleAddPost } from '../fn/master-role-responsible/api-master-data-role-responsible-add-post';
import { ApiMasterDataRoleResponsibleAddPost$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-add-post';
import { apiMasterDataRoleResponsibleAllGet } from '../fn/master-role-responsible/api-master-data-role-responsible-all-get';
import { ApiMasterDataRoleResponsibleAllGet$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-all-get';
import { apiMasterDataRoleResponsibleDeleteGet } from '../fn/master-role-responsible/api-master-data-role-responsible-delete-get';
import { ApiMasterDataRoleResponsibleDeleteGet$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-delete-get';
import { apiMasterDataRoleResponsibleEditPost } from '../fn/master-role-responsible/api-master-data-role-responsible-edit-post';
import { ApiMasterDataRoleResponsibleEditPost$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-edit-post';
import { apiMasterDataRoleResponsibleMaintainGet } from '../fn/master-role-responsible/api-master-data-role-responsible-maintain-get';
import { ApiMasterDataRoleResponsibleMaintainGet$Params } from '../fn/master-role-responsible/api-master-data-role-responsible-maintain-get';
import { MasterRfqUserItem } from '../models/master-rfq-user-item';
import { MasterRoleResponsibleItem } from '../models/master-role-responsible-item';

@Injectable({ providedIn: 'root' })
export class MasterRoleResponsibleAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataRoleResponsibleActiveGet()` */
  static readonly ApiMasterDataRoleResponsibleActiveGetPath = '/api/MasterData/RoleResponsible/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleActiveGet$Response(params?: ApiMasterDataRoleResponsibleActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRoleResponsibleItem>>> {
    return apiMasterDataRoleResponsibleActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleActiveGet(params?: ApiMasterDataRoleResponsibleActiveGet$Params, context?: HttpContext): Observable<Array<MasterRoleResponsibleItem>> {
    return this.apiMasterDataRoleResponsibleActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRoleResponsibleItem>>): Array<MasterRoleResponsibleItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRoleResponsibleMaintainGet()` */
  static readonly ApiMasterDataRoleResponsibleMaintainGetPath = '/api/MasterData/RoleResponsible/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleMaintainGet$Response(params?: ApiMasterDataRoleResponsibleMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRoleResponsibleItem>>> {
    return apiMasterDataRoleResponsibleMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleMaintainGet(params?: ApiMasterDataRoleResponsibleMaintainGet$Params, context?: HttpContext): Observable<Array<MasterRoleResponsibleItem>> {
    return this.apiMasterDataRoleResponsibleMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRoleResponsibleItem>>): Array<MasterRoleResponsibleItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRoleResponsibleAllGet()` */
  static readonly ApiMasterDataRoleResponsibleAllGetPath = '/api/MasterData/RoleResponsible/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleAllGet$Response(params?: ApiMasterDataRoleResponsibleAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRoleResponsibleItem>>> {
    return apiMasterDataRoleResponsibleAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleAllGet(params?: ApiMasterDataRoleResponsibleAllGet$Params, context?: HttpContext): Observable<Array<MasterRoleResponsibleItem>> {
    return this.apiMasterDataRoleResponsibleAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRoleResponsibleItem>>): Array<MasterRoleResponsibleItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRoleResponsibleActiveRfqUserGet()` */
  static readonly ApiMasterDataRoleResponsibleActiveRfqUserGetPath = '/api/MasterData/RoleResponsible/ActiveRfqUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleActiveRfqUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleActiveRfqUserGet$Response(params?: ApiMasterDataRoleResponsibleActiveRfqUserGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRfqUserItem>>> {
    return apiMasterDataRoleResponsibleActiveRfqUserGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleActiveRfqUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleActiveRfqUserGet(params?: ApiMasterDataRoleResponsibleActiveRfqUserGet$Params, context?: HttpContext): Observable<Array<MasterRfqUserItem>> {
    return this.apiMasterDataRoleResponsibleActiveRfqUserGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRfqUserItem>>): Array<MasterRfqUserItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRoleResponsibleAddPost()` */
  static readonly ApiMasterDataRoleResponsibleAddPostPath = '/api/MasterData/RoleResponsible/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRoleResponsibleAddPost$Response(params?: ApiMasterDataRoleResponsibleAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataRoleResponsibleAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRoleResponsibleAddPost(params?: ApiMasterDataRoleResponsibleAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataRoleResponsibleAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRoleResponsibleEditPost()` */
  static readonly ApiMasterDataRoleResponsibleEditPostPath = '/api/MasterData/RoleResponsible/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRoleResponsibleEditPost$Response(params?: ApiMasterDataRoleResponsibleEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataRoleResponsibleEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRoleResponsibleEditPost(params?: ApiMasterDataRoleResponsibleEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataRoleResponsibleEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRoleResponsibleDeleteGet()` */
  static readonly ApiMasterDataRoleResponsibleDeleteGetPath = '/api/MasterData/RoleResponsible/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRoleResponsibleDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleDeleteGet$Response(params?: ApiMasterDataRoleResponsibleDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataRoleResponsibleDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRoleResponsibleDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRoleResponsibleDeleteGet(params?: ApiMasterDataRoleResponsibleDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataRoleResponsibleDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
