/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet } from '../fn/master-pur-group/api-master-data-pur-group-active-by-item-source-for-buyer-work-sheet-get';
import { ApiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-by-item-source-for-buyer-work-sheet-get';
import { apiMasterDataPurGroupActiveByMemberGet } from '../fn/master-pur-group/api-master-data-pur-group-active-by-member-get';
import { ApiMasterDataPurGroupActiveByMemberGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-by-member-get';
import { apiMasterDataPurGroupActiveByWorkingGroupForPrGet } from '../fn/master-pur-group/api-master-data-pur-group-active-by-working-group-for-pr-get';
import { ApiMasterDataPurGroupActiveByWorkingGroupForPrGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-by-working-group-for-pr-get';
import { apiMasterDataPurGroupActiveByWorkingGroupGet } from '../fn/master-pur-group/api-master-data-pur-group-active-by-working-group-get';
import { ApiMasterDataPurGroupActiveByWorkingGroupGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-by-working-group-get';
import { apiMasterDataPurGroupActiveForChangeGet } from '../fn/master-pur-group/api-master-data-pur-group-active-for-change-get';
import { ApiMasterDataPurGroupActiveForChangeGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-for-change-get';
import { apiMasterDataPurGroupActiveForChangeGetAllGet } from '../fn/master-pur-group/api-master-data-pur-group-active-for-change-get-all-get';
import { ApiMasterDataPurGroupActiveForChangeGetAllGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-for-change-get-all-get';
import { apiMasterDataPurGroupActiveForViewGet } from '../fn/master-pur-group/api-master-data-pur-group-active-for-view-get';
import { ApiMasterDataPurGroupActiveForViewGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-for-view-get';
import { apiMasterDataPurGroupActiveGet } from '../fn/master-pur-group/api-master-data-pur-group-active-get';
import { ApiMasterDataPurGroupActiveGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-active-get';
import { apiMasterDataPurGroupAddPost } from '../fn/master-pur-group/api-master-data-pur-group-add-post';
import { ApiMasterDataPurGroupAddPost$Params } from '../fn/master-pur-group/api-master-data-pur-group-add-post';
import { apiMasterDataPurGroupAllByWorkingGroupGet } from '../fn/master-pur-group/api-master-data-pur-group-all-by-working-group-get';
import { ApiMasterDataPurGroupAllByWorkingGroupGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-all-by-working-group-get';
import { apiMasterDataPurGroupAllGet } from '../fn/master-pur-group/api-master-data-pur-group-all-get';
import { ApiMasterDataPurGroupAllGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-all-get';
import { apiMasterDataPurGroupDeleteGet } from '../fn/master-pur-group/api-master-data-pur-group-delete-get';
import { ApiMasterDataPurGroupDeleteGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-delete-get';
import { apiMasterDataPurGroupEditPost } from '../fn/master-pur-group/api-master-data-pur-group-edit-post';
import { ApiMasterDataPurGroupEditPost$Params } from '../fn/master-pur-group/api-master-data-pur-group-edit-post';
import { apiMasterDataPurGroupExportGet } from '../fn/master-pur-group/api-master-data-pur-group-export-get';
import { ApiMasterDataPurGroupExportGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-export-get';
import { apiMasterDataPurGroupImportPost } from '../fn/master-pur-group/api-master-data-pur-group-import-post';
import { ApiMasterDataPurGroupImportPost$Params } from '../fn/master-pur-group/api-master-data-pur-group-import-post';
import { apiMasterDataPurGroupItemSourceAllGet } from '../fn/master-pur-group/api-master-data-pur-group-item-source-all-get';
import { ApiMasterDataPurGroupItemSourceAllGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-item-source-all-get';
import { apiMasterDataPurGroupMaintainGet } from '../fn/master-pur-group/api-master-data-pur-group-maintain-get';
import { ApiMasterDataPurGroupMaintainGet$Params } from '../fn/master-pur-group/api-master-data-pur-group-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterPurGroupItem } from '../models/master-pur-group-item';

@Injectable({ providedIn: 'root' })
export class MasterPurGroupAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataPurGroupActiveGet()` */
  static readonly ApiMasterDataPurGroupActiveGetPath = '/api/MasterData/PurGroup/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveGet$Response(params?: ApiMasterDataPurGroupActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveGet(params?: ApiMasterDataPurGroupActiveGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveByWorkingGroupGet()` */
  static readonly ApiMasterDataPurGroupActiveByWorkingGroupGetPath = '/api/MasterData/PurGroup/ActiveByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByWorkingGroupGet$Response(params?: ApiMasterDataPurGroupActiveByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByWorkingGroupGet(params?: ApiMasterDataPurGroupActiveByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveByWorkingGroupForPrGet()` */
  static readonly ApiMasterDataPurGroupActiveByWorkingGroupForPrGetPath = '/api/MasterData/PurGroup/ActiveByWorkingGroupForPR';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveByWorkingGroupForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByWorkingGroupForPrGet$Response(params?: ApiMasterDataPurGroupActiveByWorkingGroupForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveByWorkingGroupForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveByWorkingGroupForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByWorkingGroupForPrGet(params?: ApiMasterDataPurGroupActiveByWorkingGroupForPrGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveByWorkingGroupForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGetPath = '/api/MasterData/PurGroup/ActiveByItemSourceForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet$Response(params?: ApiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet(params?: ApiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveByItemSourceForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveForViewGet()` */
  static readonly ApiMasterDataPurGroupActiveForViewGetPath = '/api/MasterData/PurGroup/ActiveForView';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveForViewGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveForViewGet$Response(params?: ApiMasterDataPurGroupActiveForViewGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveForViewGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveForViewGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveForViewGet(params?: ApiMasterDataPurGroupActiveForViewGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveForViewGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveForChangeGet()` */
  static readonly ApiMasterDataPurGroupActiveForChangeGetPath = '/api/MasterData/PurGroup/ActiveForChange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveForChangeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveForChangeGet$Response(params?: ApiMasterDataPurGroupActiveForChangeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveForChangeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveForChangeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveForChangeGet(params?: ApiMasterDataPurGroupActiveForChangeGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveForChangeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveForChangeGetAllGet()` */
  static readonly ApiMasterDataPurGroupActiveForChangeGetAllGetPath = '/api/MasterData/PurGroup/ActiveForChangeGetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveForChangeGetAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveForChangeGetAllGet$Response(params?: ApiMasterDataPurGroupActiveForChangeGetAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveForChangeGetAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveForChangeGetAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveForChangeGetAllGet(params?: ApiMasterDataPurGroupActiveForChangeGetAllGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveForChangeGetAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMaintainGet()` */
  static readonly ApiMasterDataPurGroupMaintainGetPath = '/api/MasterData/PurGroup/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMaintainGet$Response(params?: ApiMasterDataPurGroupMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMaintainGet(params?: ApiMasterDataPurGroupMaintainGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupAllGet()` */
  static readonly ApiMasterDataPurGroupAllGetPath = '/api/MasterData/PurGroup/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupAllGet$Response(params?: ApiMasterDataPurGroupAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupAllGet(params?: ApiMasterDataPurGroupAllGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupAllByWorkingGroupGet()` */
  static readonly ApiMasterDataPurGroupAllByWorkingGroupGetPath = '/api/MasterData/PurGroup/AllByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupAllByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupAllByWorkingGroupGet$Response(params?: ApiMasterDataPurGroupAllByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupAllByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupAllByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupAllByWorkingGroupGet(params?: ApiMasterDataPurGroupAllByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupAllByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupActiveByMemberGet()` */
  static readonly ApiMasterDataPurGroupActiveByMemberGetPath = '/api/MasterData/PurGroup/ActiveByMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupActiveByMemberGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByMemberGet$Response(params?: ApiMasterDataPurGroupActiveByMemberGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>> {
    return apiMasterDataPurGroupActiveByMemberGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupActiveByMemberGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupActiveByMemberGet(params?: ApiMasterDataPurGroupActiveByMemberGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupItem>> {
    return this.apiMasterDataPurGroupActiveByMemberGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupItem>>): Array<MasterPurGroupItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupItemSourceAllGet()` */
  static readonly ApiMasterDataPurGroupItemSourceAllGetPath = '/api/MasterData/PurGroup/ItemSource/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupItemSourceAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupItemSourceAllGet$Response(params?: ApiMasterDataPurGroupItemSourceAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataPurGroupItemSourceAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupItemSourceAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupItemSourceAllGet(params?: ApiMasterDataPurGroupItemSourceAllGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataPurGroupItemSourceAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupAddPost()` */
  static readonly ApiMasterDataPurGroupAddPostPath = '/api/MasterData/PurGroup/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupAddPost$Response(params?: ApiMasterDataPurGroupAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPurGroupAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupAddPost(params?: ApiMasterDataPurGroupAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPurGroupAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupEditPost()` */
  static readonly ApiMasterDataPurGroupEditPostPath = '/api/MasterData/PurGroup/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupEditPost$Response(params?: ApiMasterDataPurGroupEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPurGroupEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupEditPost(params?: ApiMasterDataPurGroupEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPurGroupEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupDeleteGet()` */
  static readonly ApiMasterDataPurGroupDeleteGetPath = '/api/MasterData/PurGroup/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupDeleteGet$Response(params?: ApiMasterDataPurGroupDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPurGroupDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupDeleteGet(params?: ApiMasterDataPurGroupDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPurGroupDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupImportPost()` */
  static readonly ApiMasterDataPurGroupImportPostPath = '/api/MasterData/PurGroup/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupImportPost$Response(params?: ApiMasterDataPurGroupImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataPurGroupImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupImportPost(params?: ApiMasterDataPurGroupImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataPurGroupImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupExportGet()` */
  static readonly ApiMasterDataPurGroupExportGetPath = '/api/MasterData/PurGroup/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupExportGet$Response(params?: ApiMasterDataPurGroupExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataPurGroupExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupExportGet(params?: ApiMasterDataPurGroupExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataPurGroupExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
