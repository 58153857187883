/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { MasterVeNusCategoryAndSeverityItem } from '../../models/master-ve-nus-category-and-severity-item';

export interface ContainForBuyerWorksheetGet$Params {
  contain?: string;
  max?: number;
  targetGroup?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function containForBuyerWorksheetGet(http: HttpClient, rootUrl: string, params?: ContainForBuyerWorksheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, containForBuyerWorksheetGet.PATH, 'get');
  if (params) {
    rb.query('contain', params.contain, {});
    rb.query('max', params.max, {});
    rb.query('targetGroup', params.targetGroup, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>;
    })
  );
}

containForBuyerWorksheetGet.PATH = '/ContainForBuyerWorksheet';
