/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { AccountWithRoleResp } from '../models/account-with-role-resp';
import { apiMeDeleteSwapPost } from '../fn/me/api-me-delete-swap-post';
import { ApiMeDeleteSwapPost$Params } from '../fn/me/api-me-delete-swap-post';
import { apiMeGet } from '../fn/me/api-me-get';
import { ApiMeGet$Params } from '../fn/me/api-me-get';
import { apiMeOriginalGet } from '../fn/me/api-me-original-get';
import { ApiMeOriginalGet$Params } from '../fn/me/api-me-original-get';
import { apiMePostSwapPost } from '../fn/me/api-me-post-swap-post';
import { ApiMePostSwapPost$Params } from '../fn/me/api-me-post-swap-post';
import { apiMeUserWorkingGroupGet } from '../fn/me/api-me-user-working-group-get';
import { ApiMeUserWorkingGroupGet$Params } from '../fn/me/api-me-user-working-group-get';

@Injectable({ providedIn: 'root' })
export class MeAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMeGet()` */
  static readonly ApiMeGetPath = '/api/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeGet$Response(params?: ApiMeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AccountWithRoleResp>> {
    return apiMeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeGet(params?: ApiMeGet$Params, context?: HttpContext): Observable<AccountWithRoleResp> {
    return this.apiMeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AccountWithRoleResp>): AccountWithRoleResp => r.body)
    );
  }

  /** Path part for operation `apiMeOriginalGet()` */
  static readonly ApiMeOriginalGetPath = '/api/me/original';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeOriginalGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeOriginalGet$Response(params?: ApiMeOriginalGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiMeOriginalGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMeOriginalGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeOriginalGet(params?: ApiMeOriginalGet$Params, context?: HttpContext): Observable<string> {
    return this.apiMeOriginalGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiMePostSwapPost()` */
  static readonly ApiMePostSwapPostPath = '/api/me/PostSwap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMePostSwapPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMePostSwapPost$Response(params?: ApiMePostSwapPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMePostSwapPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMePostSwapPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMePostSwapPost(params?: ApiMePostSwapPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMePostSwapPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMeDeleteSwapPost()` */
  static readonly ApiMeDeleteSwapPostPath = '/api/me/DeleteSwap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeDeleteSwapPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeDeleteSwapPost$Response(params?: ApiMeDeleteSwapPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMeDeleteSwapPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMeDeleteSwapPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeDeleteSwapPost(params?: ApiMeDeleteSwapPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMeDeleteSwapPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMeUserWorkingGroupGet()` */
  static readonly ApiMeUserWorkingGroupGetPath = '/api/me/UserWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeUserWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeUserWorkingGroupGet$Response(params?: ApiMeUserWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiMeUserWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMeUserWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeUserWorkingGroupGet(params?: ApiMeUserWorkingGroupGet$Params, context?: HttpContext): Observable<string> {
    return this.apiMeUserWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

}
