/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiTaskListAuctionWorksheetAuctionWorksheetExportPost } from '../fn/auction-worksheet-task-list/api-task-list-auction-worksheet-auction-worksheet-export-post';
import { ApiTaskListAuctionWorksheetAuctionWorksheetExportPost$Params } from '../fn/auction-worksheet-task-list/api-task-list-auction-worksheet-auction-worksheet-export-post';
import { apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost } from '../fn/auction-worksheet-task-list/api-task-list-auction-worksheet-auction-worksheet-task-list-post';
import { ApiTaskListAuctionWorksheetAuctionWorksheetTaskListPost$Params } from '../fn/auction-worksheet-task-list/api-task-list-auction-worksheet-auction-worksheet-task-list-post';
import { AuctionWorksheetTaskListResultItem } from '../models/auction-worksheet-task-list-result-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';

@Injectable({ providedIn: 'root' })
export class AuctionWorksheetTaskListAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost()` */
  static readonly ApiTaskListAuctionWorksheetAuctionWorksheetTaskListPostPath = '/api/task-list/auction-worksheet/AuctionWorksheetTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost$Response(params?: ApiTaskListAuctionWorksheetAuctionWorksheetTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AuctionWorksheetTaskListResultItem>>> {
    return apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost(params?: ApiTaskListAuctionWorksheetAuctionWorksheetTaskListPost$Params, context?: HttpContext): Observable<Array<AuctionWorksheetTaskListResultItem>> {
    return this.apiTaskListAuctionWorksheetAuctionWorksheetTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AuctionWorksheetTaskListResultItem>>): Array<AuctionWorksheetTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListAuctionWorksheetAuctionWorksheetExportPost()` */
  static readonly ApiTaskListAuctionWorksheetAuctionWorksheetExportPostPath = '/api/task-list/auction-worksheet/AuctionWorksheetExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListAuctionWorksheetAuctionWorksheetExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListAuctionWorksheetAuctionWorksheetExportPost$Response(params?: ApiTaskListAuctionWorksheetAuctionWorksheetExportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiTaskListAuctionWorksheetAuctionWorksheetExportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListAuctionWorksheetAuctionWorksheetExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListAuctionWorksheetAuctionWorksheetExportPost(params?: ApiTaskListAuctionWorksheetAuctionWorksheetExportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiTaskListAuctionWorksheetAuctionWorksheetExportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
