/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataRestrictedGoodActiveGet } from '../fn/master-restricted-good/api-master-data-restricted-good-active-get';
import { ApiMasterDataRestrictedGoodActiveGet$Params } from '../fn/master-restricted-good/api-master-data-restricted-good-active-get';
import { apiMasterDataRestrictedGoodAddPost } from '../fn/master-restricted-good/api-master-data-restricted-good-add-post';
import { ApiMasterDataRestrictedGoodAddPost$Params } from '../fn/master-restricted-good/api-master-data-restricted-good-add-post';
import { apiMasterDataRestrictedGoodAllGet } from '../fn/master-restricted-good/api-master-data-restricted-good-all-get';
import { ApiMasterDataRestrictedGoodAllGet$Params } from '../fn/master-restricted-good/api-master-data-restricted-good-all-get';
import { apiMasterDataRestrictedGoodDeleteGet } from '../fn/master-restricted-good/api-master-data-restricted-good-delete-get';
import { ApiMasterDataRestrictedGoodDeleteGet$Params } from '../fn/master-restricted-good/api-master-data-restricted-good-delete-get';
import { apiMasterDataRestrictedGoodEditPost } from '../fn/master-restricted-good/api-master-data-restricted-good-edit-post';
import { ApiMasterDataRestrictedGoodEditPost$Params } from '../fn/master-restricted-good/api-master-data-restricted-good-edit-post';
import { apiMasterDataRestrictedGoodMaintainGet } from '../fn/master-restricted-good/api-master-data-restricted-good-maintain-get';
import { ApiMasterDataRestrictedGoodMaintainGet$Params } from '../fn/master-restricted-good/api-master-data-restricted-good-maintain-get';
import { MasterRestrictedGoodItem } from '../models/master-restricted-good-item';

@Injectable({ providedIn: 'root' })
export class MasterRestrictedGoodAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataRestrictedGoodActiveGet()` */
  static readonly ApiMasterDataRestrictedGoodActiveGetPath = '/api/MasterData/RestrictedGood/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRestrictedGoodActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodActiveGet$Response(params?: ApiMasterDataRestrictedGoodActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRestrictedGoodItem>>> {
    return apiMasterDataRestrictedGoodActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRestrictedGoodActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodActiveGet(params?: ApiMasterDataRestrictedGoodActiveGet$Params, context?: HttpContext): Observable<Array<MasterRestrictedGoodItem>> {
    return this.apiMasterDataRestrictedGoodActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRestrictedGoodItem>>): Array<MasterRestrictedGoodItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRestrictedGoodMaintainGet()` */
  static readonly ApiMasterDataRestrictedGoodMaintainGetPath = '/api/MasterData/RestrictedGood/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRestrictedGoodMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodMaintainGet$Response(params?: ApiMasterDataRestrictedGoodMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRestrictedGoodItem>>> {
    return apiMasterDataRestrictedGoodMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRestrictedGoodMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodMaintainGet(params?: ApiMasterDataRestrictedGoodMaintainGet$Params, context?: HttpContext): Observable<Array<MasterRestrictedGoodItem>> {
    return this.apiMasterDataRestrictedGoodMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRestrictedGoodItem>>): Array<MasterRestrictedGoodItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRestrictedGoodAllGet()` */
  static readonly ApiMasterDataRestrictedGoodAllGetPath = '/api/MasterData/RestrictedGood/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRestrictedGoodAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodAllGet$Response(params?: ApiMasterDataRestrictedGoodAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterRestrictedGoodItem>>> {
    return apiMasterDataRestrictedGoodAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRestrictedGoodAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodAllGet(params?: ApiMasterDataRestrictedGoodAllGet$Params, context?: HttpContext): Observable<Array<MasterRestrictedGoodItem>> {
    return this.apiMasterDataRestrictedGoodAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterRestrictedGoodItem>>): Array<MasterRestrictedGoodItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRestrictedGoodAddPost()` */
  static readonly ApiMasterDataRestrictedGoodAddPostPath = '/api/MasterData/RestrictedGood/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRestrictedGoodAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRestrictedGoodAddPost$Response(params?: ApiMasterDataRestrictedGoodAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataRestrictedGoodAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRestrictedGoodAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRestrictedGoodAddPost(params?: ApiMasterDataRestrictedGoodAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataRestrictedGoodAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRestrictedGoodEditPost()` */
  static readonly ApiMasterDataRestrictedGoodEditPostPath = '/api/MasterData/RestrictedGood/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRestrictedGoodEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRestrictedGoodEditPost$Response(params?: ApiMasterDataRestrictedGoodEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataRestrictedGoodEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRestrictedGoodEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataRestrictedGoodEditPost(params?: ApiMasterDataRestrictedGoodEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataRestrictedGoodEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataRestrictedGoodDeleteGet()` */
  static readonly ApiMasterDataRestrictedGoodDeleteGetPath = '/api/MasterData/RestrictedGood/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataRestrictedGoodDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodDeleteGet$Response(params?: ApiMasterDataRestrictedGoodDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataRestrictedGoodDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataRestrictedGoodDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataRestrictedGoodDeleteGet(params?: ApiMasterDataRestrictedGoodDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataRestrictedGoodDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
