/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { activeGet } from '../fn/master-ve-nus-category-and-severity/active-get';
import { ActiveGet$Params } from '../fn/master-ve-nus-category-and-severity/active-get';
import { allGet } from '../fn/master-ve-nus-category-and-severity/all-get';
import { AllGet$Params } from '../fn/master-ve-nus-category-and-severity/all-get';
import { clearCacheTagGet } from '../fn/master-ve-nus-category-and-severity/clear-cache-tag-get';
import { ClearCacheTagGet$Params } from '../fn/master-ve-nus-category-and-severity/clear-cache-tag-get';
import { containForBuyerWorksheetGet } from '../fn/master-ve-nus-category-and-severity/contain-for-buyer-worksheet-get';
import { ContainForBuyerWorksheetGet$Params } from '../fn/master-ve-nus-category-and-severity/contain-for-buyer-worksheet-get';
import { containGet } from '../fn/master-ve-nus-category-and-severity/contain-get';
import { ContainGet$Params } from '../fn/master-ve-nus-category-and-severity/contain-get';
import { getByAribaCodeIdGet } from '../fn/master-ve-nus-category-and-severity/get-by-ariba-code-id-get';
import { GetByAribaCodeIdGet$Params } from '../fn/master-ve-nus-category-and-severity/get-by-ariba-code-id-get';
import { getByPkGet } from '../fn/master-ve-nus-category-and-severity/get-by-pk-get';
import { GetByPkGet$Params } from '../fn/master-ve-nus-category-and-severity/get-by-pk-get';
import { MasterVeNusCategoryAndSeverityItem } from '../models/master-ve-nus-category-and-severity-item';

@Injectable({ providedIn: 'root' })
export class MasterVeNusCategoryAndSeverityAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activeGet()` */
  static readonly ActiveGetPath = '/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeGet$Response(params?: ActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>> {
    return activeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeGet(params?: ActiveGet$Params, context?: HttpContext): Observable<Array<MasterVeNusCategoryAndSeverityItem>> {
    return this.activeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>): Array<MasterVeNusCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `allGet()` */
  static readonly AllGetPath = '/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  allGet$Response(params?: AllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>> {
    return allGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allGet(params?: AllGet$Params, context?: HttpContext): Observable<Array<MasterVeNusCategoryAndSeverityItem>> {
    return this.allGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>): Array<MasterVeNusCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `getByPkGet()` */
  static readonly GetByPkGetPath = '/GetByPK';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByPkGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByPkGet$Response(params?: GetByPkGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<MasterVeNusCategoryAndSeverityItem>> {
    return getByPkGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByPkGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByPkGet(params?: GetByPkGet$Params, context?: HttpContext): Observable<MasterVeNusCategoryAndSeverityItem> {
    return this.getByPkGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<MasterVeNusCategoryAndSeverityItem>): MasterVeNusCategoryAndSeverityItem => r.body)
    );
  }

  /** Path part for operation `getByAribaCodeIdGet()` */
  static readonly GetByAribaCodeIdGetPath = '/GetByAribaCodeId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByAribaCodeIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByAribaCodeIdGet$Response(params?: GetByAribaCodeIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<MasterVeNusCategoryAndSeverityItem>> {
    return getByAribaCodeIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByAribaCodeIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByAribaCodeIdGet(params?: GetByAribaCodeIdGet$Params, context?: HttpContext): Observable<MasterVeNusCategoryAndSeverityItem> {
    return this.getByAribaCodeIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<MasterVeNusCategoryAndSeverityItem>): MasterVeNusCategoryAndSeverityItem => r.body)
    );
  }

  /** Path part for operation `containGet()` */
  static readonly ContainGetPath = '/Contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `containGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  containGet$Response(params?: ContainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>> {
    return containGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `containGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  containGet(params?: ContainGet$Params, context?: HttpContext): Observable<Array<MasterVeNusCategoryAndSeverityItem>> {
    return this.containGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>): Array<MasterVeNusCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `containForBuyerWorksheetGet()` */
  static readonly ContainForBuyerWorksheetGetPath = '/ContainForBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `containForBuyerWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  containForBuyerWorksheetGet$Response(params?: ContainForBuyerWorksheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>> {
    return containForBuyerWorksheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `containForBuyerWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  containForBuyerWorksheetGet(params?: ContainForBuyerWorksheetGet$Params, context?: HttpContext): Observable<Array<MasterVeNusCategoryAndSeverityItem>> {
    return this.containForBuyerWorksheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVeNusCategoryAndSeverityItem>>): Array<MasterVeNusCategoryAndSeverityItem> => r.body)
    );
  }

  /** Path part for operation `clearCacheTagGet()` */
  static readonly ClearCacheTagGetPath = '/ClearCacheTag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearCacheTagGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearCacheTagGet$Response(params?: ClearCacheTagGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return clearCacheTagGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearCacheTagGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearCacheTagGet(params?: ClearCacheTagGet$Params, context?: HttpContext): Observable<void> {
    return this.clearCacheTagGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
