/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiTaskListSearchingContractListExportPost } from '../fn/contract-searching-task-list/api-task-list-searching-contract-list-export-post';
import { ApiTaskListSearchingContractListExportPost$Params } from '../fn/contract-searching-task-list/api-task-list-searching-contract-list-export-post';
import { apiTaskListSearchingContractSearchingTaskListPost } from '../fn/contract-searching-task-list/api-task-list-searching-contract-searching-task-list-post';
import { ApiTaskListSearchingContractSearchingTaskListPost$Params } from '../fn/contract-searching-task-list/api-task-list-searching-contract-searching-task-list-post';
import { ContractSearchingTaskListResultItem } from '../models/contract-searching-task-list-result-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';

@Injectable({ providedIn: 'root' })
export class ContractSearchingTaskListAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTaskListSearchingContractSearchingTaskListPost()` */
  static readonly ApiTaskListSearchingContractSearchingTaskListPostPath = '/api/task-list/searching/ContractSearchingTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListSearchingContractSearchingTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListSearchingContractSearchingTaskListPost$Response(params?: ApiTaskListSearchingContractSearchingTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractSearchingTaskListResultItem>>> {
    return apiTaskListSearchingContractSearchingTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListSearchingContractSearchingTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListSearchingContractSearchingTaskListPost(params?: ApiTaskListSearchingContractSearchingTaskListPost$Params, context?: HttpContext): Observable<Array<ContractSearchingTaskListResultItem>> {
    return this.apiTaskListSearchingContractSearchingTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractSearchingTaskListResultItem>>): Array<ContractSearchingTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListSearchingContractListExportPost()` */
  static readonly ApiTaskListSearchingContractListExportPostPath = '/api/task-list/searching/ContractListExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListSearchingContractListExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListSearchingContractListExportPost$Response(params?: ApiTaskListSearchingContractListExportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiTaskListSearchingContractListExportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListSearchingContractListExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListSearchingContractListExportPost(params?: ApiTaskListSearchingContractListExportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiTaskListSearchingContractListExportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
