/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestBuyerWorksheetExportGetGet } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-get-get';
import { ApiRequestBuyerWorksheetExportGetGet$Params } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-get-get';
import { apiRequestBuyerWorksheetExportPdfGet } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-pdf-get';
import { ApiRequestBuyerWorksheetExportPdfGet$Params } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-pdf-get';
import { apiRequestBuyerWorksheetExportPreviewGet } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-preview-get';
import { ApiRequestBuyerWorksheetExportPreviewGet$Params } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-preview-get';
import { apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-view-bidding-evaluation-form-post';
import { ApiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost$Params } from '../fn/buyer-worksheet-export/api-request-buyer-worksheet-export-view-bidding-evaluation-form-post';
import { BuyerWorksheetItem } from '../models/buyer-worksheet-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';

@Injectable({ providedIn: 'root' })
export class BuyerWorksheetExportAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestBuyerWorksheetExportGetGet()` */
  static readonly ApiRequestBuyerWorksheetExportGetGetPath = '/api/request/buyer-worksheet-export/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetExportGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetExportGetGet$Response(params?: ApiRequestBuyerWorksheetExportGetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BuyerWorksheetItem>> {
    return apiRequestBuyerWorksheetExportGetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetExportGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetExportGetGet(params?: ApiRequestBuyerWorksheetExportGetGet$Params, context?: HttpContext): Observable<BuyerWorksheetItem> {
    return this.apiRequestBuyerWorksheetExportGetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<BuyerWorksheetItem>): BuyerWorksheetItem => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetExportPdfGet()` */
  static readonly ApiRequestBuyerWorksheetExportPdfGetPath = '/api/request/buyer-worksheet-export/PDF';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetExportPdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetExportPdfGet$Response(params?: ApiRequestBuyerWorksheetExportPdfGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestBuyerWorksheetExportPdfGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetExportPdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetExportPdfGet(params?: ApiRequestBuyerWorksheetExportPdfGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestBuyerWorksheetExportPdfGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetExportPreviewGet()` */
  static readonly ApiRequestBuyerWorksheetExportPreviewGetPath = '/api/request/buyer-worksheet-export/Preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetExportPreviewGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetExportPreviewGet$Response(params?: ApiRequestBuyerWorksheetExportPreviewGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestBuyerWorksheetExportPreviewGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetExportPreviewGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetExportPreviewGet(params?: ApiRequestBuyerWorksheetExportPreviewGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestBuyerWorksheetExportPreviewGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost()` */
  static readonly ApiRequestBuyerWorksheetExportViewBiddingEvaluationFormPostPath = '/api/request/buyer-worksheet-export/ViewBiddingEvaluationForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost$Response(params?: ApiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost(params?: ApiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestBuyerWorksheetExportViewBiddingEvaluationFormPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
