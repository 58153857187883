/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataConditionValueTypeGetAllGet } from '../fn/master-condition-value-type/api-master-data-condition-value-type-get-all-get';
import { ApiMasterDataConditionValueTypeGetAllGet$Params } from '../fn/master-condition-value-type/api-master-data-condition-value-type-get-all-get';
import { apiMasterDataConditionValueTypeGetDropdownListGet } from '../fn/master-condition-value-type/api-master-data-condition-value-type-get-dropdown-list-get';
import { ApiMasterDataConditionValueTypeGetDropdownListGet$Params } from '../fn/master-condition-value-type/api-master-data-condition-value-type-get-dropdown-list-get';
import { MasterConditionValueType } from '../models/master-condition-value-type';
import { VendorCommercialBidEvaluationOption } from '../models/vendor-commercial-bid-evaluation-option';

@Injectable({ providedIn: 'root' })
export class MasterConditionValueTypeAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataConditionValueTypeGetAllGet()` */
  static readonly ApiMasterDataConditionValueTypeGetAllGetPath = '/api/MasterData/ConditionValueType/GetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataConditionValueTypeGetAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataConditionValueTypeGetAllGet$Response(params?: ApiMasterDataConditionValueTypeGetAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterConditionValueType>>> {
    return apiMasterDataConditionValueTypeGetAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataConditionValueTypeGetAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataConditionValueTypeGetAllGet(params?: ApiMasterDataConditionValueTypeGetAllGet$Params, context?: HttpContext): Observable<Array<MasterConditionValueType>> {
    return this.apiMasterDataConditionValueTypeGetAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterConditionValueType>>): Array<MasterConditionValueType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataConditionValueTypeGetDropdownListGet()` */
  static readonly ApiMasterDataConditionValueTypeGetDropdownListGetPath = '/api/MasterData/ConditionValueType/GetDropdownList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataConditionValueTypeGetDropdownListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataConditionValueTypeGetDropdownListGet$Response(params?: ApiMasterDataConditionValueTypeGetDropdownListGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<VendorCommercialBidEvaluationOption>> {
    return apiMasterDataConditionValueTypeGetDropdownListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataConditionValueTypeGetDropdownListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataConditionValueTypeGetDropdownListGet(params?: ApiMasterDataConditionValueTypeGetDropdownListGet$Params, context?: HttpContext): Observable<VendorCommercialBidEvaluationOption> {
    return this.apiMasterDataConditionValueTypeGetDropdownListGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<VendorCommercialBidEvaluationOption>): VendorCommercialBidEvaluationOption => r.body)
    );
  }

}
