/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataSynergyCostSavingActiveGet } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-active-get';
import { ApiMasterDataSynergyCostSavingActiveGet$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-active-get';
import { apiMasterDataSynergyCostSavingAddPost } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-add-post';
import { ApiMasterDataSynergyCostSavingAddPost$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-add-post';
import { apiMasterDataSynergyCostSavingAllGet } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-all-get';
import { ApiMasterDataSynergyCostSavingAllGet$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-all-get';
import { apiMasterDataSynergyCostSavingDeleteGet } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-delete-get';
import { ApiMasterDataSynergyCostSavingDeleteGet$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-delete-get';
import { apiMasterDataSynergyCostSavingEditPost } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-edit-post';
import { ApiMasterDataSynergyCostSavingEditPost$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-edit-post';
import { apiMasterDataSynergyCostSavingExportGet } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-export-get';
import { ApiMasterDataSynergyCostSavingExportGet$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-export-get';
import { apiMasterDataSynergyCostSavingGetSavingRangeByCodePost } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-get-saving-range-by-code-post';
import { ApiMasterDataSynergyCostSavingGetSavingRangeByCodePost$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-get-saving-range-by-code-post';
import { apiMasterDataSynergyCostSavingGetSavingRangePost } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-get-saving-range-post';
import { ApiMasterDataSynergyCostSavingGetSavingRangePost$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-get-saving-range-post';
import { apiMasterDataSynergyCostSavingImportPost } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-import-post';
import { ApiMasterDataSynergyCostSavingImportPost$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-import-post';
import { apiMasterDataSynergyCostSavingMaintainGet } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-maintain-get';
import { ApiMasterDataSynergyCostSavingMaintainGet$Params } from '../fn/master-synergy-cost-saving/api-master-data-synergy-cost-saving-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterSynergyCostSavingItem } from '../models/master-synergy-cost-saving-item';

@Injectable({ providedIn: 'root' })
export class MasterSynergyCostSavingAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingGetSavingRangePost()` */
  static readonly ApiMasterDataSynergyCostSavingGetSavingRangePostPath = '/api/MasterData/SynergyCostSaving/GetSavingRange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingGetSavingRangePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingGetSavingRangePost$Response(params?: ApiMasterDataSynergyCostSavingGetSavingRangePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>> {
    return apiMasterDataSynergyCostSavingGetSavingRangePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingGetSavingRangePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingGetSavingRangePost(params?: ApiMasterDataSynergyCostSavingGetSavingRangePost$Params, context?: HttpContext): Observable<Array<MasterSynergyCostSavingItem>> {
    return this.apiMasterDataSynergyCostSavingGetSavingRangePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>): Array<MasterSynergyCostSavingItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingGetSavingRangeByCodePost()` */
  static readonly ApiMasterDataSynergyCostSavingGetSavingRangeByCodePostPath = '/api/MasterData/SynergyCostSaving/GetSavingRangeByCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingGetSavingRangeByCodePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingGetSavingRangeByCodePost$Response(params?: ApiMasterDataSynergyCostSavingGetSavingRangeByCodePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>> {
    return apiMasterDataSynergyCostSavingGetSavingRangeByCodePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingGetSavingRangeByCodePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingGetSavingRangeByCodePost(params?: ApiMasterDataSynergyCostSavingGetSavingRangeByCodePost$Params, context?: HttpContext): Observable<Array<MasterSynergyCostSavingItem>> {
    return this.apiMasterDataSynergyCostSavingGetSavingRangeByCodePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>): Array<MasterSynergyCostSavingItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingActiveGet()` */
  static readonly ApiMasterDataSynergyCostSavingActiveGetPath = '/api/MasterData/SynergyCostSaving/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingActiveGet$Response(params?: ApiMasterDataSynergyCostSavingActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>> {
    return apiMasterDataSynergyCostSavingActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingActiveGet(params?: ApiMasterDataSynergyCostSavingActiveGet$Params, context?: HttpContext): Observable<Array<MasterSynergyCostSavingItem>> {
    return this.apiMasterDataSynergyCostSavingActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>): Array<MasterSynergyCostSavingItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingMaintainGet()` */
  static readonly ApiMasterDataSynergyCostSavingMaintainGetPath = '/api/MasterData/SynergyCostSaving/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingMaintainGet$Response(params?: ApiMasterDataSynergyCostSavingMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>> {
    return apiMasterDataSynergyCostSavingMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingMaintainGet(params?: ApiMasterDataSynergyCostSavingMaintainGet$Params, context?: HttpContext): Observable<Array<MasterSynergyCostSavingItem>> {
    return this.apiMasterDataSynergyCostSavingMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>): Array<MasterSynergyCostSavingItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingAllGet()` */
  static readonly ApiMasterDataSynergyCostSavingAllGetPath = '/api/MasterData/SynergyCostSaving/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingAllGet$Response(params?: ApiMasterDataSynergyCostSavingAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>> {
    return apiMasterDataSynergyCostSavingAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingAllGet(params?: ApiMasterDataSynergyCostSavingAllGet$Params, context?: HttpContext): Observable<Array<MasterSynergyCostSavingItem>> {
    return this.apiMasterDataSynergyCostSavingAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSynergyCostSavingItem>>): Array<MasterSynergyCostSavingItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingAddPost()` */
  static readonly ApiMasterDataSynergyCostSavingAddPostPath = '/api/MasterData/SynergyCostSaving/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingAddPost$Response(params?: ApiMasterDataSynergyCostSavingAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSynergyCostSavingAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingAddPost(params?: ApiMasterDataSynergyCostSavingAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSynergyCostSavingAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingEditPost()` */
  static readonly ApiMasterDataSynergyCostSavingEditPostPath = '/api/MasterData/SynergyCostSaving/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingEditPost$Response(params?: ApiMasterDataSynergyCostSavingEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSynergyCostSavingEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSynergyCostSavingEditPost(params?: ApiMasterDataSynergyCostSavingEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSynergyCostSavingEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingDeleteGet()` */
  static readonly ApiMasterDataSynergyCostSavingDeleteGetPath = '/api/MasterData/SynergyCostSaving/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingDeleteGet$Response(params?: ApiMasterDataSynergyCostSavingDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSynergyCostSavingDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingDeleteGet(params?: ApiMasterDataSynergyCostSavingDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSynergyCostSavingDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingImportPost()` */
  static readonly ApiMasterDataSynergyCostSavingImportPostPath = '/api/MasterData/SynergyCostSaving/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingImportPost$Response(params?: ApiMasterDataSynergyCostSavingImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataSynergyCostSavingImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingImportPost(params?: ApiMasterDataSynergyCostSavingImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataSynergyCostSavingImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSynergyCostSavingExportGet()` */
  static readonly ApiMasterDataSynergyCostSavingExportGetPath = '/api/MasterData/SynergyCostSaving/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSynergyCostSavingExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingExportGet$Response(params?: ApiMasterDataSynergyCostSavingExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataSynergyCostSavingExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSynergyCostSavingExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSynergyCostSavingExportGet(params?: ApiMasterDataSynergyCostSavingExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataSynergyCostSavingExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
