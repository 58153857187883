/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataTemplateFileActiveGet } from '../fn/master-template-file/api-master-data-template-file-active-get';
import { ApiMasterDataTemplateFileActiveGet$Params } from '../fn/master-template-file/api-master-data-template-file-active-get';
import { apiMasterDataTemplateFileAddPost } from '../fn/master-template-file/api-master-data-template-file-add-post';
import { ApiMasterDataTemplateFileAddPost$Params } from '../fn/master-template-file/api-master-data-template-file-add-post';
import { apiMasterDataTemplateFileAllGet } from '../fn/master-template-file/api-master-data-template-file-all-get';
import { ApiMasterDataTemplateFileAllGet$Params } from '../fn/master-template-file/api-master-data-template-file-all-get';
import { apiMasterDataTemplateFileDeleteGet } from '../fn/master-template-file/api-master-data-template-file-delete-get';
import { ApiMasterDataTemplateFileDeleteGet$Params } from '../fn/master-template-file/api-master-data-template-file-delete-get';
import { apiMasterDataTemplateFileEditPost } from '../fn/master-template-file/api-master-data-template-file-edit-post';
import { ApiMasterDataTemplateFileEditPost$Params } from '../fn/master-template-file/api-master-data-template-file-edit-post';
import { apiMasterDataTemplateFileMaintainGet } from '../fn/master-template-file/api-master-data-template-file-maintain-get';
import { ApiMasterDataTemplateFileMaintainGet$Params } from '../fn/master-template-file/api-master-data-template-file-maintain-get';
import { apiMasterDataTemplateFileUseForGet } from '../fn/master-template-file/api-master-data-template-file-use-for-get';
import { ApiMasterDataTemplateFileUseForGet$Params } from '../fn/master-template-file/api-master-data-template-file-use-for-get';
import { MasterTemplateFileItem } from '../models/master-template-file-item';

@Injectable({ providedIn: 'root' })
export class MasterTemplateFileAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataTemplateFileUseForGet()` */
  static readonly ApiMasterDataTemplateFileUseForGetPath = '/api/MasterData/TemplateFile/UseFor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileUseForGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileUseForGet$Response(params?: ApiMasterDataTemplateFileUseForGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>> {
    return apiMasterDataTemplateFileUseForGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileUseForGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileUseForGet(params?: ApiMasterDataTemplateFileUseForGet$Params, context?: HttpContext): Observable<Array<MasterTemplateFileItem>> {
    return this.apiMasterDataTemplateFileUseForGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>): Array<MasterTemplateFileItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTemplateFileActiveGet()` */
  static readonly ApiMasterDataTemplateFileActiveGetPath = '/api/MasterData/TemplateFile/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileActiveGet$Response(params?: ApiMasterDataTemplateFileActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>> {
    return apiMasterDataTemplateFileActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileActiveGet(params?: ApiMasterDataTemplateFileActiveGet$Params, context?: HttpContext): Observable<Array<MasterTemplateFileItem>> {
    return this.apiMasterDataTemplateFileActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>): Array<MasterTemplateFileItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTemplateFileMaintainGet()` */
  static readonly ApiMasterDataTemplateFileMaintainGetPath = '/api/MasterData/TemplateFile/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileMaintainGet$Response(params?: ApiMasterDataTemplateFileMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>> {
    return apiMasterDataTemplateFileMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileMaintainGet(params?: ApiMasterDataTemplateFileMaintainGet$Params, context?: HttpContext): Observable<Array<MasterTemplateFileItem>> {
    return this.apiMasterDataTemplateFileMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>): Array<MasterTemplateFileItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTemplateFileAllGet()` */
  static readonly ApiMasterDataTemplateFileAllGetPath = '/api/MasterData/TemplateFile/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileAllGet$Response(params?: ApiMasterDataTemplateFileAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>> {
    return apiMasterDataTemplateFileAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileAllGet(params?: ApiMasterDataTemplateFileAllGet$Params, context?: HttpContext): Observable<Array<MasterTemplateFileItem>> {
    return this.apiMasterDataTemplateFileAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTemplateFileItem>>): Array<MasterTemplateFileItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTemplateFileAddPost()` */
  static readonly ApiMasterDataTemplateFileAddPostPath = '/api/MasterData/TemplateFile/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTemplateFileAddPost$Response(params?: ApiMasterDataTemplateFileAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataTemplateFileAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTemplateFileAddPost(params?: ApiMasterDataTemplateFileAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataTemplateFileAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTemplateFileEditPost()` */
  static readonly ApiMasterDataTemplateFileEditPostPath = '/api/MasterData/TemplateFile/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTemplateFileEditPost$Response(params?: ApiMasterDataTemplateFileEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataTemplateFileEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTemplateFileEditPost(params?: ApiMasterDataTemplateFileEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataTemplateFileEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTemplateFileDeleteGet()` */
  static readonly ApiMasterDataTemplateFileDeleteGetPath = '/api/MasterData/TemplateFile/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTemplateFileDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileDeleteGet$Response(params?: ApiMasterDataTemplateFileDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataTemplateFileDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTemplateFileDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTemplateFileDeleteGet(params?: ApiMasterDataTemplateFileDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataTemplateFileDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
