/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataContractEmailTemplateActiveGet } from '../fn/master-contract-email-template/api-master-data-contract-email-template-active-get';
import { ApiMasterDataContractEmailTemplateActiveGet$Params } from '../fn/master-contract-email-template/api-master-data-contract-email-template-active-get';
import { apiMasterDataContractEmailTemplateAddPost } from '../fn/master-contract-email-template/api-master-data-contract-email-template-add-post';
import { ApiMasterDataContractEmailTemplateAddPost$Params } from '../fn/master-contract-email-template/api-master-data-contract-email-template-add-post';
import { apiMasterDataContractEmailTemplateAllGet } from '../fn/master-contract-email-template/api-master-data-contract-email-template-all-get';
import { ApiMasterDataContractEmailTemplateAllGet$Params } from '../fn/master-contract-email-template/api-master-data-contract-email-template-all-get';
import { apiMasterDataContractEmailTemplateDeleteGet } from '../fn/master-contract-email-template/api-master-data-contract-email-template-delete-get';
import { ApiMasterDataContractEmailTemplateDeleteGet$Params } from '../fn/master-contract-email-template/api-master-data-contract-email-template-delete-get';
import { apiMasterDataContractEmailTemplateEditPost } from '../fn/master-contract-email-template/api-master-data-contract-email-template-edit-post';
import { ApiMasterDataContractEmailTemplateEditPost$Params } from '../fn/master-contract-email-template/api-master-data-contract-email-template-edit-post';
import { apiMasterDataContractEmailTemplateMaintainGet } from '../fn/master-contract-email-template/api-master-data-contract-email-template-maintain-get';
import { ApiMasterDataContractEmailTemplateMaintainGet$Params } from '../fn/master-contract-email-template/api-master-data-contract-email-template-maintain-get';
import { MasterContractEmailTemplateItem } from '../models/master-contract-email-template-item';

@Injectable({ providedIn: 'root' })
export class MasterContractEmailTemplateAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataContractEmailTemplateActiveGet()` */
  static readonly ApiMasterDataContractEmailTemplateActiveGetPath = '/api/MasterData/ContractEmailTemplate/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractEmailTemplateActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateActiveGet$Response(params?: ApiMasterDataContractEmailTemplateActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractEmailTemplateItem>>> {
    return apiMasterDataContractEmailTemplateActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractEmailTemplateActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateActiveGet(params?: ApiMasterDataContractEmailTemplateActiveGet$Params, context?: HttpContext): Observable<Array<MasterContractEmailTemplateItem>> {
    return this.apiMasterDataContractEmailTemplateActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractEmailTemplateItem>>): Array<MasterContractEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractEmailTemplateMaintainGet()` */
  static readonly ApiMasterDataContractEmailTemplateMaintainGetPath = '/api/MasterData/ContractEmailTemplate/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractEmailTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateMaintainGet$Response(params?: ApiMasterDataContractEmailTemplateMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractEmailTemplateItem>>> {
    return apiMasterDataContractEmailTemplateMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractEmailTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateMaintainGet(params?: ApiMasterDataContractEmailTemplateMaintainGet$Params, context?: HttpContext): Observable<Array<MasterContractEmailTemplateItem>> {
    return this.apiMasterDataContractEmailTemplateMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractEmailTemplateItem>>): Array<MasterContractEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractEmailTemplateAllGet()` */
  static readonly ApiMasterDataContractEmailTemplateAllGetPath = '/api/MasterData/ContractEmailTemplate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractEmailTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateAllGet$Response(params?: ApiMasterDataContractEmailTemplateAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractEmailTemplateItem>>> {
    return apiMasterDataContractEmailTemplateAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractEmailTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateAllGet(params?: ApiMasterDataContractEmailTemplateAllGet$Params, context?: HttpContext): Observable<Array<MasterContractEmailTemplateItem>> {
    return this.apiMasterDataContractEmailTemplateAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractEmailTemplateItem>>): Array<MasterContractEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractEmailTemplateAddPost()` */
  static readonly ApiMasterDataContractEmailTemplateAddPostPath = '/api/MasterData/ContractEmailTemplate/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractEmailTemplateAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractEmailTemplateAddPost$Response(params?: ApiMasterDataContractEmailTemplateAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataContractEmailTemplateAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractEmailTemplateAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractEmailTemplateAddPost(params?: ApiMasterDataContractEmailTemplateAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataContractEmailTemplateAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractEmailTemplateEditPost()` */
  static readonly ApiMasterDataContractEmailTemplateEditPostPath = '/api/MasterData/ContractEmailTemplate/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractEmailTemplateEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractEmailTemplateEditPost$Response(params?: ApiMasterDataContractEmailTemplateEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataContractEmailTemplateEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractEmailTemplateEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractEmailTemplateEditPost(params?: ApiMasterDataContractEmailTemplateEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataContractEmailTemplateEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractEmailTemplateDeleteGet()` */
  static readonly ApiMasterDataContractEmailTemplateDeleteGetPath = '/api/MasterData/ContractEmailTemplate/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractEmailTemplateDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateDeleteGet$Response(params?: ApiMasterDataContractEmailTemplateDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataContractEmailTemplateDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractEmailTemplateDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractEmailTemplateDeleteGet(params?: ApiMasterDataContractEmailTemplateDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataContractEmailTemplateDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
