/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataSimpleValueActiveGet } from '../fn/master-simple-value/api-master-data-simple-value-active-get';
import { ApiMasterDataSimpleValueActiveGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-active-get';
import { apiMasterDataSimpleValueAddPost } from '../fn/master-simple-value/api-master-data-simple-value-add-post';
import { ApiMasterDataSimpleValueAddPost$Params } from '../fn/master-simple-value/api-master-data-simple-value-add-post';
import { apiMasterDataSimpleValueAllGet } from '../fn/master-simple-value/api-master-data-simple-value-all-get';
import { ApiMasterDataSimpleValueAllGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-all-get';
import { apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-for-buyer-work-sheet-get';
import { ApiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-for-buyer-work-sheet-get';
import { apiMasterDataSimpleValueByMasterTypeForPrGet } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-for-pr-get';
import { ApiMasterDataSimpleValueByMasterTypeForPrGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-for-pr-get';
import { apiMasterDataSimpleValueByMasterTypeGet } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-get';
import { ApiMasterDataSimpleValueByMasterTypeGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-get';
import { apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-not-filter-by-working-group-get';
import { ApiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-by-master-type-not-filter-by-working-group-get';
import { apiMasterDataSimpleValueDeleteGet } from '../fn/master-simple-value/api-master-data-simple-value-delete-get';
import { ApiMasterDataSimpleValueDeleteGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-delete-get';
import { apiMasterDataSimpleValueEditPost } from '../fn/master-simple-value/api-master-data-simple-value-edit-post';
import { ApiMasterDataSimpleValueEditPost$Params } from '../fn/master-simple-value/api-master-data-simple-value-edit-post';
import { apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet } from '../fn/master-simple-value/api-master-data-simple-value-key-value-by-master-type-for-buyer-work-sheet-get';
import { ApiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-key-value-by-master-type-for-buyer-work-sheet-get';
import { apiMasterDataSimpleValueKeyValueByMasterTypeGet } from '../fn/master-simple-value/api-master-data-simple-value-key-value-by-master-type-get';
import { ApiMasterDataSimpleValueKeyValueByMasterTypeGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-key-value-by-master-type-get';
import { apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet } from '../fn/master-simple-value/api-master-data-simple-value-key-value-by-master-type-not-filter-by-working-group-get';
import { ApiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-key-value-by-master-type-not-filter-by-working-group-get';
import { apiMasterDataSimpleValueMaintainGet } from '../fn/master-simple-value/api-master-data-simple-value-maintain-get';
import { ApiMasterDataSimpleValueMaintainGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-maintain-get';
import { apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet } from '../fn/master-simple-value/api-master-data-simple-value-value-by-master-type-for-buyer-work-sheet-get';
import { ApiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-value-by-master-type-for-buyer-work-sheet-get';
import { apiMasterDataSimpleValueValueByMasterTypeGet } from '../fn/master-simple-value/api-master-data-simple-value-value-by-master-type-get';
import { ApiMasterDataSimpleValueValueByMasterTypeGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-value-by-master-type-get';
import { apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet } from '../fn/master-simple-value/api-master-data-simple-value-value-by-master-type-not-filter-by-working-group-get';
import { ApiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet$Params } from '../fn/master-simple-value/api-master-data-simple-value-value-by-master-type-not-filter-by-working-group-get';
import { MasterSimpleKeyValueItem } from '../models/master-simple-key-value-item';
import { MasterSimpleValueItem } from '../models/master-simple-value-item';

@Injectable({ providedIn: 'root' })
export class MasterSimpleValueAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataSimpleValueByMasterTypeGet()` */
  static readonly ApiMasterDataSimpleValueByMasterTypeGetPath = '/api/MasterData/SimpleValue/ByMasterType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueByMasterTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeGet$Response(params?: ApiMasterDataSimpleValueByMasterTypeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueByMasterTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueByMasterTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeGet(params?: ApiMasterDataSimpleValueByMasterTypeGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueByMasterTypeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueByMasterTypeForPrGet()` */
  static readonly ApiMasterDataSimpleValueByMasterTypeForPrGetPath = '/api/MasterData/SimpleValue/ByMasterTypeForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueByMasterTypeForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeForPrGet$Response(params?: ApiMasterDataSimpleValueByMasterTypeForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueByMasterTypeForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueByMasterTypeForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeForPrGet(params?: ApiMasterDataSimpleValueByMasterTypeForPrGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueByMasterTypeForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGetPath = '/api/MasterData/SimpleValue/ByMasterTypeForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet$Response(params?: ApiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet(params?: ApiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueByMasterTypeForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet()` */
  static readonly ApiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGetPath = '/api/MasterData/SimpleValue/ByMasterTypeNotFilterByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet$Response(params?: ApiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet(params?: ApiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueByMasterTypeNotFilterByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueKeyValueByMasterTypeGet()` */
  static readonly ApiMasterDataSimpleValueKeyValueByMasterTypeGetPath = '/api/MasterData/SimpleValue/KeyValueByMasterType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueKeyValueByMasterTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueKeyValueByMasterTypeGet$Response(params?: ApiMasterDataSimpleValueKeyValueByMasterTypeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>> {
    return apiMasterDataSimpleValueKeyValueByMasterTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueKeyValueByMasterTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueKeyValueByMasterTypeGet(params?: ApiMasterDataSimpleValueKeyValueByMasterTypeGet$Params, context?: HttpContext): Observable<Array<MasterSimpleKeyValueItem>> {
    return this.apiMasterDataSimpleValueKeyValueByMasterTypeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>): Array<MasterSimpleKeyValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGetPath = '/api/MasterData/SimpleValue/KeyValueByMasterTypeForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet$Response(params?: ApiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>> {
    return apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet(params?: ApiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<MasterSimpleKeyValueItem>> {
    return this.apiMasterDataSimpleValueKeyValueByMasterTypeForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>): Array<MasterSimpleKeyValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet()` */
  static readonly ApiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGetPath = '/api/MasterData/SimpleValue/KeyValueByMasterTypeNotFilterByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet$Response(params?: ApiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>> {
    return apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet(params?: ApiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<MasterSimpleKeyValueItem>> {
    return this.apiMasterDataSimpleValueKeyValueByMasterTypeNotFilterByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>): Array<MasterSimpleKeyValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueValueByMasterTypeGet()` */
  static readonly ApiMasterDataSimpleValueValueByMasterTypeGetPath = '/api/MasterData/SimpleValue/ValueByMasterType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueValueByMasterTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueValueByMasterTypeGet$Response(params?: ApiMasterDataSimpleValueValueByMasterTypeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataSimpleValueValueByMasterTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueValueByMasterTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueValueByMasterTypeGet(params?: ApiMasterDataSimpleValueValueByMasterTypeGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataSimpleValueValueByMasterTypeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGetPath = '/api/MasterData/SimpleValue/ValueByMasterTypeForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet$Response(params?: ApiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet(params?: ApiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataSimpleValueValueByMasterTypeForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet()` */
  static readonly ApiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGetPath = '/api/MasterData/SimpleValue/ValueByMasterTypeNotFilterByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet$Response(params?: ApiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet(params?: ApiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataSimpleValueValueByMasterTypeNotFilterByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueActiveGet()` */
  static readonly ApiMasterDataSimpleValueActiveGetPath = '/api/MasterData/SimpleValue/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueActiveGet$Response(params?: ApiMasterDataSimpleValueActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueActiveGet(params?: ApiMasterDataSimpleValueActiveGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueMaintainGet()` */
  static readonly ApiMasterDataSimpleValueMaintainGetPath = '/api/MasterData/SimpleValue/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueMaintainGet$Response(params?: ApiMasterDataSimpleValueMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueMaintainGet(params?: ApiMasterDataSimpleValueMaintainGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueAllGet()` */
  static readonly ApiMasterDataSimpleValueAllGetPath = '/api/MasterData/SimpleValue/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueAllGet$Response(params?: ApiMasterDataSimpleValueAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiMasterDataSimpleValueAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueAllGet(params?: ApiMasterDataSimpleValueAllGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiMasterDataSimpleValueAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueAddPost()` */
  static readonly ApiMasterDataSimpleValueAddPostPath = '/api/MasterData/SimpleValue/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSimpleValueAddPost$Response(params?: ApiMasterDataSimpleValueAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSimpleValueAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSimpleValueAddPost(params?: ApiMasterDataSimpleValueAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSimpleValueAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueEditPost()` */
  static readonly ApiMasterDataSimpleValueEditPostPath = '/api/MasterData/SimpleValue/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSimpleValueEditPost$Response(params?: ApiMasterDataSimpleValueEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSimpleValueEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSimpleValueEditPost(params?: ApiMasterDataSimpleValueEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSimpleValueEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSimpleValueDeleteGet()` */
  static readonly ApiMasterDataSimpleValueDeleteGetPath = '/api/MasterData/SimpleValue/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSimpleValueDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueDeleteGet$Response(params?: ApiMasterDataSimpleValueDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSimpleValueDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSimpleValueDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSimpleValueDeleteGet(params?: ApiMasterDataSimpleValueDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSimpleValueDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
