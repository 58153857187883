/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { MasterSimpleValueItem } from '../../models/master-simple-value-item';

export interface ApiMasterDataSimpleValueActiveGet$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiMasterDataSimpleValueActiveGet(http: HttpClient, rootUrl: string, params?: ApiMasterDataSimpleValueActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiMasterDataSimpleValueActiveGet.PATH, 'get');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>;
    })
  );
}

apiMasterDataSimpleValueActiveGet.PATH = '/api/MasterData/SimpleValue/Active';
