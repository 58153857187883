/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost } from '../fn/sap-interface-po-creation-response/api-interface-sap-interface-po-creation-response-update-po-creation-result-for-lsp-post';
import { ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Params } from '../fn/sap-interface-po-creation-response/api-interface-sap-interface-po-creation-response-update-po-creation-result-for-lsp-post';
import { apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost } from '../fn/sap-interface-po-creation-response/api-interface-sap-interface-po-creation-response-update-po-creation-result-post';
import { ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost$Params } from '../fn/sap-interface-po-creation-response/api-interface-sap-interface-po-creation-response-update-po-creation-result-post';

@Injectable({ providedIn: 'root' })
export class SapInterfacePoCreationResponseAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost()` */
  static readonly ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPostPath = '/api/interface/SAPInterfacePOCreationResponse/UpdatePOCreationResult';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost$Response(params?: ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost(params?: ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost$Params, context?: HttpContext): Observable<void> {
    return this.apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost()` */
  static readonly ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPostPath = '/api/interface/SAPInterfacePOCreationResponse/UpdatePOCreationResultForLSP';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Response(params?: ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost(params?: ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Params, context?: HttpContext): Observable<void> {
    return this.apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
