/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiTaskListContractWorksheetContractWorksheetTaskListPost } from '../fn/contract-worksheet-task-list/api-task-list-contract-worksheet-contract-worksheet-task-list-post';
import { ApiTaskListContractWorksheetContractWorksheetTaskListPost$Params } from '../fn/contract-worksheet-task-list/api-task-list-contract-worksheet-contract-worksheet-task-list-post';
import { ContractWorksheetTaskListResultItem } from '../models/contract-worksheet-task-list-result-item';

@Injectable({ providedIn: 'root' })
export class ContractWorksheetTaskListAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTaskListContractWorksheetContractWorksheetTaskListPost()` */
  static readonly ApiTaskListContractWorksheetContractWorksheetTaskListPostPath = '/api/task-list/contract-worksheet/ContractWorksheetTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListContractWorksheetContractWorksheetTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListContractWorksheetContractWorksheetTaskListPost$Response(params?: ApiTaskListContractWorksheetContractWorksheetTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetTaskListResultItem>>> {
    return apiTaskListContractWorksheetContractWorksheetTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListContractWorksheetContractWorksheetTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListContractWorksheetContractWorksheetTaskListPost(params?: ApiTaskListContractWorksheetContractWorksheetTaskListPost$Params, context?: HttpContext): Observable<Array<ContractWorksheetTaskListResultItem>> {
    return this.apiTaskListContractWorksheetContractWorksheetTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetTaskListResultItem>>): Array<ContractWorksheetTaskListResultItem> => r.body)
    );
  }

}
