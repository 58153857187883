/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiRequestCalculationGetTaxCodePost$Params {
  vendorCountry?: string;
  unit?: string;
  itemSource?: string;
  ftaPrivilege?: string;
  accountAssignmentCategory?: string;
  orderNumber?: string;
  category?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestCalculationGetTaxCodePost(http: HttpClient, rootUrl: string, params?: ApiRequestCalculationGetTaxCodePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestCalculationGetTaxCodePost.PATH, 'post');
  if (params) {
    rb.query('vendorCountry', params.vendorCountry, {});
    rb.query('unit', params.unit, {});
    rb.query('itemSource', params.itemSource, {});
    rb.query('ftaPrivilege', params.ftaPrivilege, {});
    rb.query('accountAssignmentCategory', params.accountAssignmentCategory, {});
    rb.query('orderNumber', params.orderNumber, {});
    rb.query('category', params.category, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<string>;
    })
  );
}

apiRequestCalculationGetTaxCodePost.PATH = '/api/request/calculation/GetTaxCode';
