/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { RfqSetupEmailItem } from '../../models/rfq-setup-email-item';
import { RfqTransactionItem } from '../../models/rfq-transaction-item';

export interface ApiRequestNewRfqSubmitNewRfqPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: RfqSetupEmailItem
}

export function apiRequestNewRfqSubmitNewRfqPost(http: HttpClient, rootUrl: string, params?: ApiRequestNewRfqSubmitNewRfqPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTransactionItem>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestNewRfqSubmitNewRfqPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<RfqTransactionItem>>;
    })
  );
}

apiRequestNewRfqSubmitNewRfqPost.PATH = '/api/request/new-rfq/SubmitNewRfq';
