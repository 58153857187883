/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataAuctionEmailTemplateActiveGet } from '../fn/master-auction-email-template/api-master-data-auction-email-template-active-get';
import { ApiMasterDataAuctionEmailTemplateActiveGet$Params } from '../fn/master-auction-email-template/api-master-data-auction-email-template-active-get';
import { apiMasterDataAuctionEmailTemplateAddPost } from '../fn/master-auction-email-template/api-master-data-auction-email-template-add-post';
import { ApiMasterDataAuctionEmailTemplateAddPost$Params } from '../fn/master-auction-email-template/api-master-data-auction-email-template-add-post';
import { apiMasterDataAuctionEmailTemplateAllGet } from '../fn/master-auction-email-template/api-master-data-auction-email-template-all-get';
import { ApiMasterDataAuctionEmailTemplateAllGet$Params } from '../fn/master-auction-email-template/api-master-data-auction-email-template-all-get';
import { apiMasterDataAuctionEmailTemplateDeleteGet } from '../fn/master-auction-email-template/api-master-data-auction-email-template-delete-get';
import { ApiMasterDataAuctionEmailTemplateDeleteGet$Params } from '../fn/master-auction-email-template/api-master-data-auction-email-template-delete-get';
import { apiMasterDataAuctionEmailTemplateEditPost } from '../fn/master-auction-email-template/api-master-data-auction-email-template-edit-post';
import { ApiMasterDataAuctionEmailTemplateEditPost$Params } from '../fn/master-auction-email-template/api-master-data-auction-email-template-edit-post';
import { apiMasterDataAuctionEmailTemplateMaintainGet } from '../fn/master-auction-email-template/api-master-data-auction-email-template-maintain-get';
import { ApiMasterDataAuctionEmailTemplateMaintainGet$Params } from '../fn/master-auction-email-template/api-master-data-auction-email-template-maintain-get';
import { MasterAuctionEmailTemplateItem } from '../models/master-auction-email-template-item';

@Injectable({ providedIn: 'root' })
export class MasterAuctionEmailTemplateAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataAuctionEmailTemplateActiveGet()` */
  static readonly ApiMasterDataAuctionEmailTemplateActiveGetPath = '/api/MasterData/AuctionEmailTemplate/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionEmailTemplateActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateActiveGet$Response(params?: ApiMasterDataAuctionEmailTemplateActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterAuctionEmailTemplateItem>>> {
    return apiMasterDataAuctionEmailTemplateActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionEmailTemplateActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateActiveGet(params?: ApiMasterDataAuctionEmailTemplateActiveGet$Params, context?: HttpContext): Observable<Array<MasterAuctionEmailTemplateItem>> {
    return this.apiMasterDataAuctionEmailTemplateActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterAuctionEmailTemplateItem>>): Array<MasterAuctionEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionEmailTemplateMaintainGet()` */
  static readonly ApiMasterDataAuctionEmailTemplateMaintainGetPath = '/api/MasterData/AuctionEmailTemplate/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionEmailTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateMaintainGet$Response(params?: ApiMasterDataAuctionEmailTemplateMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterAuctionEmailTemplateItem>>> {
    return apiMasterDataAuctionEmailTemplateMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionEmailTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateMaintainGet(params?: ApiMasterDataAuctionEmailTemplateMaintainGet$Params, context?: HttpContext): Observable<Array<MasterAuctionEmailTemplateItem>> {
    return this.apiMasterDataAuctionEmailTemplateMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterAuctionEmailTemplateItem>>): Array<MasterAuctionEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionEmailTemplateAllGet()` */
  static readonly ApiMasterDataAuctionEmailTemplateAllGetPath = '/api/MasterData/AuctionEmailTemplate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionEmailTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateAllGet$Response(params?: ApiMasterDataAuctionEmailTemplateAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterAuctionEmailTemplateItem>>> {
    return apiMasterDataAuctionEmailTemplateAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionEmailTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateAllGet(params?: ApiMasterDataAuctionEmailTemplateAllGet$Params, context?: HttpContext): Observable<Array<MasterAuctionEmailTemplateItem>> {
    return this.apiMasterDataAuctionEmailTemplateAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterAuctionEmailTemplateItem>>): Array<MasterAuctionEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionEmailTemplateAddPost()` */
  static readonly ApiMasterDataAuctionEmailTemplateAddPostPath = '/api/MasterData/AuctionEmailTemplate/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionEmailTemplateAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionEmailTemplateAddPost$Response(params?: ApiMasterDataAuctionEmailTemplateAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataAuctionEmailTemplateAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionEmailTemplateAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionEmailTemplateAddPost(params?: ApiMasterDataAuctionEmailTemplateAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataAuctionEmailTemplateAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionEmailTemplateEditPost()` */
  static readonly ApiMasterDataAuctionEmailTemplateEditPostPath = '/api/MasterData/AuctionEmailTemplate/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionEmailTemplateEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionEmailTemplateEditPost$Response(params?: ApiMasterDataAuctionEmailTemplateEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataAuctionEmailTemplateEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionEmailTemplateEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionEmailTemplateEditPost(params?: ApiMasterDataAuctionEmailTemplateEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataAuctionEmailTemplateEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionEmailTemplateDeleteGet()` */
  static readonly ApiMasterDataAuctionEmailTemplateDeleteGetPath = '/api/MasterData/AuctionEmailTemplate/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionEmailTemplateDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateDeleteGet$Response(params?: ApiMasterDataAuctionEmailTemplateDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataAuctionEmailTemplateDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionEmailTemplateDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionEmailTemplateDeleteGet(params?: ApiMasterDataAuctionEmailTemplateDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataAuctionEmailTemplateDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
