import { Injectable } from "@angular/core";
import { decodeQueryParams, encodeQueryParams } from "../helpers/queryParamsHelper";

@Injectable({
  providedIn: 'root'
})
export class WorkingGroupService {

  key: string = 'UserWorkingGroup'

  setUserWorkingGroup(workingGroup: string): void {
    localStorage.setItem(this.key, encodeQueryParams(workingGroup));
  }

  getUserWorkingGroup(): string {
    let userWorkingGroup: string = localStorage.getItem(this.key);

    if (userWorkingGroup) {
      return decodeQueryParams(userWorkingGroup);
    }
    else {
      return null;
    }
  }

  onStorageWorkGroupChange() {
    // Check for changes in localStorage on component initialization
    window.addEventListener('storage', (event) => {
      if (event.key == this.key) {
        window.location.reload();
      }
    });
  }
}
