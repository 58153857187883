/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataGreenProcurementActiveGet } from '../fn/master-green-procurement/api-master-data-green-procurement-active-get';
import { ApiMasterDataGreenProcurementActiveGet$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-active-get';
import { apiMasterDataGreenProcurementAddPost } from '../fn/master-green-procurement/api-master-data-green-procurement-add-post';
import { ApiMasterDataGreenProcurementAddPost$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-add-post';
import { apiMasterDataGreenProcurementAllGet } from '../fn/master-green-procurement/api-master-data-green-procurement-all-get';
import { ApiMasterDataGreenProcurementAllGet$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-all-get';
import { apiMasterDataGreenProcurementDeleteGet } from '../fn/master-green-procurement/api-master-data-green-procurement-delete-get';
import { ApiMasterDataGreenProcurementDeleteGet$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-delete-get';
import { apiMasterDataGreenProcurementEditPost } from '../fn/master-green-procurement/api-master-data-green-procurement-edit-post';
import { ApiMasterDataGreenProcurementEditPost$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-edit-post';
import { apiMasterDataGreenProcurementExportGet } from '../fn/master-green-procurement/api-master-data-green-procurement-export-get';
import { ApiMasterDataGreenProcurementExportGet$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-export-get';
import { apiMasterDataGreenProcurementImportPost } from '../fn/master-green-procurement/api-master-data-green-procurement-import-post';
import { ApiMasterDataGreenProcurementImportPost$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-import-post';
import { apiMasterDataGreenProcurementMaintainGet } from '../fn/master-green-procurement/api-master-data-green-procurement-maintain-get';
import { ApiMasterDataGreenProcurementMaintainGet$Params } from '../fn/master-green-procurement/api-master-data-green-procurement-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterGreenProcurementItem } from '../models/master-green-procurement-item';

@Injectable({ providedIn: 'root' })
export class MasterGreenProcurementAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataGreenProcurementActiveGet()` */
  static readonly ApiMasterDataGreenProcurementActiveGetPath = '/api/MasterData/GreenProcurement/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementActiveGet$Response(params?: ApiMasterDataGreenProcurementActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterGreenProcurementItem>>> {
    return apiMasterDataGreenProcurementActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementActiveGet(params?: ApiMasterDataGreenProcurementActiveGet$Params, context?: HttpContext): Observable<Array<MasterGreenProcurementItem>> {
    return this.apiMasterDataGreenProcurementActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterGreenProcurementItem>>): Array<MasterGreenProcurementItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementMaintainGet()` */
  static readonly ApiMasterDataGreenProcurementMaintainGetPath = '/api/MasterData/GreenProcurement/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementMaintainGet$Response(params?: ApiMasterDataGreenProcurementMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterGreenProcurementItem>>> {
    return apiMasterDataGreenProcurementMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementMaintainGet(params?: ApiMasterDataGreenProcurementMaintainGet$Params, context?: HttpContext): Observable<Array<MasterGreenProcurementItem>> {
    return this.apiMasterDataGreenProcurementMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterGreenProcurementItem>>): Array<MasterGreenProcurementItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementAllGet()` */
  static readonly ApiMasterDataGreenProcurementAllGetPath = '/api/MasterData/GreenProcurement/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementAllGet$Response(params?: ApiMasterDataGreenProcurementAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterGreenProcurementItem>>> {
    return apiMasterDataGreenProcurementAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementAllGet(params?: ApiMasterDataGreenProcurementAllGet$Params, context?: HttpContext): Observable<Array<MasterGreenProcurementItem>> {
    return this.apiMasterDataGreenProcurementAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterGreenProcurementItem>>): Array<MasterGreenProcurementItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementAddPost()` */
  static readonly ApiMasterDataGreenProcurementAddPostPath = '/api/MasterData/GreenProcurement/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGreenProcurementAddPost$Response(params?: ApiMasterDataGreenProcurementAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataGreenProcurementAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGreenProcurementAddPost(params?: ApiMasterDataGreenProcurementAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataGreenProcurementAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementEditPost()` */
  static readonly ApiMasterDataGreenProcurementEditPostPath = '/api/MasterData/GreenProcurement/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGreenProcurementEditPost$Response(params?: ApiMasterDataGreenProcurementEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataGreenProcurementEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGreenProcurementEditPost(params?: ApiMasterDataGreenProcurementEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataGreenProcurementEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementDeleteGet()` */
  static readonly ApiMasterDataGreenProcurementDeleteGetPath = '/api/MasterData/GreenProcurement/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementDeleteGet$Response(params?: ApiMasterDataGreenProcurementDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataGreenProcurementDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementDeleteGet(params?: ApiMasterDataGreenProcurementDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataGreenProcurementDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementImportPost()` */
  static readonly ApiMasterDataGreenProcurementImportPostPath = '/api/MasterData/GreenProcurement/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementImportPost$Response(params?: ApiMasterDataGreenProcurementImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataGreenProcurementImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementImportPost(params?: ApiMasterDataGreenProcurementImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataGreenProcurementImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataGreenProcurementExportGet()` */
  static readonly ApiMasterDataGreenProcurementExportGetPath = '/api/MasterData/GreenProcurement/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGreenProcurementExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementExportGet$Response(params?: ApiMasterDataGreenProcurementExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataGreenProcurementExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGreenProcurementExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGreenProcurementExportGet(params?: ApiMasterDataGreenProcurementExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataGreenProcurementExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
