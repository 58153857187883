/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { PoCreationResponseLsp } from '../../models/po-creation-response-lsp';

export interface ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Params {
  workingGroup?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: PoCreationResponseLsp
}

export function apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost(http: HttpClient, rootUrl: string, params?: ApiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost.PATH, 'post');
  if (params) {
    rb.query('workingGroup', params.workingGroup, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AzureApiStrictHttpResponse<void>;
    })
  );
}

apiInterfaceSapInterfacePoCreationResponseUpdatePoCreationResultForLspPost.PATH = '/api/interface/SAPInterfacePOCreationResponse/UpdatePOCreationResultForLSP';
