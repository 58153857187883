/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiServerEventServerTimeGet } from '../fn/sever-event/api-server-event-server-time-get';
import { ApiServerEventServerTimeGet$Params } from '../fn/sever-event/api-server-event-server-time-get';

@Injectable({ providedIn: 'root' })
export class SeverEventAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiServerEventServerTimeGet()` */
  static readonly ApiServerEventServerTimeGetPath = '/api/server-event/server-time';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerEventServerTimeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerEventServerTimeGet$Response(params?: ApiServerEventServerTimeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerEventServerTimeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerEventServerTimeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerEventServerTimeGet(params?: ApiServerEventServerTimeGet$Params, context?: HttpContext): Observable<string> {
    return this.apiServerEventServerTimeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

}
