/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiRequestBuyerWorksheetGetIdGet$Params {
  docNo?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestBuyerWorksheetGetIdGet(http: HttpClient, rootUrl: string, params?: ApiRequestBuyerWorksheetGetIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestBuyerWorksheetGetIdGet.PATH, 'get');
  if (params) {
    rb.query('docNo', params.docNo, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<string>;
    })
  );
}

apiRequestBuyerWorksheetGetIdGet.PATH = '/api/request/buyer-worksheet/GetId';
