/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiTaskListAmendmentContractAmendmentTaskListPost } from '../fn/contract-amendment-task-list/api-task-list-amendment-contract-amendment-task-list-post';
import { ApiTaskListAmendmentContractAmendmentTaskListPost$Params } from '../fn/contract-amendment-task-list/api-task-list-amendment-contract-amendment-task-list-post';
import { apiTaskListAmendmentMasterContractAmendmentStatusGet } from '../fn/contract-amendment-task-list/api-task-list-amendment-master-contract-amendment-status-get';
import { ApiTaskListAmendmentMasterContractAmendmentStatusGet$Params } from '../fn/contract-amendment-task-list/api-task-list-amendment-master-contract-amendment-status-get';
import { ContractAmendmentRequestTaskListResultItem } from '../models/contract-amendment-request-task-list-result-item';

@Injectable({ providedIn: 'root' })
export class ContractAmendmentTaskListAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTaskListAmendmentContractAmendmentTaskListPost()` */
  static readonly ApiTaskListAmendmentContractAmendmentTaskListPostPath = '/api/task-list/amendment/ContractAmendmentTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListAmendmentContractAmendmentTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListAmendmentContractAmendmentTaskListPost$Response(params?: ApiTaskListAmendmentContractAmendmentTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractAmendmentRequestTaskListResultItem>>> {
    return apiTaskListAmendmentContractAmendmentTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListAmendmentContractAmendmentTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListAmendmentContractAmendmentTaskListPost(params?: ApiTaskListAmendmentContractAmendmentTaskListPost$Params, context?: HttpContext): Observable<Array<ContractAmendmentRequestTaskListResultItem>> {
    return this.apiTaskListAmendmentContractAmendmentTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractAmendmentRequestTaskListResultItem>>): Array<ContractAmendmentRequestTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListAmendmentMasterContractAmendmentStatusGet()` */
  static readonly ApiTaskListAmendmentMasterContractAmendmentStatusGetPath = '/api/task-list/amendment/MasterContractAmendmentStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListAmendmentMasterContractAmendmentStatusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListAmendmentMasterContractAmendmentStatusGet$Response(params?: ApiTaskListAmendmentMasterContractAmendmentStatusGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiTaskListAmendmentMasterContractAmendmentStatusGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListAmendmentMasterContractAmendmentStatusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListAmendmentMasterContractAmendmentStatusGet(params?: ApiTaskListAmendmentMasterContractAmendmentStatusGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiTaskListAmendmentMasterContractAmendmentStatusGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
