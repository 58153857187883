/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestContractWorksheetExportExportContractWorksheetTemplateGet } from '../fn/contract-worksheet-export/api-request-contract-worksheet-export-export-contract-worksheet-template-get';
import { ApiRequestContractWorksheetExportExportContractWorksheetTemplateGet$Params } from '../fn/contract-worksheet-export/api-request-contract-worksheet-export-export-contract-worksheet-template-get';
import { apiRequestContractWorksheetExportExportContractWorksheetZipGet } from '../fn/contract-worksheet-export/api-request-contract-worksheet-export-export-contract-worksheet-zip-get';
import { ApiRequestContractWorksheetExportExportContractWorksheetZipGet$Params } from '../fn/contract-worksheet-export/api-request-contract-worksheet-export-export-contract-worksheet-zip-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';

@Injectable({ providedIn: 'root' })
export class ContractWorksheetExportAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestContractWorksheetExportExportContractWorksheetTemplateGet()` */
  static readonly ApiRequestContractWorksheetExportExportContractWorksheetTemplateGetPath = '/api/request/contract-worksheet-export/ExportContractWorksheetTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetExportExportContractWorksheetTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetExportExportContractWorksheetTemplateGet$Response(params?: ApiRequestContractWorksheetExportExportContractWorksheetTemplateGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestContractWorksheetExportExportContractWorksheetTemplateGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetExportExportContractWorksheetTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetExportExportContractWorksheetTemplateGet(params?: ApiRequestContractWorksheetExportExportContractWorksheetTemplateGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestContractWorksheetExportExportContractWorksheetTemplateGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetExportExportContractWorksheetZipGet()` */
  static readonly ApiRequestContractWorksheetExportExportContractWorksheetZipGetPath = '/api/request/contract-worksheet-export/ExportContractWorksheetZip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetExportExportContractWorksheetZipGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetExportExportContractWorksheetZipGet$Response(params?: ApiRequestContractWorksheetExportExportContractWorksheetZipGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestContractWorksheetExportExportContractWorksheetZipGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetExportExportContractWorksheetZipGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetExportExportContractWorksheetZipGet(params?: ApiRequestContractWorksheetExportExportContractWorksheetZipGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestContractWorksheetExportExportContractWorksheetZipGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
