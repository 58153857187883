/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { TestDbRes } from '../../models/test-db-res';

export interface ApiServerTestTryQueryGet$Params {
  secret?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiServerTestTryQueryGet(http: HttpClient, rootUrl: string, params?: ApiServerTestTryQueryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<TestDbRes>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiServerTestTryQueryGet.PATH, 'get');
  if (params) {
    rb.query('secret', params.secret, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<TestDbRes>>;
    })
  );
}

apiServerTestTryQueryGet.PATH = '/api/server-test/try-query';
