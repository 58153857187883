import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConfigs } from '../commons/global-config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, tap, catchError, concatMap } from 'rxjs/operators';
import { AccountResp, AccountWithRoleResp } from '../api-azure/models';
import { MeAzureApiService } from '../api-azure/services';
import { find, includes, isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  me: AccountWithRoleResp;

  private subjectLoginDisplay = new BehaviorSubject<boolean>(false);
  private subjectAccountInfo = new BehaviorSubject<AccountWithRoleResp>(null);

  get getCurrentAD(): string | null {
    return localStorage.getItem('currentAD');
  }

  constructor() {
    this.subjectLoginDisplay.next(false);
  }

  setLoginDisplay(loginDisplay: boolean) {
    this.subjectLoginDisplay.next(loginDisplay);
  }

  getLoginDisplay() {
    return this.subjectLoginDisplay.asObservable();
  }

  setAccount(account: AccountWithRoleResp) {
    this.me = account;
    this.subjectAccountInfo.next(account);
  }

  getAccount() {
    return this.subjectAccountInfo.asObservable();
  }

  getAccessToken(): Promise<string | null> {
    const accessTokenStore = find(Object.entries(localStorage), ([key, value]) => {
      return includes(key, "api://" + GlobalConfigs.datas.msal?.auth?.clientId + "/default");
    });
    const accessTokenObj = JSON.parse(accessTokenStore?.[1]);

    return accessTokenObj ? accessTokenObj.secret : null;
  }
}
