/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataPlantAllForContractWorksheetGet } from '../fn/master-plant/api-master-data-plant-all-for-contract-worksheet-get';
import { ApiMasterDataPlantAllForContractWorksheetGet$Params } from '../fn/master-plant/api-master-data-plant-all-for-contract-worksheet-get';
import { apiMasterDataPlantAllForPrGet } from '../fn/master-plant/api-master-data-plant-all-for-pr-get';
import { ApiMasterDataPlantAllForPrGet$Params } from '../fn/master-plant/api-master-data-plant-all-for-pr-get';
import { apiMasterDataPlantAllGet } from '../fn/master-plant/api-master-data-plant-all-get';
import { ApiMasterDataPlantAllGet$Params } from '../fn/master-plant/api-master-data-plant-all-get';
import { MasterPlantItem } from '../models/master-plant-item';

@Injectable({ providedIn: 'root' })
export class MasterPlantAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataPlantAllGet()` */
  static readonly ApiMasterDataPlantAllGetPath = '/api/MasterData/Plant/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPlantAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPlantAllGet$Response(params?: ApiMasterDataPlantAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPlantItem>>> {
    return apiMasterDataPlantAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPlantAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPlantAllGet(params?: ApiMasterDataPlantAllGet$Params, context?: HttpContext): Observable<Array<MasterPlantItem>> {
    return this.apiMasterDataPlantAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPlantItem>>): Array<MasterPlantItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPlantAllForPrGet()` */
  static readonly ApiMasterDataPlantAllForPrGetPath = '/api/MasterData/Plant/AllForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPlantAllForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPlantAllForPrGet$Response(params?: ApiMasterDataPlantAllForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPlantItem>>> {
    return apiMasterDataPlantAllForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPlantAllForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPlantAllForPrGet(params?: ApiMasterDataPlantAllForPrGet$Params, context?: HttpContext): Observable<Array<MasterPlantItem>> {
    return this.apiMasterDataPlantAllForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPlantItem>>): Array<MasterPlantItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPlantAllForContractWorksheetGet()` */
  static readonly ApiMasterDataPlantAllForContractWorksheetGetPath = '/api/MasterData/Plant/AllForContractWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPlantAllForContractWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPlantAllForContractWorksheetGet$Response(params?: ApiMasterDataPlantAllForContractWorksheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPlantItem>>> {
    return apiMasterDataPlantAllForContractWorksheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPlantAllForContractWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPlantAllForContractWorksheetGet(params?: ApiMasterDataPlantAllForContractWorksheetGet$Params, context?: HttpContext): Observable<Array<MasterPlantItem>> {
    return this.apiMasterDataPlantAllForContractWorksheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPlantItem>>): Array<MasterPlantItem> => r.body)
    );
  }

}
