/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet } from '../fn/master-term-condition-template/api-master-data-term-condition-template-active-for-buyer-work-sheet-get';
import { ApiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-active-for-buyer-work-sheet-get';
import { apiMasterDataTermConditionTemplateActiveGet } from '../fn/master-term-condition-template/api-master-data-term-condition-template-active-get';
import { ApiMasterDataTermConditionTemplateActiveGet$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-active-get';
import { apiMasterDataTermConditionTemplateAddPost } from '../fn/master-term-condition-template/api-master-data-term-condition-template-add-post';
import { ApiMasterDataTermConditionTemplateAddPost$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-add-post';
import { apiMasterDataTermConditionTemplateAllGet } from '../fn/master-term-condition-template/api-master-data-term-condition-template-all-get';
import { ApiMasterDataTermConditionTemplateAllGet$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-all-get';
import { apiMasterDataTermConditionTemplateDeleteGet } from '../fn/master-term-condition-template/api-master-data-term-condition-template-delete-get';
import { ApiMasterDataTermConditionTemplateDeleteGet$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-delete-get';
import { apiMasterDataTermConditionTemplateEditPost } from '../fn/master-term-condition-template/api-master-data-term-condition-template-edit-post';
import { ApiMasterDataTermConditionTemplateEditPost$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-edit-post';
import { apiMasterDataTermConditionTemplateMaintainGet } from '../fn/master-term-condition-template/api-master-data-term-condition-template-maintain-get';
import { ApiMasterDataTermConditionTemplateMaintainGet$Params } from '../fn/master-term-condition-template/api-master-data-term-condition-template-maintain-get';
import { MasterTermConditionTemplate } from '../models/master-term-condition-template';

@Injectable({ providedIn: 'root' })
export class MasterTermConditionTemplateAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateActiveGet()` */
  static readonly ApiMasterDataTermConditionTemplateActiveGetPath = '/api/MasterData/TermConditionTemplate/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateActiveGet$Response(params?: ApiMasterDataTermConditionTemplateActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>> {
    return apiMasterDataTermConditionTemplateActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateActiveGet(params?: ApiMasterDataTermConditionTemplateActiveGet$Params, context?: HttpContext): Observable<Array<MasterTermConditionTemplate>> {
    return this.apiMasterDataTermConditionTemplateActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>): Array<MasterTermConditionTemplate> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGetPath = '/api/MasterData/TermConditionTemplate/ActiveForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet$Response(params?: ApiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>> {
    return apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet(params?: ApiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<Array<MasterTermConditionTemplate>> {
    return this.apiMasterDataTermConditionTemplateActiveForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>): Array<MasterTermConditionTemplate> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateMaintainGet()` */
  static readonly ApiMasterDataTermConditionTemplateMaintainGetPath = '/api/MasterData/TermConditionTemplate/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateMaintainGet$Response(params?: ApiMasterDataTermConditionTemplateMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>> {
    return apiMasterDataTermConditionTemplateMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateMaintainGet(params?: ApiMasterDataTermConditionTemplateMaintainGet$Params, context?: HttpContext): Observable<Array<MasterTermConditionTemplate>> {
    return this.apiMasterDataTermConditionTemplateMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>): Array<MasterTermConditionTemplate> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateAllGet()` */
  static readonly ApiMasterDataTermConditionTemplateAllGetPath = '/api/MasterData/TermConditionTemplate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateAllGet$Response(params?: ApiMasterDataTermConditionTemplateAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>> {
    return apiMasterDataTermConditionTemplateAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateAllGet(params?: ApiMasterDataTermConditionTemplateAllGet$Params, context?: HttpContext): Observable<Array<MasterTermConditionTemplate>> {
    return this.apiMasterDataTermConditionTemplateAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterTermConditionTemplate>>): Array<MasterTermConditionTemplate> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateAddPost()` */
  static readonly ApiMasterDataTermConditionTemplateAddPostPath = '/api/MasterData/TermConditionTemplate/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTermConditionTemplateAddPost$Response(params?: ApiMasterDataTermConditionTemplateAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataTermConditionTemplateAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTermConditionTemplateAddPost(params?: ApiMasterDataTermConditionTemplateAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataTermConditionTemplateAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateEditPost()` */
  static readonly ApiMasterDataTermConditionTemplateEditPostPath = '/api/MasterData/TermConditionTemplate/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTermConditionTemplateEditPost$Response(params?: ApiMasterDataTermConditionTemplateEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataTermConditionTemplateEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataTermConditionTemplateEditPost(params?: ApiMasterDataTermConditionTemplateEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataTermConditionTemplateEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataTermConditionTemplateDeleteGet()` */
  static readonly ApiMasterDataTermConditionTemplateDeleteGetPath = '/api/MasterData/TermConditionTemplate/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataTermConditionTemplateDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateDeleteGet$Response(params?: ApiMasterDataTermConditionTemplateDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataTermConditionTemplateDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataTermConditionTemplateDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataTermConditionTemplateDeleteGet(params?: ApiMasterDataTermConditionTemplateDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataTermConditionTemplateDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
