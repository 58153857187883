/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataKeywordBrandGet } from '../fn/master-keyword/api-master-data-keyword-brand-get';
import { ApiMasterDataKeywordBrandGet$Params } from '../fn/master-keyword/api-master-data-keyword-brand-get';
import { apiMasterDataKeywordCategoryFromMainCategoryGet } from '../fn/master-keyword/api-master-data-keyword-category-from-main-category-get';
import { ApiMasterDataKeywordCategoryFromMainCategoryGet$Params } from '../fn/master-keyword/api-master-data-keyword-category-from-main-category-get';
import { apiMasterDataKeywordCategoryGet } from '../fn/master-keyword/api-master-data-keyword-category-get';
import { ApiMasterDataKeywordCategoryGet$Params } from '../fn/master-keyword/api-master-data-keyword-category-get';
import { apiMasterDataKeywordCategoryWithMainCategoryGet } from '../fn/master-keyword/api-master-data-keyword-category-with-main-category-get';
import { ApiMasterDataKeywordCategoryWithMainCategoryGet$Params } from '../fn/master-keyword/api-master-data-keyword-category-with-main-category-get';
import { apiMasterDataKeywordMainCategoryGet } from '../fn/master-keyword/api-master-data-keyword-main-category-get';
import { ApiMasterDataKeywordMainCategoryGet$Params } from '../fn/master-keyword/api-master-data-keyword-main-category-get';
import { apiMasterDataKeywordServiceGet } from '../fn/master-keyword/api-master-data-keyword-service-get';
import { ApiMasterDataKeywordServiceGet$Params } from '../fn/master-keyword/api-master-data-keyword-service-get';
import { MasterKeywordMainCategory } from '../models/master-keyword-main-category';

@Injectable({ providedIn: 'root' })
export class MasterKeywordAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataKeywordMainCategoryGet()` */
  static readonly ApiMasterDataKeywordMainCategoryGetPath = '/api/MasterData/Keyword/MainCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataKeywordMainCategoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordMainCategoryGet$Response(params?: ApiMasterDataKeywordMainCategoryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataKeywordMainCategoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataKeywordMainCategoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordMainCategoryGet(params?: ApiMasterDataKeywordMainCategoryGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataKeywordMainCategoryGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataKeywordCategoryWithMainCategoryGet()` */
  static readonly ApiMasterDataKeywordCategoryWithMainCategoryGetPath = '/api/MasterData/Keyword/CategoryWithMainCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataKeywordCategoryWithMainCategoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordCategoryWithMainCategoryGet$Response(params?: ApiMasterDataKeywordCategoryWithMainCategoryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterKeywordMainCategory>>> {
    return apiMasterDataKeywordCategoryWithMainCategoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataKeywordCategoryWithMainCategoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordCategoryWithMainCategoryGet(params?: ApiMasterDataKeywordCategoryWithMainCategoryGet$Params, context?: HttpContext): Observable<Array<MasterKeywordMainCategory>> {
    return this.apiMasterDataKeywordCategoryWithMainCategoryGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterKeywordMainCategory>>): Array<MasterKeywordMainCategory> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataKeywordCategoryFromMainCategoryGet()` */
  static readonly ApiMasterDataKeywordCategoryFromMainCategoryGetPath = '/api/MasterData/Keyword/CategoryFromMainCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataKeywordCategoryFromMainCategoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordCategoryFromMainCategoryGet$Response(params?: ApiMasterDataKeywordCategoryFromMainCategoryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataKeywordCategoryFromMainCategoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataKeywordCategoryFromMainCategoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordCategoryFromMainCategoryGet(params?: ApiMasterDataKeywordCategoryFromMainCategoryGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataKeywordCategoryFromMainCategoryGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataKeywordCategoryGet()` */
  static readonly ApiMasterDataKeywordCategoryGetPath = '/api/MasterData/Keyword/Category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataKeywordCategoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordCategoryGet$Response(params?: ApiMasterDataKeywordCategoryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataKeywordCategoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataKeywordCategoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordCategoryGet(params?: ApiMasterDataKeywordCategoryGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataKeywordCategoryGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataKeywordServiceGet()` */
  static readonly ApiMasterDataKeywordServiceGetPath = '/api/MasterData/Keyword/Service';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataKeywordServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordServiceGet$Response(params?: ApiMasterDataKeywordServiceGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataKeywordServiceGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataKeywordServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordServiceGet(params?: ApiMasterDataKeywordServiceGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataKeywordServiceGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataKeywordBrandGet()` */
  static readonly ApiMasterDataKeywordBrandGetPath = '/api/MasterData/Keyword/Brand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataKeywordBrandGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordBrandGet$Response(params?: ApiMasterDataKeywordBrandGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiMasterDataKeywordBrandGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataKeywordBrandGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataKeywordBrandGet(params?: ApiMasterDataKeywordBrandGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiMasterDataKeywordBrandGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
