import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, firstValueFrom, of } from 'rxjs';
import { MeAzureApiService } from './api-azure/services';
import { AuthService } from './services/auth.service';
import { AccountWithRoleResp } from './api-azure/models';
import { GlobalConfigs } from './commons/global-config';

@Injectable({
  providedIn: 'root'
})

export class LoadUserResolver implements Resolve<Observable<AccountWithRoleResp>> {

  constructor(
    private accountService: MeAzureApiService,
    private authService: AuthService
  ) { }

  async resolve(): Promise<Observable<AccountWithRoleResp>> {
    let user = await firstValueFrom(this.accountService.apiMeGet());
    if (user == null) window.location.href = GlobalConfigs.datas.webClientBaseUri + "/error/401";
    this.authService.setAccount(user);
    return of(user);
  }
}
