/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestCalculationGetOverseaCodePost } from '../fn/calculation/api-request-calculation-get-oversea-code-post';
import { ApiRequestCalculationGetOverseaCodePost$Params } from '../fn/calculation/api-request-calculation-get-oversea-code-post';
import { apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost } from '../fn/calculation/api-request-calculation-get-po-doc-type-by-daily-info-request-items-post';
import { ApiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost$Params } from '../fn/calculation/api-request-calculation-get-po-doc-type-by-daily-info-request-items-post';
import { apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost } from '../fn/calculation/api-request-calculation-get-po-doc-type-by-synergy-transaction-detail-items-and-vendors-post';
import { ApiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost$Params } from '../fn/calculation/api-request-calculation-get-po-doc-type-by-synergy-transaction-detail-items-and-vendors-post';
import { apiRequestCalculationGetPoDocTypePost } from '../fn/calculation/api-request-calculation-get-po-doc-type-post';
import { ApiRequestCalculationGetPoDocTypePost$Params } from '../fn/calculation/api-request-calculation-get-po-doc-type-post';
import { apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost } from '../fn/calculation/api-request-calculation-get-tax-code-by-daily-info-request-items-post';
import { ApiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost$Params } from '../fn/calculation/api-request-calculation-get-tax-code-by-daily-info-request-items-post';
import { apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost } from '../fn/calculation/api-request-calculation-get-tax-code-by-synergy-transaction-detail-items-and-vendors-post';
import { ApiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost$Params } from '../fn/calculation/api-request-calculation-get-tax-code-by-synergy-transaction-detail-items-and-vendors-post';
import { apiRequestCalculationGetTaxCodePost } from '../fn/calculation/api-request-calculation-get-tax-code-post';
import { ApiRequestCalculationGetTaxCodePost$Params } from '../fn/calculation/api-request-calculation-get-tax-code-post';
import { apiRequestCalculationGetWithHoldingTaxPost } from '../fn/calculation/api-request-calculation-get-with-holding-tax-post';
import { ApiRequestCalculationGetWithHoldingTaxPost$Params } from '../fn/calculation/api-request-calculation-get-with-holding-tax-post';
import { DailyInfoRequestItem } from '../models/daily-info-request-item';
import { SynergyTransactionDetailItem } from '../models/synergy-transaction-detail-item';

@Injectable({ providedIn: 'root' })
export class CalculationAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestCalculationGetTaxCodePost()` */
  static readonly ApiRequestCalculationGetTaxCodePostPath = '/api/request/calculation/GetTaxCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetTaxCodePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetTaxCodePost$Response(params?: ApiRequestCalculationGetTaxCodePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestCalculationGetTaxCodePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetTaxCodePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetTaxCodePost(params?: ApiRequestCalculationGetTaxCodePost$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestCalculationGetTaxCodePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost()` */
  static readonly ApiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPostPath = '/api/request/calculation/GetTaxCodeBySynergyTransactionDetailItemsAndVendors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost$Response(params?: ApiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<SynergyTransactionDetailItem>>> {
    return apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost(params?: ApiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost$Params, context?: HttpContext): Observable<Array<SynergyTransactionDetailItem>> {
    return this.apiRequestCalculationGetTaxCodeBySynergyTransactionDetailItemsAndVendorsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<SynergyTransactionDetailItem>>): Array<SynergyTransactionDetailItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost()` */
  static readonly ApiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPostPath = '/api/request/calculation/GetTaxCodeByDailyInfoRequestItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost$Response(params?: ApiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<DailyInfoRequestItem>>> {
    return apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost(params?: ApiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost$Params, context?: HttpContext): Observable<Array<DailyInfoRequestItem>> {
    return this.apiRequestCalculationGetTaxCodeByDailyInfoRequestItemsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<DailyInfoRequestItem>>): Array<DailyInfoRequestItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetPoDocTypePost()` */
  static readonly ApiRequestCalculationGetPoDocTypePostPath = '/api/request/calculation/GetPODocType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetPoDocTypePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetPoDocTypePost$Response(params?: ApiRequestCalculationGetPoDocTypePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestCalculationGetPoDocTypePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetPoDocTypePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetPoDocTypePost(params?: ApiRequestCalculationGetPoDocTypePost$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestCalculationGetPoDocTypePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost()` */
  static readonly ApiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPostPath = '/api/request/calculation/GetPODocTypeBySynergyTransactionDetailItemsAndVendors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost$Response(params?: ApiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<SynergyTransactionDetailItem>>> {
    return apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost(params?: ApiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost$Params, context?: HttpContext): Observable<Array<SynergyTransactionDetailItem>> {
    return this.apiRequestCalculationGetPoDocTypeBySynergyTransactionDetailItemsAndVendorsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<SynergyTransactionDetailItem>>): Array<SynergyTransactionDetailItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost()` */
  static readonly ApiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPostPath = '/api/request/calculation/GetPODocTypeByDailyInfoRequestItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost$Response(params?: ApiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<DailyInfoRequestItem>>> {
    return apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost(params?: ApiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost$Params, context?: HttpContext): Observable<Array<DailyInfoRequestItem>> {
    return this.apiRequestCalculationGetPoDocTypeByDailyInfoRequestItemsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<DailyInfoRequestItem>>): Array<DailyInfoRequestItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetOverseaCodePost()` */
  static readonly ApiRequestCalculationGetOverseaCodePostPath = '/api/request/calculation/GetOverseaCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetOverseaCodePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetOverseaCodePost$Response(params?: ApiRequestCalculationGetOverseaCodePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestCalculationGetOverseaCodePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetOverseaCodePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetOverseaCodePost(params?: ApiRequestCalculationGetOverseaCodePost$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestCalculationGetOverseaCodePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRequestCalculationGetWithHoldingTaxPost()` */
  static readonly ApiRequestCalculationGetWithHoldingTaxPostPath = '/api/request/calculation/GetWithHoldingTax';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestCalculationGetWithHoldingTaxPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetWithHoldingTaxPost$Response(params?: ApiRequestCalculationGetWithHoldingTaxPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestCalculationGetWithHoldingTaxPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestCalculationGetWithHoldingTaxPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestCalculationGetWithHoldingTaxPost(params?: ApiRequestCalculationGetWithHoldingTaxPost$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestCalculationGetWithHoldingTaxPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

}
