/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestContractAmendmentGetGet } from '../fn/contract-amendment/api-request-contract-amendment-get-get';
import { ApiRequestContractAmendmentGetGet$Params } from '../fn/contract-amendment/api-request-contract-amendment-get-get';
import { apiRequestContractAmendmentGetIdGet } from '../fn/contract-amendment/api-request-contract-amendment-get-id-get';
import { ApiRequestContractAmendmentGetIdGet$Params } from '../fn/contract-amendment/api-request-contract-amendment-get-id-get';
import { apiRequestContractAmendmentInitialGet } from '../fn/contract-amendment/api-request-contract-amendment-initial-get';
import { ApiRequestContractAmendmentInitialGet$Params } from '../fn/contract-amendment/api-request-contract-amendment-initial-get';
import { apiRequestContractAmendmentMasterAmendmentIssuesGet } from '../fn/contract-amendment/api-request-contract-amendment-master-amendment-issues-get';
import { ApiRequestContractAmendmentMasterAmendmentIssuesGet$Params } from '../fn/contract-amendment/api-request-contract-amendment-master-amendment-issues-get';
import { apiRequestContractAmendmentSavePost } from '../fn/contract-amendment/api-request-contract-amendment-save-post';
import { ApiRequestContractAmendmentSavePost$Params } from '../fn/contract-amendment/api-request-contract-amendment-save-post';
import { apiRequestContractAmendmentTakeActionPost } from '../fn/contract-amendment/api-request-contract-amendment-take-action-post';
import { ApiRequestContractAmendmentTakeActionPost$Params } from '../fn/contract-amendment/api-request-contract-amendment-take-action-post';
import { ContractAmendmentRequestItem } from '../models/contract-amendment-request-item';
import { MasterSimpleValueItem } from '../models/master-simple-value-item';

@Injectable({ providedIn: 'root' })
export class ContractAmendmentAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestContractAmendmentGetGet()` */
  static readonly ApiRequestContractAmendmentGetGetPath = '/api/request/contract-amendment/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractAmendmentGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentGetGet$Response(params?: ApiRequestContractAmendmentGetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractAmendmentRequestItem>> {
    return apiRequestContractAmendmentGetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractAmendmentGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentGetGet(params?: ApiRequestContractAmendmentGetGet$Params, context?: HttpContext): Observable<ContractAmendmentRequestItem> {
    return this.apiRequestContractAmendmentGetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractAmendmentRequestItem>): ContractAmendmentRequestItem => r.body)
    );
  }

  /** Path part for operation `apiRequestContractAmendmentGetIdGet()` */
  static readonly ApiRequestContractAmendmentGetIdGetPath = '/api/request/contract-amendment/GetId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractAmendmentGetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentGetIdGet$Response(params?: ApiRequestContractAmendmentGetIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestContractAmendmentGetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractAmendmentGetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentGetIdGet(params?: ApiRequestContractAmendmentGetIdGet$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestContractAmendmentGetIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRequestContractAmendmentInitialGet()` */
  static readonly ApiRequestContractAmendmentInitialGetPath = '/api/request/contract-amendment/Initial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractAmendmentInitialGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentInitialGet$Response(params?: ApiRequestContractAmendmentInitialGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractAmendmentRequestItem>> {
    return apiRequestContractAmendmentInitialGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractAmendmentInitialGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentInitialGet(params?: ApiRequestContractAmendmentInitialGet$Params, context?: HttpContext): Observable<ContractAmendmentRequestItem> {
    return this.apiRequestContractAmendmentInitialGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractAmendmentRequestItem>): ContractAmendmentRequestItem => r.body)
    );
  }

  /** Path part for operation `apiRequestContractAmendmentSavePost()` */
  static readonly ApiRequestContractAmendmentSavePostPath = '/api/request/contract-amendment/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractAmendmentSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractAmendmentSavePost$Response(params?: ApiRequestContractAmendmentSavePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractAmendmentRequestItem>> {
    return apiRequestContractAmendmentSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractAmendmentSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractAmendmentSavePost(params?: ApiRequestContractAmendmentSavePost$Params, context?: HttpContext): Observable<ContractAmendmentRequestItem> {
    return this.apiRequestContractAmendmentSavePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractAmendmentRequestItem>): ContractAmendmentRequestItem => r.body)
    );
  }

  /** Path part for operation `apiRequestContractAmendmentTakeActionPost()` */
  static readonly ApiRequestContractAmendmentTakeActionPostPath = '/api/request/contract-amendment/TakeAction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractAmendmentTakeActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractAmendmentTakeActionPost$Response(params?: ApiRequestContractAmendmentTakeActionPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractAmendmentRequestItem>> {
    return apiRequestContractAmendmentTakeActionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractAmendmentTakeActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractAmendmentTakeActionPost(params?: ApiRequestContractAmendmentTakeActionPost$Params, context?: HttpContext): Observable<ContractAmendmentRequestItem> {
    return this.apiRequestContractAmendmentTakeActionPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractAmendmentRequestItem>): ContractAmendmentRequestItem => r.body)
    );
  }

  /** Path part for operation `apiRequestContractAmendmentMasterAmendmentIssuesGet()` */
  static readonly ApiRequestContractAmendmentMasterAmendmentIssuesGetPath = '/api/request/contract-amendment/MasterAmendmentIssues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractAmendmentMasterAmendmentIssuesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentMasterAmendmentIssuesGet$Response(params?: ApiRequestContractAmendmentMasterAmendmentIssuesGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiRequestContractAmendmentMasterAmendmentIssuesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractAmendmentMasterAmendmentIssuesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractAmendmentMasterAmendmentIssuesGet(params?: ApiRequestContractAmendmentMasterAmendmentIssuesGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiRequestContractAmendmentMasterAmendmentIssuesGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

}
