/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { BuyerWorksheetDefaultValue } from '../../models/buyer-worksheet-default-value';
import { VendorCommercialBidEvaluationItem } from '../../models/vendor-commercial-bid-evaluation-item';

export interface ApiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: BuyerWorksheetDefaultValue
}

export function apiRequestBuyerWorksheetSetDefaultValueByIncotermPost(http: HttpClient, rootUrl: string, params?: ApiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<VendorCommercialBidEvaluationItem>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestBuyerWorksheetSetDefaultValueByIncotermPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<VendorCommercialBidEvaluationItem>;
    })
  );
}

apiRequestBuyerWorksheetSetDefaultValueByIncotermPost.PATH = '/api/request/buyer-worksheet/SetDefaultValueByIncoterm';
