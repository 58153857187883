/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRfqTaskInfoGdcDynamicSyncGet } from '../fn/rfq-task-info/api-rfq-task-info-gdc-dynamic-sync-get';
import { ApiRfqTaskInfoGdcDynamicSyncGet$Params } from '../fn/rfq-task-info/api-rfq-task-info-gdc-dynamic-sync-get';
import { apiRfqTaskInfoGetRfqTaskLiveLogsGet } from '../fn/rfq-task-info/api-rfq-task-info-get-rfq-task-live-logs-get';
import { ApiRfqTaskInfoGetRfqTaskLiveLogsGet$Params } from '../fn/rfq-task-info/api-rfq-task-info-get-rfq-task-live-logs-get';
import { apiRfqTaskInfoGetRfqTaskLogsPost } from '../fn/rfq-task-info/api-rfq-task-info-get-rfq-task-logs-post';
import { ApiRfqTaskInfoGetRfqTaskLogsPost$Params } from '../fn/rfq-task-info/api-rfq-task-info-get-rfq-task-logs-post';
import { apiRfqTaskInfoGetRfqTaskSchedulerGet } from '../fn/rfq-task-info/api-rfq-task-info-get-rfq-task-scheduler-get';
import { ApiRfqTaskInfoGetRfqTaskSchedulerGet$Params } from '../fn/rfq-task-info/api-rfq-task-info-get-rfq-task-scheduler-get';
import { apiRfqTaskInfoGetTaskInfosGet } from '../fn/rfq-task-info/api-rfq-task-info-get-task-infos-get';
import { ApiRfqTaskInfoGetTaskInfosGet$Params } from '../fn/rfq-task-info/api-rfq-task-info-get-task-infos-get';
import { apiRfqTaskInfoMdmgdcSyncGet } from '../fn/rfq-task-info/api-rfq-task-info-mdmgdc-sync-get';
import { ApiRfqTaskInfoMdmgdcSyncGet$Params } from '../fn/rfq-task-info/api-rfq-task-info-mdmgdc-sync-get';
import { apiRfqTaskInfoSetCustomFlagPost } from '../fn/rfq-task-info/api-rfq-task-info-set-custom-flag-post';
import { ApiRfqTaskInfoSetCustomFlagPost$Params } from '../fn/rfq-task-info/api-rfq-task-info-set-custom-flag-post';
import { RfqTaskInfo } from '../models/rfq-task-info';
import { RfqTaskLog } from '../models/rfq-task-log';
import { RfqTaskScheduler } from '../models/rfq-task-scheduler';

@Injectable({ providedIn: 'root' })
export class RfqTaskInfoAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRfqTaskInfoGetTaskInfosGet()` */
  static readonly ApiRfqTaskInfoGetTaskInfosGetPath = '/api/rfqTaskInfo/GetTaskInfos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoGetTaskInfosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGetTaskInfosGet$Response(params?: ApiRfqTaskInfoGetTaskInfosGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTaskInfo>>> {
    return apiRfqTaskInfoGetTaskInfosGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoGetTaskInfosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGetTaskInfosGet(params?: ApiRfqTaskInfoGetTaskInfosGet$Params, context?: HttpContext): Observable<Array<RfqTaskInfo>> {
    return this.apiRfqTaskInfoGetTaskInfosGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTaskInfo>>): Array<RfqTaskInfo> => r.body)
    );
  }

  /** Path part for operation `apiRfqTaskInfoSetCustomFlagPost()` */
  static readonly ApiRfqTaskInfoSetCustomFlagPostPath = '/api/rfqTaskInfo/SetCustomFlag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoSetCustomFlagPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoSetCustomFlagPost$Response(params?: ApiRfqTaskInfoSetCustomFlagPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRfqTaskInfoSetCustomFlagPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoSetCustomFlagPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoSetCustomFlagPost(params?: ApiRfqTaskInfoSetCustomFlagPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRfqTaskInfoSetCustomFlagPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRfqTaskInfoGetRfqTaskLogsPost()` */
  static readonly ApiRfqTaskInfoGetRfqTaskLogsPostPath = '/api/rfqTaskInfo/GetRfqTaskLogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoGetRfqTaskLogsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRfqTaskInfoGetRfqTaskLogsPost$Response(params?: ApiRfqTaskInfoGetRfqTaskLogsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTaskLog>>> {
    return apiRfqTaskInfoGetRfqTaskLogsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoGetRfqTaskLogsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRfqTaskInfoGetRfqTaskLogsPost(params?: ApiRfqTaskInfoGetRfqTaskLogsPost$Params, context?: HttpContext): Observable<Array<RfqTaskLog>> {
    return this.apiRfqTaskInfoGetRfqTaskLogsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTaskLog>>): Array<RfqTaskLog> => r.body)
    );
  }

  /** Path part for operation `apiRfqTaskInfoGetRfqTaskLiveLogsGet()` */
  static readonly ApiRfqTaskInfoGetRfqTaskLiveLogsGetPath = '/api/rfqTaskInfo/GetRfqTaskLiveLogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoGetRfqTaskLiveLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGetRfqTaskLiveLogsGet$Response(params?: ApiRfqTaskInfoGetRfqTaskLiveLogsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTaskLog>>> {
    return apiRfqTaskInfoGetRfqTaskLiveLogsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoGetRfqTaskLiveLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGetRfqTaskLiveLogsGet(params?: ApiRfqTaskInfoGetRfqTaskLiveLogsGet$Params, context?: HttpContext): Observable<Array<RfqTaskLog>> {
    return this.apiRfqTaskInfoGetRfqTaskLiveLogsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTaskLog>>): Array<RfqTaskLog> => r.body)
    );
  }

  /** Path part for operation `apiRfqTaskInfoGetRfqTaskSchedulerGet()` */
  static readonly ApiRfqTaskInfoGetRfqTaskSchedulerGetPath = '/api/rfqTaskInfo/GetRfqTaskScheduler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoGetRfqTaskSchedulerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGetRfqTaskSchedulerGet$Response(params?: ApiRfqTaskInfoGetRfqTaskSchedulerGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTaskScheduler>>> {
    return apiRfqTaskInfoGetRfqTaskSchedulerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoGetRfqTaskSchedulerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGetRfqTaskSchedulerGet(params?: ApiRfqTaskInfoGetRfqTaskSchedulerGet$Params, context?: HttpContext): Observable<Array<RfqTaskScheduler>> {
    return this.apiRfqTaskInfoGetRfqTaskSchedulerGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTaskScheduler>>): Array<RfqTaskScheduler> => r.body)
    );
  }

  /** Path part for operation `apiRfqTaskInfoMdmgdcSyncGet()` */
  static readonly ApiRfqTaskInfoMdmgdcSyncGetPath = '/api/rfqTaskInfo/MDMGDCSync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoMdmgdcSyncGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoMdmgdcSyncGet$Response(params?: ApiRfqTaskInfoMdmgdcSyncGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRfqTaskInfoMdmgdcSyncGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoMdmgdcSyncGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoMdmgdcSyncGet(params?: ApiRfqTaskInfoMdmgdcSyncGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRfqTaskInfoMdmgdcSyncGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRfqTaskInfoGdcDynamicSyncGet()` */
  static readonly ApiRfqTaskInfoGdcDynamicSyncGetPath = '/api/rfqTaskInfo/GDCDynamicSync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRfqTaskInfoGdcDynamicSyncGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGdcDynamicSyncGet$Response(params?: ApiRfqTaskInfoGdcDynamicSyncGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRfqTaskInfoGdcDynamicSyncGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRfqTaskInfoGdcDynamicSyncGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRfqTaskInfoGdcDynamicSyncGet(params?: ApiRfqTaskInfoGdcDynamicSyncGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRfqTaskInfoGdcDynamicSyncGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
