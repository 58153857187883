/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataSourcingTeamActiveForPrGet } from '../fn/master-sourcing-team/api-master-data-sourcing-team-active-for-pr-get';
import { ApiMasterDataSourcingTeamActiveForPrGet$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-active-for-pr-get';
import { apiMasterDataSourcingTeamActiveGet } from '../fn/master-sourcing-team/api-master-data-sourcing-team-active-get';
import { ApiMasterDataSourcingTeamActiveGet$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-active-get';
import { apiMasterDataSourcingTeamAddPost } from '../fn/master-sourcing-team/api-master-data-sourcing-team-add-post';
import { ApiMasterDataSourcingTeamAddPost$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-add-post';
import { apiMasterDataSourcingTeamAllGet } from '../fn/master-sourcing-team/api-master-data-sourcing-team-all-get';
import { ApiMasterDataSourcingTeamAllGet$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-all-get';
import { apiMasterDataSourcingTeamDeleteGet } from '../fn/master-sourcing-team/api-master-data-sourcing-team-delete-get';
import { ApiMasterDataSourcingTeamDeleteGet$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-delete-get';
import { apiMasterDataSourcingTeamEditPost } from '../fn/master-sourcing-team/api-master-data-sourcing-team-edit-post';
import { ApiMasterDataSourcingTeamEditPost$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-edit-post';
import { apiMasterDataSourcingTeamExportGet } from '../fn/master-sourcing-team/api-master-data-sourcing-team-export-get';
import { ApiMasterDataSourcingTeamExportGet$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-export-get';
import { apiMasterDataSourcingTeamImportPost } from '../fn/master-sourcing-team/api-master-data-sourcing-team-import-post';
import { ApiMasterDataSourcingTeamImportPost$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-import-post';
import { apiMasterDataSourcingTeamMaintainGet } from '../fn/master-sourcing-team/api-master-data-sourcing-team-maintain-get';
import { ApiMasterDataSourcingTeamMaintainGet$Params } from '../fn/master-sourcing-team/api-master-data-sourcing-team-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterSourcingTeamItem } from '../models/master-sourcing-team-item';

@Injectable({ providedIn: 'root' })
export class MasterSourcingTeamAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataSourcingTeamActiveGet()` */
  static readonly ApiMasterDataSourcingTeamActiveGetPath = '/api/MasterData/SourcingTeam/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamActiveGet$Response(params?: ApiMasterDataSourcingTeamActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>> {
    return apiMasterDataSourcingTeamActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamActiveGet(params?: ApiMasterDataSourcingTeamActiveGet$Params, context?: HttpContext): Observable<Array<MasterSourcingTeamItem>> {
    return this.apiMasterDataSourcingTeamActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>): Array<MasterSourcingTeamItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamActiveForPrGet()` */
  static readonly ApiMasterDataSourcingTeamActiveForPrGetPath = '/api/MasterData/SourcingTeam/ActiveForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamActiveForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamActiveForPrGet$Response(params?: ApiMasterDataSourcingTeamActiveForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>> {
    return apiMasterDataSourcingTeamActiveForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamActiveForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamActiveForPrGet(params?: ApiMasterDataSourcingTeamActiveForPrGet$Params, context?: HttpContext): Observable<Array<MasterSourcingTeamItem>> {
    return this.apiMasterDataSourcingTeamActiveForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>): Array<MasterSourcingTeamItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamMaintainGet()` */
  static readonly ApiMasterDataSourcingTeamMaintainGetPath = '/api/MasterData/SourcingTeam/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamMaintainGet$Response(params?: ApiMasterDataSourcingTeamMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>> {
    return apiMasterDataSourcingTeamMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamMaintainGet(params?: ApiMasterDataSourcingTeamMaintainGet$Params, context?: HttpContext): Observable<Array<MasterSourcingTeamItem>> {
    return this.apiMasterDataSourcingTeamMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>): Array<MasterSourcingTeamItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamAllGet()` */
  static readonly ApiMasterDataSourcingTeamAllGetPath = '/api/MasterData/SourcingTeam/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamAllGet$Response(params?: ApiMasterDataSourcingTeamAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>> {
    return apiMasterDataSourcingTeamAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamAllGet(params?: ApiMasterDataSourcingTeamAllGet$Params, context?: HttpContext): Observable<Array<MasterSourcingTeamItem>> {
    return this.apiMasterDataSourcingTeamAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSourcingTeamItem>>): Array<MasterSourcingTeamItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamAddPost()` */
  static readonly ApiMasterDataSourcingTeamAddPostPath = '/api/MasterData/SourcingTeam/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSourcingTeamAddPost$Response(params?: ApiMasterDataSourcingTeamAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSourcingTeamAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSourcingTeamAddPost(params?: ApiMasterDataSourcingTeamAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSourcingTeamAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamEditPost()` */
  static readonly ApiMasterDataSourcingTeamEditPostPath = '/api/MasterData/SourcingTeam/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSourcingTeamEditPost$Response(params?: ApiMasterDataSourcingTeamEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSourcingTeamEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataSourcingTeamEditPost(params?: ApiMasterDataSourcingTeamEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSourcingTeamEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamDeleteGet()` */
  static readonly ApiMasterDataSourcingTeamDeleteGetPath = '/api/MasterData/SourcingTeam/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamDeleteGet$Response(params?: ApiMasterDataSourcingTeamDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataSourcingTeamDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamDeleteGet(params?: ApiMasterDataSourcingTeamDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataSourcingTeamDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamImportPost()` */
  static readonly ApiMasterDataSourcingTeamImportPostPath = '/api/MasterData/SourcingTeam/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamImportPost$Response(params?: ApiMasterDataSourcingTeamImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataSourcingTeamImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamImportPost(params?: ApiMasterDataSourcingTeamImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataSourcingTeamImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataSourcingTeamExportGet()` */
  static readonly ApiMasterDataSourcingTeamExportGetPath = '/api/MasterData/SourcingTeam/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataSourcingTeamExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamExportGet$Response(params?: ApiMasterDataSourcingTeamExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataSourcingTeamExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataSourcingTeamExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataSourcingTeamExportGet(params?: ApiMasterDataSourcingTeamExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataSourcingTeamExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
