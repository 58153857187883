/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet } from '../fn/claim-complaint-interface/api-interface-claim-complaint-per-group-member-by-pur-group-name-get';
import { ApiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet$Params } from '../fn/claim-complaint-interface/api-interface-claim-complaint-per-group-member-by-pur-group-name-get';
import { MasterPurGroupMemberItem } from '../models/master-pur-group-member-item';

@Injectable({ providedIn: 'root' })
export class ClaimComplaintInterfaceAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet()` */
  static readonly ApiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGetPath = '/api/interface/claimComplaint/PerGroupMemberByPurGroupName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet$Response(params?: ApiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>> {
    return apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet(params?: ApiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupMemberItem>> {
    return this.apiInterfaceClaimComplaintPerGroupMemberByPurGroupNameGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>): Array<MasterPurGroupMemberItem> => r.body)
    );
  }

}
