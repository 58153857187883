/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiRequestAuctionWorksheetRevokeRejectedInvitationGet$Params {
  auctionId?: string;
  vendorCode?: string;
  remark?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestAuctionWorksheetRevokeRejectedInvitationGet(http: HttpClient, rootUrl: string, params?: ApiRequestAuctionWorksheetRevokeRejectedInvitationGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestAuctionWorksheetRevokeRejectedInvitationGet.PATH, 'get');
  if (params) {
    rb.query('auctionId', params.auctionId, {});
    rb.query('vendorCode', params.vendorCode, {});
    rb.query('remark', params.remark, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AzureApiStrictHttpResponse<void>;
    })
  );
}

apiRequestAuctionWorksheetRevokeRejectedInvitationGet.PATH = '/api/request/auction-worksheet/RevokeRejectedInvitation';
