/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataFreightModeActivePost } from '../fn/master-freight-mode/api-master-data-freight-mode-active-post';
import { ApiMasterDataFreightModeActivePost$Params } from '../fn/master-freight-mode/api-master-data-freight-mode-active-post';
import { MasterFreightMode } from '../models/master-freight-mode';

@Injectable({ providedIn: 'root' })
export class MasterFreightModeAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataFreightModeActivePost()` */
  static readonly ApiMasterDataFreightModeActivePostPath = '/api/MasterData/FreightMode/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFreightModeActivePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFreightModeActivePost$Response(params?: ApiMasterDataFreightModeActivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterFreightMode>>> {
    return apiMasterDataFreightModeActivePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFreightModeActivePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFreightModeActivePost(params?: ApiMasterDataFreightModeActivePost$Params, context?: HttpContext): Observable<Array<MasterFreightMode>> {
    return this.apiMasterDataFreightModeActivePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterFreightMode>>): Array<MasterFreightMode> => r.body)
    );
  }

}
