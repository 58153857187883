/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { EmailNotificationLog } from '../../models/email-notification-log';
import { EmailNotificationLogCriteria } from '../../models/email-notification-log-criteria';

export interface ApiEmailnotificationlogSearchPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: EmailNotificationLogCriteria
}

export function apiEmailnotificationlogSearchPost(http: HttpClient, rootUrl: string, params?: ApiEmailnotificationlogSearchPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<EmailNotificationLog>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiEmailnotificationlogSearchPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<EmailNotificationLog>>;
    })
  );
}

apiEmailnotificationlogSearchPost.PATH = '/api/emailnotificationlog/Search';
