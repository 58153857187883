/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiJobJob07GetCognitiveStockResultGet$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiJobJob07GetCognitiveStockResultGet(http: HttpClient, rootUrl: string, params?: ApiJobJob07GetCognitiveStockResultGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiJobJob07GetCognitiveStockResultGet.PATH, 'get');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AzureApiStrictHttpResponse<void>;
    })
  );
}

apiJobJob07GetCognitiveStockResultGet.PATH = '/api/Job/job07_GetCognitiveStockResult';
