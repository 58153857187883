/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiConfAllConnPost } from '../fn/conf/api-conf-all-conn-post';
import { ApiConfAllConnPost$Params } from '../fn/conf/api-conf-all-conn-post';
import { apiConfAllGet } from '../fn/conf/api-conf-all-get';
import { ApiConfAllGet$Params } from '../fn/conf/api-conf-all-get';
import { apiConfEditPost } from '../fn/conf/api-conf-edit-post';
import { ApiConfEditPost$Params } from '../fn/conf/api-conf-edit-post';
import { apiConfFetchGet } from '../fn/conf/api-conf-fetch-get';
import { ApiConfFetchGet$Params } from '../fn/conf/api-conf-fetch-get';
import { apiConfFetchWithKeyGet } from '../fn/conf/api-conf-fetch-with-key-get';
import { ApiConfFetchWithKeyGet$Params } from '../fn/conf/api-conf-fetch-with-key-get';
import { apiConfGet } from '../fn/conf/api-conf-get';
import { ApiConfGet$Params } from '../fn/conf/api-conf-get';
import { apiConfWorkingGroupAllGet } from '../fn/conf/api-conf-working-group-all-get';
import { ApiConfWorkingGroupAllGet$Params } from '../fn/conf/api-conf-working-group-all-get';
import { apiConfWorkingGroupEditPost } from '../fn/conf/api-conf-working-group-edit-post';
import { ApiConfWorkingGroupEditPost$Params } from '../fn/conf/api-conf-working-group-edit-post';
import { CliConfInResp } from '../models/cli-conf-in-resp';
import { Config } from '../models/config';
import { ConfigWorkingGroup } from '../models/config-working-group';

@Injectable({ providedIn: 'root' })
export class ConfAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiConfGet()` */
  static readonly ApiConfGetPath = '/api/conf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfGet$Response(params?: ApiConfGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<CliConfInResp>> {
    return apiConfGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfGet(params?: ApiConfGet$Params, context?: HttpContext): Observable<CliConfInResp> {
    return this.apiConfGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<CliConfInResp>): CliConfInResp => r.body)
    );
  }

  /** Path part for operation `apiConfFetchGet()` */
  static readonly ApiConfFetchGetPath = '/api/conf/fetch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfFetchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfFetchGet$Response(params?: ApiConfFetchGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiConfFetchGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfFetchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfFetchGet(params?: ApiConfFetchGet$Params, context?: HttpContext): Observable<string> {
    return this.apiConfFetchGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiConfFetchWithKeyGet()` */
  static readonly ApiConfFetchWithKeyGetPath = '/api/conf/fetch-with-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfFetchWithKeyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfFetchWithKeyGet$Response(params?: ApiConfFetchWithKeyGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiConfFetchWithKeyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfFetchWithKeyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfFetchWithKeyGet(params?: ApiConfFetchWithKeyGet$Params, context?: HttpContext): Observable<string> {
    return this.apiConfFetchWithKeyGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiConfAllGet()` */
  static readonly ApiConfAllGetPath = '/api/conf/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfAllGet$Response(params?: ApiConfAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<Config>>> {
    return apiConfAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfAllGet(params?: ApiConfAllGet$Params, context?: HttpContext): Observable<Array<Config>> {
    return this.apiConfAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<Config>>): Array<Config> => r.body)
    );
  }

  /** Path part for operation `apiConfWorkingGroupAllGet()` */
  static readonly ApiConfWorkingGroupAllGetPath = '/api/conf/WorkingGroup/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfWorkingGroupAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfWorkingGroupAllGet$Response(params?: ApiConfWorkingGroupAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ConfigWorkingGroup>>> {
    return apiConfWorkingGroupAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfWorkingGroupAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfWorkingGroupAllGet(params?: ApiConfWorkingGroupAllGet$Params, context?: HttpContext): Observable<Array<ConfigWorkingGroup>> {
    return this.apiConfWorkingGroupAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ConfigWorkingGroup>>): Array<ConfigWorkingGroup> => r.body)
    );
  }

  /** Path part for operation `apiConfEditPost()` */
  static readonly ApiConfEditPostPath = '/api/conf/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiConfEditPost$Response(params?: ApiConfEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiConfEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiConfEditPost(params?: ApiConfEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiConfEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiConfWorkingGroupEditPost()` */
  static readonly ApiConfWorkingGroupEditPostPath = '/api/conf/WorkingGroup/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfWorkingGroupEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiConfWorkingGroupEditPost$Response(params?: ApiConfWorkingGroupEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiConfWorkingGroupEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfWorkingGroupEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiConfWorkingGroupEditPost(params?: ApiConfWorkingGroupEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiConfWorkingGroupEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiConfAllConnPost()` */
  static readonly ApiConfAllConnPostPath = '/api/conf/AllConn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfAllConnPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfAllConnPost$Response(params?: ApiConfAllConnPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiConfAllConnPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiConfAllConnPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfAllConnPost(params?: ApiConfAllConnPost$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiConfAllConnPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
