/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet } from '../fn/work-group/api-work-group-work-group-get-by-item-source-and-purchasing-group-get';
import { ApiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet$Params } from '../fn/work-group/api-work-group-work-group-get-by-item-source-and-purchasing-group-get';
import { apiWorkGroupWorkGroupGetCurrencyGet } from '../fn/work-group/api-work-group-work-group-get-currency-get';
import { ApiWorkGroupWorkGroupGetCurrencyGet$Params } from '../fn/work-group/api-work-group-work-group-get-currency-get';
import { apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet } from '../fn/work-group/api-work-group-work-group-recheck-transaction-working-group-get';
import { ApiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet$Params } from '../fn/work-group/api-work-group-work-group-recheck-transaction-working-group-get';
import { RecheckTransactionWorkingGroupDto } from '../models/recheck-transaction-working-group-dto';

@Injectable({ providedIn: 'root' })
export class WorkGroupAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet()` */
  static readonly ApiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGetPath = '/api/WorkGroup/WorkGroup/GetByItemSourceAndPurchasingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet$Response(params?: ApiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet(params?: ApiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet$Params, context?: HttpContext): Observable<string> {
    return this.apiWorkGroupWorkGroupGetByItemSourceAndPurchasingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet()` */
  static readonly ApiWorkGroupWorkGroupRecheckTransactionWorkingGroupGetPath = '/api/WorkGroup/WorkGroup/RecheckTransactionWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet$Response(params?: ApiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RecheckTransactionWorkingGroupDto>> {
    return apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet(params?: ApiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet$Params, context?: HttpContext): Observable<RecheckTransactionWorkingGroupDto> {
    return this.apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RecheckTransactionWorkingGroupDto>): RecheckTransactionWorkingGroupDto => r.body)
    );
  }

  /** Path part for operation `apiWorkGroupWorkGroupGetCurrencyGet()` */
  static readonly ApiWorkGroupWorkGroupGetCurrencyGetPath = '/api/WorkGroup/WorkGroup/GetCurrency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkGroupWorkGroupGetCurrencyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkGroupWorkGroupGetCurrencyGet$Response(params?: ApiWorkGroupWorkGroupGetCurrencyGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiWorkGroupWorkGroupGetCurrencyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWorkGroupWorkGroupGetCurrencyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkGroupWorkGroupGetCurrencyGet(params?: ApiWorkGroupWorkGroupGetCurrencyGet$Params, context?: HttpContext): Observable<string> {
    return this.apiWorkGroupWorkGroupGetCurrencyGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

}
