/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { AuctionWorksheetDto } from '../../models/auction-worksheet-dto';
import { AuctionWorksheetInitialDataRequestDto } from '../../models/auction-worksheet-initial-data-request-dto';

export interface ApiRequestAuctionWorksheetInitialRequestPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: AuctionWorksheetInitialDataRequestDto
}

export function apiRequestAuctionWorksheetInitialRequestPost(http: HttpClient, rootUrl: string, params?: ApiRequestAuctionWorksheetInitialRequestPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetDto>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestAuctionWorksheetInitialRequestPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<AuctionWorksheetDto>;
    })
  );
}

apiRequestAuctionWorksheetInitialRequestPost.PATH = '/api/request/auction-worksheet/InitialRequest';
