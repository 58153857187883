/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestNewRfqCancelRfqDetailGet } from '../fn/rfq-request/api-request-new-rfq-cancel-rfq-detail-get';
import { ApiRequestNewRfqCancelRfqDetailGet$Params } from '../fn/rfq-request/api-request-new-rfq-cancel-rfq-detail-get';
import { apiRequestNewRfqChangeDeadlinePost } from '../fn/rfq-request/api-request-new-rfq-change-deadline-post';
import { ApiRequestNewRfqChangeDeadlinePost$Params } from '../fn/rfq-request/api-request-new-rfq-change-deadline-post';
import { apiRequestNewRfqCompleteRfqDetailPost } from '../fn/rfq-request/api-request-new-rfq-complete-rfq-detail-post';
import { ApiRequestNewRfqCompleteRfqDetailPost$Params } from '../fn/rfq-request/api-request-new-rfq-complete-rfq-detail-post';
import { apiRequestNewRfqCreateDraftPost } from '../fn/rfq-request/api-request-new-rfq-create-draft-post';
import { ApiRequestNewRfqCreateDraftPost$Params } from '../fn/rfq-request/api-request-new-rfq-create-draft-post';
import { apiRequestNewRfqDeleteDraftPost } from '../fn/rfq-request/api-request-new-rfq-delete-draft-post';
import { ApiRequestNewRfqDeleteDraftPost$Params } from '../fn/rfq-request/api-request-new-rfq-delete-draft-post';
import { apiRequestNewRfqGetDraftPost } from '../fn/rfq-request/api-request-new-rfq-get-draft-post';
import { ApiRequestNewRfqGetDraftPost$Params } from '../fn/rfq-request/api-request-new-rfq-get-draft-post';
import { apiRequestNewRfqGetFlipToPoFlagPost } from '../fn/rfq-request/api-request-new-rfq-get-flip-to-po-flag-post';
import { ApiRequestNewRfqGetFlipToPoFlagPost$Params } from '../fn/rfq-request/api-request-new-rfq-get-flip-to-po-flag-post';
import { apiRequestNewRfqGetPreviewForRfqDetailPost } from '../fn/rfq-request/api-request-new-rfq-get-preview-for-rfq-detail-post';
import { ApiRequestNewRfqGetPreviewForRfqDetailPost$Params } from '../fn/rfq-request/api-request-new-rfq-get-preview-for-rfq-detail-post';
import { apiRequestNewRfqGetPreviewForRfqTrasactionPost } from '../fn/rfq-request/api-request-new-rfq-get-preview-for-rfq-trasaction-post';
import { ApiRequestNewRfqGetPreviewForRfqTrasactionPost$Params } from '../fn/rfq-request/api-request-new-rfq-get-preview-for-rfq-trasaction-post';
import { apiRequestNewRfqItemGet } from '../fn/rfq-request/api-request-new-rfq-item-get';
import { ApiRequestNewRfqItemGet$Params } from '../fn/rfq-request/api-request-new-rfq-item-get';
import { apiRequestNewRfqItemsGet } from '../fn/rfq-request/api-request-new-rfq-items-get';
import { ApiRequestNewRfqItemsGet$Params } from '../fn/rfq-request/api-request-new-rfq-items-get';
import { apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost } from '../fn/rfq-request/api-request-new-rfq-prepare-and-create-buyer-worksheet-post';
import { ApiRequestNewRfqPrepareAndCreateBuyerWorksheetPost$Params } from '../fn/rfq-request/api-request-new-rfq-prepare-and-create-buyer-worksheet-post';
import { apiRequestNewRfqPrepareFlipToPoPost } from '../fn/rfq-request/api-request-new-rfq-prepare-flip-to-po-post';
import { ApiRequestNewRfqPrepareFlipToPoPost$Params } from '../fn/rfq-request/api-request-new-rfq-prepare-flip-to-po-post';
import { apiRequestNewRfqReturnRfqDetailGet } from '../fn/rfq-request/api-request-new-rfq-return-rfq-detail-get';
import { ApiRequestNewRfqReturnRfqDetailGet$Params } from '../fn/rfq-request/api-request-new-rfq-return-rfq-detail-get';
import { apiRequestNewRfqSaveRfqDetailPost } from '../fn/rfq-request/api-request-new-rfq-save-rfq-detail-post';
import { ApiRequestNewRfqSaveRfqDetailPost$Params } from '../fn/rfq-request/api-request-new-rfq-save-rfq-detail-post';
import { apiRequestNewRfqSendEmailForRfqDetailPost } from '../fn/rfq-request/api-request-new-rfq-send-email-for-rfq-detail-post';
import { ApiRequestNewRfqSendEmailForRfqDetailPost$Params } from '../fn/rfq-request/api-request-new-rfq-send-email-for-rfq-detail-post';
import { apiRequestNewRfqSubmitManualRfqFromWorkStationPost } from '../fn/rfq-request/api-request-new-rfq-submit-manual-rfq-from-work-station-post';
import { ApiRequestNewRfqSubmitManualRfqFromWorkStationPost$Params } from '../fn/rfq-request/api-request-new-rfq-submit-manual-rfq-from-work-station-post';
import { apiRequestNewRfqSubmitNewRfqPost } from '../fn/rfq-request/api-request-new-rfq-submit-new-rfq-post';
import { ApiRequestNewRfqSubmitNewRfqPost$Params } from '../fn/rfq-request/api-request-new-rfq-submit-new-rfq-post';
import { BuyerWorksheetItem } from '../models/buyer-worksheet-item';
import { DailyInfoSummaryFlipPoItem } from '../models/daily-info-summary-flip-po-item';
import { RfqSetupEmailItem } from '../models/rfq-setup-email-item';
import { RfqTransactionItem } from '../models/rfq-transaction-item';

@Injectable({ providedIn: 'root' })
export class RfqRequestAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestNewRfqItemGet()` */
  static readonly ApiRequestNewRfqItemGetPath = '/api/request/new-rfq/Item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqItemGet$Response(params?: ApiRequestNewRfqItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqTransactionItem>> {
    return apiRequestNewRfqItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqItemGet(params?: ApiRequestNewRfqItemGet$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiRequestNewRfqItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqItemsGet()` */
  static readonly ApiRequestNewRfqItemsGetPath = '/api/request/new-rfq/Items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqItemsGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqItemsGet$Response(params?: ApiRequestNewRfqItemsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTransactionItem>>> {
    return apiRequestNewRfqItemsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqItemsGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqItemsGet(params?: ApiRequestNewRfqItemsGet$Params, context?: HttpContext): Observable<Array<RfqTransactionItem>> {
    return this.apiRequestNewRfqItemsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTransactionItem>>): Array<RfqTransactionItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqGetPreviewForRfqTrasactionPost()` */
  static readonly ApiRequestNewRfqGetPreviewForRfqTrasactionPostPath = '/api/request/new-rfq/GetPreviewForRfqTrasaction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqGetPreviewForRfqTrasactionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqGetPreviewForRfqTrasactionPost$Response(params?: ApiRequestNewRfqGetPreviewForRfqTrasactionPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqSetupEmailItem>> {
    return apiRequestNewRfqGetPreviewForRfqTrasactionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqGetPreviewForRfqTrasactionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqGetPreviewForRfqTrasactionPost(params?: ApiRequestNewRfqGetPreviewForRfqTrasactionPost$Params, context?: HttpContext): Observable<RfqSetupEmailItem> {
    return this.apiRequestNewRfqGetPreviewForRfqTrasactionPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqSetupEmailItem>): RfqSetupEmailItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqSubmitNewRfqPost()` */
  static readonly ApiRequestNewRfqSubmitNewRfqPostPath = '/api/request/new-rfq/SubmitNewRfq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqSubmitNewRfqPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSubmitNewRfqPost$Response(params?: ApiRequestNewRfqSubmitNewRfqPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTransactionItem>>> {
    return apiRequestNewRfqSubmitNewRfqPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqSubmitNewRfqPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSubmitNewRfqPost(params?: ApiRequestNewRfqSubmitNewRfqPost$Params, context?: HttpContext): Observable<Array<RfqTransactionItem>> {
    return this.apiRequestNewRfqSubmitNewRfqPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTransactionItem>>): Array<RfqTransactionItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqReturnRfqDetailGet()` */
  static readonly ApiRequestNewRfqReturnRfqDetailGetPath = '/api/request/new-rfq/ReturnRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqReturnRfqDetailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqReturnRfqDetailGet$Response(params?: ApiRequestNewRfqReturnRfqDetailGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestNewRfqReturnRfqDetailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqReturnRfqDetailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqReturnRfqDetailGet(params?: ApiRequestNewRfqReturnRfqDetailGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestNewRfqReturnRfqDetailGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqCancelRfqDetailGet()` */
  static readonly ApiRequestNewRfqCancelRfqDetailGetPath = '/api/request/new-rfq/CancelRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqCancelRfqDetailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqCancelRfqDetailGet$Response(params?: ApiRequestNewRfqCancelRfqDetailGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestNewRfqCancelRfqDetailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqCancelRfqDetailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqCancelRfqDetailGet(params?: ApiRequestNewRfqCancelRfqDetailGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestNewRfqCancelRfqDetailGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqSaveRfqDetailPost()` */
  static readonly ApiRequestNewRfqSaveRfqDetailPostPath = '/api/request/new-rfq/SaveRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqSaveRfqDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSaveRfqDetailPost$Response(params?: ApiRequestNewRfqSaveRfqDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqTransactionItem>> {
    return apiRequestNewRfqSaveRfqDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqSaveRfqDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSaveRfqDetailPost(params?: ApiRequestNewRfqSaveRfqDetailPost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiRequestNewRfqSaveRfqDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqCompleteRfqDetailPost()` */
  static readonly ApiRequestNewRfqCompleteRfqDetailPostPath = '/api/request/new-rfq/CompleteRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqCompleteRfqDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqCompleteRfqDetailPost$Response(params?: ApiRequestNewRfqCompleteRfqDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqTransactionItem>> {
    return apiRequestNewRfqCompleteRfqDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqCompleteRfqDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqCompleteRfqDetailPost(params?: ApiRequestNewRfqCompleteRfqDetailPost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiRequestNewRfqCompleteRfqDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqChangeDeadlinePost()` */
  static readonly ApiRequestNewRfqChangeDeadlinePostPath = '/api/request/new-rfq/ChangeDeadline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqChangeDeadlinePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqChangeDeadlinePost$Response(params?: ApiRequestNewRfqChangeDeadlinePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqTransactionItem>> {
    return apiRequestNewRfqChangeDeadlinePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqChangeDeadlinePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqChangeDeadlinePost(params?: ApiRequestNewRfqChangeDeadlinePost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiRequestNewRfqChangeDeadlinePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqGetPreviewForRfqDetailPost()` */
  static readonly ApiRequestNewRfqGetPreviewForRfqDetailPostPath = '/api/request/new-rfq/GetPreviewForRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqGetPreviewForRfqDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqGetPreviewForRfqDetailPost$Response(params?: ApiRequestNewRfqGetPreviewForRfqDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqTransactionItem>> {
    return apiRequestNewRfqGetPreviewForRfqDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqGetPreviewForRfqDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqGetPreviewForRfqDetailPost(params?: ApiRequestNewRfqGetPreviewForRfqDetailPost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiRequestNewRfqGetPreviewForRfqDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqGetDraftPost()` */
  static readonly ApiRequestNewRfqGetDraftPostPath = '/api/request/new-rfq/GetDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqGetDraftPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqGetDraftPost$Response(params?: ApiRequestNewRfqGetDraftPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqSetupEmailItem>> {
    return apiRequestNewRfqGetDraftPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqGetDraftPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqGetDraftPost(params?: ApiRequestNewRfqGetDraftPost$Params, context?: HttpContext): Observable<RfqSetupEmailItem> {
    return this.apiRequestNewRfqGetDraftPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqSetupEmailItem>): RfqSetupEmailItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqCreateDraftPost()` */
  static readonly ApiRequestNewRfqCreateDraftPostPath = '/api/request/new-rfq/CreateDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqCreateDraftPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqCreateDraftPost$Response(params?: ApiRequestNewRfqCreateDraftPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestNewRfqCreateDraftPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqCreateDraftPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqCreateDraftPost(params?: ApiRequestNewRfqCreateDraftPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestNewRfqCreateDraftPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqDeleteDraftPost()` */
  static readonly ApiRequestNewRfqDeleteDraftPostPath = '/api/request/new-rfq/DeleteDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqDeleteDraftPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqDeleteDraftPost$Response(params?: ApiRequestNewRfqDeleteDraftPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestNewRfqDeleteDraftPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqDeleteDraftPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqDeleteDraftPost(params?: ApiRequestNewRfqDeleteDraftPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestNewRfqDeleteDraftPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqSendEmailForRfqDetailPost()` */
  static readonly ApiRequestNewRfqSendEmailForRfqDetailPostPath = '/api/request/new-rfq/SendEmailForRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqSendEmailForRfqDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSendEmailForRfqDetailPost$Response(params?: ApiRequestNewRfqSendEmailForRfqDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RfqTransactionItem>> {
    return apiRequestNewRfqSendEmailForRfqDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqSendEmailForRfqDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSendEmailForRfqDetailPost(params?: ApiRequestNewRfqSendEmailForRfqDetailPost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiRequestNewRfqSendEmailForRfqDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqSubmitManualRfqFromWorkStationPost()` */
  static readonly ApiRequestNewRfqSubmitManualRfqFromWorkStationPostPath = '/api/request/new-rfq/SubmitManualRfqFromWorkStation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqSubmitManualRfqFromWorkStationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSubmitManualRfqFromWorkStationPost$Response(params?: ApiRequestNewRfqSubmitManualRfqFromWorkStationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTransactionItem>>> {
    return apiRequestNewRfqSubmitManualRfqFromWorkStationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqSubmitManualRfqFromWorkStationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqSubmitManualRfqFromWorkStationPost(params?: ApiRequestNewRfqSubmitManualRfqFromWorkStationPost$Params, context?: HttpContext): Observable<Array<RfqTransactionItem>> {
    return this.apiRequestNewRfqSubmitManualRfqFromWorkStationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTransactionItem>>): Array<RfqTransactionItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost()` */
  static readonly ApiRequestNewRfqPrepareAndCreateBuyerWorksheetPostPath = '/api/request/new-rfq/PrepareAndCreateBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost$Response(params?: ApiRequestNewRfqPrepareAndCreateBuyerWorksheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<BuyerWorksheetItem>>> {
    return apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost(params?: ApiRequestNewRfqPrepareAndCreateBuyerWorksheetPost$Params, context?: HttpContext): Observable<Array<BuyerWorksheetItem>> {
    return this.apiRequestNewRfqPrepareAndCreateBuyerWorksheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<BuyerWorksheetItem>>): Array<BuyerWorksheetItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqGetFlipToPoFlagPost()` */
  static readonly ApiRequestNewRfqGetFlipToPoFlagPostPath = '/api/request/new-rfq/GetFlipToPOFlag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqGetFlipToPoFlagPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqGetFlipToPoFlagPost$Response(params?: ApiRequestNewRfqGetFlipToPoFlagPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<DailyInfoSummaryFlipPoItem>> {
    return apiRequestNewRfqGetFlipToPoFlagPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqGetFlipToPoFlagPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqGetFlipToPoFlagPost(params?: ApiRequestNewRfqGetFlipToPoFlagPost$Params, context?: HttpContext): Observable<DailyInfoSummaryFlipPoItem> {
    return this.apiRequestNewRfqGetFlipToPoFlagPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<DailyInfoSummaryFlipPoItem>): DailyInfoSummaryFlipPoItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqPrepareFlipToPoPost()` */
  static readonly ApiRequestNewRfqPrepareFlipToPoPostPath = '/api/request/new-rfq/PrepareFlipToPO';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqPrepareFlipToPoPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqPrepareFlipToPoPost$Response(params?: ApiRequestNewRfqPrepareFlipToPoPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<BuyerWorksheetItem>>> {
    return apiRequestNewRfqPrepareFlipToPoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqPrepareFlipToPoPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqPrepareFlipToPoPost(params?: ApiRequestNewRfqPrepareFlipToPoPost$Params, context?: HttpContext): Observable<Array<BuyerWorksheetItem>> {
    return this.apiRequestNewRfqPrepareFlipToPoPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<BuyerWorksheetItem>>): Array<BuyerWorksheetItem> => r.body)
    );
  }

}
