import { environment } from '../../environments/environment';
import { AzureApiConfiguration } from '../api-azure/azure-api-configuration';
import { CliConfInResp } from '../api-azure/models/cli-conf-in-resp';

export class GlobalConfigs {
  public static datas: CliConfInResp = {};
}

export function apiAzureApiBaseUrlGetter() {

  if ((window as any).env) {
    //console.log("apiAzureApiBaseUrlGetter", {
    //  type: "(window as any).env.API_AZURE_BASE_URL)",
    //  value: (window as any).env.API_AZURE_BASE_URL
    //});
    return (window as any).env.API_AZURE_BASE_URL;
  }

  if (environment) {
    //console.log("apiAzureApiBaseUrlGetter", {
    //  type: "environment.base_url_azure_api",
    //  value: environment.base_url_azure_api
    //});
    return environment.base_url_azure_api;
  }

  const apiConfig: AzureApiConfiguration = new AzureApiConfiguration();
  //console.log("apiAzureApiBaseUrlGetter", {
  //  type: "AzureApiConfiguration.rootUrl",
  //  value: apiConfig.rootUrl
  //});
  return apiConfig.rootUrl;
}

export function apiAuctionBaseUrlGetter() {
  if ((window as any).env) {
    return (window as any).env.AUCTION_API_BASE_URL;
  } else {
    return apiAzureApiBaseUrlGetter();
  }
}
