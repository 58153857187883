/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestPrCognitiveVendorDetailFromCognitivePost } from '../fn/pr-cognitive/api-request-pr-cognitive-vendor-detail-from-cognitive-post';
import { ApiRequestPrCognitiveVendorDetailFromCognitivePost$Params } from '../fn/pr-cognitive/api-request-pr-cognitive-vendor-detail-from-cognitive-post';
import { PrCognitiveResultItem } from '../models/pr-cognitive-result-item';

@Injectable({ providedIn: 'root' })
export class PrCognitiveAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestPrCognitiveVendorDetailFromCognitivePost()` */
  static readonly ApiRequestPrCognitiveVendorDetailFromCognitivePostPath = '/api/request/pr-cognitive/VendorDetailFromCognitive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestPrCognitiveVendorDetailFromCognitivePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestPrCognitiveVendorDetailFromCognitivePost$Response(params?: ApiRequestPrCognitiveVendorDetailFromCognitivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<PrCognitiveResultItem>>> {
    return apiRequestPrCognitiveVendorDetailFromCognitivePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestPrCognitiveVendorDetailFromCognitivePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestPrCognitiveVendorDetailFromCognitivePost(params?: ApiRequestPrCognitiveVendorDetailFromCognitivePost$Params, context?: HttpContext): Observable<Array<PrCognitiveResultItem>> {
    return this.apiRequestPrCognitiveVendorDetailFromCognitivePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<PrCognitiveResultItem>>): Array<PrCognitiveResultItem> => r.body)
    );
  }

}
