import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WorkingGroupService } from "../services/working-group.service";

@Injectable()
export class WorkingGroupInterceptor implements HttpInterceptor {

  constructor(
    private workingGroupService : WorkingGroupService, 
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    let newHeaders = request.clone({}).headers;

    const userWorkingGroup = this.workingGroupService.getUserWorkingGroup();
    if (userWorkingGroup) {
      let authHeader = newHeaders.get(this.workingGroupService.key);
      if(authHeader){
        newHeaders = newHeaders.set(this.workingGroupService.key, userWorkingGroup)
      }else{
        newHeaders = newHeaders.append(this.workingGroupService.key, userWorkingGroup);
      }
    }

    request = request.clone({ headers: newHeaders });
    return next.handle(request);
  }
}
