/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiFilePost } from '../fn/file-api/api-file-post';
import { ApiFilePost$Params } from '../fn/file-api/api-file-post';
import { apiFileTempUploadPost } from '../fn/file-api/api-file-temp-upload-post';
import { ApiFileTempUploadPost$Params } from '../fn/file-api/api-file-temp-upload-post';
import { FileUploadResp } from '../models/file-upload-resp';

@Injectable({ providedIn: 'root' })
export class FileApiAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiFilePost()` */
  static readonly ApiFilePostPath = '/api/file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilePost$Response(params?: ApiFilePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<FileUploadResp>> {
    return apiFilePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFilePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilePost(params?: ApiFilePost$Params, context?: HttpContext): Observable<FileUploadResp> {
    return this.apiFilePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<FileUploadResp>): FileUploadResp => r.body)
    );
  }

  /** Path part for operation `apiFileTempUploadPost()` */
  static readonly ApiFileTempUploadPostPath = '/api/file/temp-upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileTempUploadPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileTempUploadPost$Response(params?: ApiFileTempUploadPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<FileUploadResp>> {
    return apiFileTempUploadPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFileTempUploadPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileTempUploadPost(params?: ApiFileTempUploadPost$Params, context?: HttpContext): Observable<FileUploadResp> {
    return this.apiFileTempUploadPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<FileUploadResp>): FileUploadResp => r.body)
    );
  }

}
