/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { FileCommonModel } from '../../models/file-common-model';

export interface ApiAmazonS3DownloadObjectGet$Params {
  configName?: string;
  filePath?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiAmazonS3DownloadObjectGet(http: HttpClient, rootUrl: string, params?: ApiAmazonS3DownloadObjectGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<FileCommonModel>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiAmazonS3DownloadObjectGet.PATH, 'get');
  if (params) {
    rb.query('configName', params.configName, {});
    rb.query('filePath', params.filePath, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<FileCommonModel>;
    })
  );
}

apiAmazonS3DownloadObjectGet.PATH = '/api/AmazonS3/DownloadObject';
