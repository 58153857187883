/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { AccountResp } from '../models/account-resp';
import { AccountWithRoleResp } from '../models/account-with-role-resp';
import { apiEmployeeGetAccountInfoByDomainNameGet } from '../fn/employee/api-employee-get-account-info-by-domain-name-get';
import { ApiEmployeeGetAccountInfoByDomainNameGet$Params } from '../fn/employee/api-employee-get-account-info-by-domain-name-get';
import { apiEmployeeGetChainApproversGet } from '../fn/employee/api-employee-get-chain-approvers-get';
import { ApiEmployeeGetChainApproversGet$Params } from '../fn/employee/api-employee-get-chain-approvers-get';
import { apiEmployeeGetEmployeeByIdGet } from '../fn/employee/api-employee-get-employee-by-id-get';
import { ApiEmployeeGetEmployeeByIdGet$Params } from '../fn/employee/api-employee-get-employee-by-id-get';
import { apiEmployeeGetWorkingGroupByDomainNameGet } from '../fn/employee/api-employee-get-working-group-by-domain-name-get';
import { ApiEmployeeGetWorkingGroupByDomainNameGet$Params } from '../fn/employee/api-employee-get-working-group-by-domain-name-get';
import { apiEmployeeListPost } from '../fn/employee/api-employee-list-post';
import { ApiEmployeeListPost$Params } from '../fn/employee/api-employee-list-post';
import { apiEmployeeStartwithApprovalDistinctGet } from '../fn/employee/api-employee-startwith-approval-distinct-get';
import { ApiEmployeeStartwithApprovalDistinctGet$Params } from '../fn/employee/api-employee-startwith-approval-distinct-get';
import { apiEmployeeStartwithApprovalGet } from '../fn/employee/api-employee-startwith-approval-get';
import { ApiEmployeeStartwithApprovalGet$Params } from '../fn/employee/api-employee-startwith-approval-get';
import { apiEmployeeStartwithByWorkingGroupGet } from '../fn/employee/api-employee-startwith-by-working-group-get';
import { ApiEmployeeStartwithByWorkingGroupGet$Params } from '../fn/employee/api-employee-startwith-by-working-group-get';
import { apiEmployeeStartwithForAllGet } from '../fn/employee/api-employee-startwith-for-all-get';
import { ApiEmployeeStartwithForAllGet$Params } from '../fn/employee/api-employee-startwith-for-all-get';
import { apiEmployeeStartwithForBuyerWorksheetGet } from '../fn/employee/api-employee-startwith-for-buyer-worksheet-get';
import { ApiEmployeeStartwithForBuyerWorksheetGet$Params } from '../fn/employee/api-employee-startwith-for-buyer-worksheet-get';
import { apiEmployeeStartwithGet } from '../fn/employee/api-employee-startwith-get';
import { ApiEmployeeStartwithGet$Params } from '../fn/employee/api-employee-startwith-get';

@Injectable({ providedIn: 'root' })
export class EmployeeAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiEmployeeStartwithGet()` */
  static readonly ApiEmployeeStartwithGetPath = '/api/employee/startwith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithGet$Response(params?: ApiEmployeeStartwithGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeStartwithGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithGet(params?: ApiEmployeeStartwithGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeStartwithGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartwithForAllGet()` */
  static readonly ApiEmployeeStartwithForAllGetPath = '/api/employee/startwithForAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithForAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithForAllGet$Response(params?: ApiEmployeeStartwithForAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeStartwithForAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithForAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithForAllGet(params?: ApiEmployeeStartwithForAllGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeStartwithForAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartwithByWorkingGroupGet()` */
  static readonly ApiEmployeeStartwithByWorkingGroupGetPath = '/api/employee/startwithByWorkingGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithByWorkingGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithByWorkingGroupGet$Response(params?: ApiEmployeeStartwithByWorkingGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeStartwithByWorkingGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithByWorkingGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithByWorkingGroupGet(params?: ApiEmployeeStartwithByWorkingGroupGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeStartwithByWorkingGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartwithForBuyerWorksheetGet()` */
  static readonly ApiEmployeeStartwithForBuyerWorksheetGetPath = '/api/employee/startwithForBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithForBuyerWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithForBuyerWorksheetGet$Response(params?: ApiEmployeeStartwithForBuyerWorksheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeStartwithForBuyerWorksheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithForBuyerWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithForBuyerWorksheetGet(params?: ApiEmployeeStartwithForBuyerWorksheetGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeStartwithForBuyerWorksheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeListPost()` */
  static readonly ApiEmployeeListPostPath = '/api/employee/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmployeeListPost$Response(params?: ApiEmployeeListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmployeeListPost(params?: ApiEmployeeListPost$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartwithApprovalGet()` */
  static readonly ApiEmployeeStartwithApprovalGetPath = '/api/employee/startwithApproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithApprovalGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithApprovalGet$Response(params?: ApiEmployeeStartwithApprovalGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeStartwithApprovalGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithApprovalGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithApprovalGet(params?: ApiEmployeeStartwithApprovalGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeStartwithApprovalGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeStartwithApprovalDistinctGet()` */
  static readonly ApiEmployeeStartwithApprovalDistinctGetPath = '/api/employee/startwithApprovalDistinct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeStartwithApprovalDistinctGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithApprovalDistinctGet$Response(params?: ApiEmployeeStartwithApprovalDistinctGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeStartwithApprovalDistinctGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeStartwithApprovalDistinctGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeStartwithApprovalDistinctGet(params?: ApiEmployeeStartwithApprovalDistinctGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeStartwithApprovalDistinctGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeGetEmployeeByIdGet()` */
  static readonly ApiEmployeeGetEmployeeByIdGetPath = '/api/employee/GetEmployeeById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeGetEmployeeByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetEmployeeByIdGet$Response(params?: ApiEmployeeGetEmployeeByIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AccountResp>> {
    return apiEmployeeGetEmployeeByIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeGetEmployeeByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetEmployeeByIdGet(params?: ApiEmployeeGetEmployeeByIdGet$Params, context?: HttpContext): Observable<AccountResp> {
    return this.apiEmployeeGetEmployeeByIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AccountResp>): AccountResp => r.body)
    );
  }

  /** Path part for operation `apiEmployeeGetAccountInfoByDomainNameGet()` */
  static readonly ApiEmployeeGetAccountInfoByDomainNameGetPath = '/api/employee/GetAccountInfoByDomainName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeGetAccountInfoByDomainNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetAccountInfoByDomainNameGet$Response(params?: ApiEmployeeGetAccountInfoByDomainNameGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AccountWithRoleResp>> {
    return apiEmployeeGetAccountInfoByDomainNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeGetAccountInfoByDomainNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetAccountInfoByDomainNameGet(params?: ApiEmployeeGetAccountInfoByDomainNameGet$Params, context?: HttpContext): Observable<AccountWithRoleResp> {
    return this.apiEmployeeGetAccountInfoByDomainNameGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AccountWithRoleResp>): AccountWithRoleResp => r.body)
    );
  }

  /** Path part for operation `apiEmployeeGetChainApproversGet()` */
  static readonly ApiEmployeeGetChainApproversGetPath = '/api/employee/GetChainApprovers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeGetChainApproversGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetChainApproversGet$Response(params?: ApiEmployeeGetChainApproversGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AccountResp>>> {
    return apiEmployeeGetChainApproversGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeGetChainApproversGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetChainApproversGet(params?: ApiEmployeeGetChainApproversGet$Params, context?: HttpContext): Observable<Array<AccountResp>> {
    return this.apiEmployeeGetChainApproversGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AccountResp>>): Array<AccountResp> => r.body)
    );
  }

  /** Path part for operation `apiEmployeeGetWorkingGroupByDomainNameGet()` */
  static readonly ApiEmployeeGetWorkingGroupByDomainNameGetPath = '/api/employee/GetWorkingGroupByDomainName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeGetWorkingGroupByDomainNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetWorkingGroupByDomainNameGet$Response(params?: ApiEmployeeGetWorkingGroupByDomainNameGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiEmployeeGetWorkingGroupByDomainNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeGetWorkingGroupByDomainNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeGetWorkingGroupByDomainNameGet(params?: ApiEmployeeGetWorkingGroupByDomainNameGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiEmployeeGetWorkingGroupByDomainNameGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
