/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiDbTestDbTestGet$Params {
  connectionString?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiDbTestDbTestGet(http: HttpClient, rootUrl: string, params?: ApiDbTestDbTestGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiDbTestDbTestGet.PATH, 'get');
  if (params) {
    rb.query('connectionString', params.connectionString, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<string>;
    })
  );
}

apiDbTestDbTestGet.PATH = '/api/db-test/db-test';
