/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiAccountTestApiDeleteSwapPost } from '../fn/account-test-api/api-account-test-api-delete-swap-post';
import { ApiAccountTestApiDeleteSwapPost$Params } from '../fn/account-test-api/api-account-test-api-delete-swap-post';
import { apiAccountTestApiMeGet } from '../fn/account-test-api/api-account-test-api-me-get';
import { ApiAccountTestApiMeGet$Params } from '../fn/account-test-api/api-account-test-api-me-get';
import { apiAccountTestApiOriginalGet } from '../fn/account-test-api/api-account-test-api-original-get';
import { ApiAccountTestApiOriginalGet$Params } from '../fn/account-test-api/api-account-test-api-original-get';
import { apiAccountTestApiPostSwapPost } from '../fn/account-test-api/api-account-test-api-post-swap-post';
import { ApiAccountTestApiPostSwapPost$Params } from '../fn/account-test-api/api-account-test-api-post-swap-post';

@Injectable({ providedIn: 'root' })
export class AccountTestApiAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountTestApiMeGet()` */
  static readonly ApiAccountTestApiMeGetPath = '/api/account-test-api/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountTestApiMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiMeGet$Response(params?: ApiAccountTestApiMeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiAccountTestApiMeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountTestApiMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiMeGet(params?: ApiAccountTestApiMeGet$Params, context?: HttpContext): Observable<string> {
    return this.apiAccountTestApiMeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAccountTestApiOriginalGet()` */
  static readonly ApiAccountTestApiOriginalGetPath = '/api/account-test-api/original';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountTestApiOriginalGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiOriginalGet$Response(params?: ApiAccountTestApiOriginalGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiAccountTestApiOriginalGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountTestApiOriginalGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiOriginalGet(params?: ApiAccountTestApiOriginalGet$Params, context?: HttpContext): Observable<string> {
    return this.apiAccountTestApiOriginalGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAccountTestApiPostSwapPost()` */
  static readonly ApiAccountTestApiPostSwapPostPath = '/api/account-test-api/PostSwap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountTestApiPostSwapPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiPostSwapPost$Response(params?: ApiAccountTestApiPostSwapPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiAccountTestApiPostSwapPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountTestApiPostSwapPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiPostSwapPost(params?: ApiAccountTestApiPostSwapPost$Params, context?: HttpContext): Observable<void> {
    return this.apiAccountTestApiPostSwapPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountTestApiDeleteSwapPost()` */
  static readonly ApiAccountTestApiDeleteSwapPostPath = '/api/account-test-api/DeleteSwap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountTestApiDeleteSwapPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiDeleteSwapPost$Response(params?: ApiAccountTestApiDeleteSwapPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiAccountTestApiDeleteSwapPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountTestApiDeleteSwapPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountTestApiDeleteSwapPost(params?: ApiAccountTestApiDeleteSwapPost$Params, context?: HttpContext): Observable<void> {
    return this.apiAccountTestApiDeleteSwapPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
