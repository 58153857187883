/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataExchangeRateAllForPrPost } from '../fn/master-exchange-rate/api-master-data-exchange-rate-all-for-pr-post';
import { ApiMasterDataExchangeRateAllForPrPost$Params } from '../fn/master-exchange-rate/api-master-data-exchange-rate-all-for-pr-post';
import { apiMasterDataExchangeRateAllPost } from '../fn/master-exchange-rate/api-master-data-exchange-rate-all-post';
import { ApiMasterDataExchangeRateAllPost$Params } from '../fn/master-exchange-rate/api-master-data-exchange-rate-all-post';
import { apiMasterDataExchangeRatePost } from '../fn/master-exchange-rate/api-master-data-exchange-rate-post';
import { ApiMasterDataExchangeRatePost$Params } from '../fn/master-exchange-rate/api-master-data-exchange-rate-post';
import { ExchangeRate } from '../models/exchange-rate';

@Injectable({ providedIn: 'root' })
export class MasterExchangeRateAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataExchangeRatePost()` */
  static readonly ApiMasterDataExchangeRatePostPath = '/api/MasterData/ExchangeRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataExchangeRatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataExchangeRatePost$Response(params?: ApiMasterDataExchangeRatePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ExchangeRate>>> {
    return apiMasterDataExchangeRatePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataExchangeRatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataExchangeRatePost(params?: ApiMasterDataExchangeRatePost$Params, context?: HttpContext): Observable<Array<ExchangeRate>> {
    return this.apiMasterDataExchangeRatePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ExchangeRate>>): Array<ExchangeRate> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataExchangeRateAllPost()` */
  static readonly ApiMasterDataExchangeRateAllPostPath = '/api/MasterData/ExchangeRate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataExchangeRateAllPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataExchangeRateAllPost$Response(params?: ApiMasterDataExchangeRateAllPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ExchangeRate>>> {
    return apiMasterDataExchangeRateAllPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataExchangeRateAllPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataExchangeRateAllPost(params?: ApiMasterDataExchangeRateAllPost$Params, context?: HttpContext): Observable<Array<ExchangeRate>> {
    return this.apiMasterDataExchangeRateAllPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ExchangeRate>>): Array<ExchangeRate> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataExchangeRateAllForPrPost()` */
  static readonly ApiMasterDataExchangeRateAllForPrPostPath = '/api/MasterData/ExchangeRate/AllForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataExchangeRateAllForPrPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataExchangeRateAllForPrPost$Response(params?: ApiMasterDataExchangeRateAllForPrPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ExchangeRate>>> {
    return apiMasterDataExchangeRateAllForPrPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataExchangeRateAllForPrPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataExchangeRateAllForPrPost(params?: ApiMasterDataExchangeRateAllForPrPost$Params, context?: HttpContext): Observable<Array<ExchangeRate>> {
    return this.apiMasterDataExchangeRateAllForPrPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ExchangeRate>>): Array<ExchangeRate> => r.body)
    );
  }

}
