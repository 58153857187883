/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiHomeContentAnnouncementPost } from '../fn/home-content/api-home-content-announcement-post';
import { ApiHomeContentAnnouncementPost$Params } from '../fn/home-content/api-home-content-announcement-post';
import { apiHomeContentContactPost } from '../fn/home-content/api-home-content-contact-post';
import { ApiHomeContentContactPost$Params } from '../fn/home-content/api-home-content-contact-post';
import { apiHomeContentGet } from '../fn/home-content/api-home-content-get';
import { ApiHomeContentGet$Params } from '../fn/home-content/api-home-content-get';
import { HtmlResp } from '../models/html-resp';

@Injectable({ providedIn: 'root' })
export class HomeContentAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHomeContentGet()` */
  static readonly ApiHomeContentGetPath = '/api/homeContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeContentGet$Response(params?: ApiHomeContentGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<{
[key: string]: HtmlResp;
}>> {
    return apiHomeContentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeContentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeContentGet(params?: ApiHomeContentGet$Params, context?: HttpContext): Observable<{
[key: string]: HtmlResp;
}> {
    return this.apiHomeContentGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<{
[key: string]: HtmlResp;
}>): {
[key: string]: HtmlResp;
} => r.body)
    );
  }

  /** Path part for operation `apiHomeContentAnnouncementPost()` */
  static readonly ApiHomeContentAnnouncementPostPath = '/api/homeContent/Announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContentAnnouncementPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentAnnouncementPost$Response(params?: ApiHomeContentAnnouncementPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiHomeContentAnnouncementPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeContentAnnouncementPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentAnnouncementPost(params?: ApiHomeContentAnnouncementPost$Params, context?: HttpContext): Observable<void> {
    return this.apiHomeContentAnnouncementPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHomeContentContactPost()` */
  static readonly ApiHomeContentContactPostPath = '/api/homeContent/Contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContentContactPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentContactPost$Response(params?: ApiHomeContentContactPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiHomeContentContactPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeContentContactPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentContactPost(params?: ApiHomeContentContactPost$Params, context?: HttpContext): Observable<void> {
    return this.apiHomeContentContactPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
