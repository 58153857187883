/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiTaskListAllDailyInfoItemTypeGet } from '../fn/task-list/api-task-list-all-daily-info-item-type-get';
import { ApiTaskListAllDailyInfoItemTypeGet$Params } from '../fn/task-list/api-task-list-all-daily-info-item-type-get';
import { apiTaskListBuyerWorksheetTaskListPost } from '../fn/task-list/api-task-list-buyer-worksheet-task-list-post';
import { ApiTaskListBuyerWorksheetTaskListPost$Params } from '../fn/task-list/api-task-list-buyer-worksheet-task-list-post';
import { apiTaskListContractAmendmentMyTaskPost } from '../fn/task-list/api-task-list-contract-amendment-my-task-post';
import { ApiTaskListContractAmendmentMyTaskPost$Params } from '../fn/task-list/api-task-list-contract-amendment-my-task-post';
import { apiTaskListContractWorksheetMyTaskPost } from '../fn/task-list/api-task-list-contract-worksheet-my-task-post';
import { ApiTaskListContractWorksheetMyTaskPost$Params } from '../fn/task-list/api-task-list-contract-worksheet-my-task-post';
import { apiTaskListHomeTaskListPost } from '../fn/task-list/api-task-list-home-task-list-post';
import { ApiTaskListHomeTaskListPost$Params } from '../fn/task-list/api-task-list-home-task-list-post';
import { apiTaskListPrTaskListExportPost } from '../fn/task-list/api-task-list-pr-task-list-export-post';
import { ApiTaskListPrTaskListExportPost$Params } from '../fn/task-list/api-task-list-pr-task-list-export-post';
import { apiTaskListPrTaskListPost } from '../fn/task-list/api-task-list-pr-task-list-post';
import { ApiTaskListPrTaskListPost$Params } from '../fn/task-list/api-task-list-pr-task-list-post';
import { apiTaskListRfqTaskListGroupEmailNotifyPost } from '../fn/task-list/api-task-list-rfq-task-list-group-email-notify-post';
import { ApiTaskListRfqTaskListGroupEmailNotifyPost$Params } from '../fn/task-list/api-task-list-rfq-task-list-group-email-notify-post';
import { apiTaskListRfqTaskListPost } from '../fn/task-list/api-task-list-rfq-task-list-post';
import { ApiTaskListRfqTaskListPost$Params } from '../fn/task-list/api-task-list-rfq-task-list-post';
import { apiTaskListRpfMyTaskPost } from '../fn/task-list/api-task-list-rpf-my-task-post';
import { ApiTaskListRpfMyTaskPost$Params } from '../fn/task-list/api-task-list-rpf-my-task-post';
import { apiTaskListRpfTaskListExportPost } from '../fn/task-list/api-task-list-rpf-task-list-export-post';
import { ApiTaskListRpfTaskListExportPost$Params } from '../fn/task-list/api-task-list-rpf-task-list-export-post';
import { apiTaskListRpfTaskListPost } from '../fn/task-list/api-task-list-rpf-task-list-post';
import { ApiTaskListRpfTaskListPost$Params } from '../fn/task-list/api-task-list-rpf-task-list-post';
import { apiTaskListSynergyTaskListPost } from '../fn/task-list/api-task-list-synergy-task-list-post';
import { ApiTaskListSynergyTaskListPost$Params } from '../fn/task-list/api-task-list-synergy-task-list-post';
import { BuyerWorksheetTaskListResultItem } from '../models/buyer-worksheet-task-list-result-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { HomeTaskListResultItem } from '../models/home-task-list-result-item';
import { MyTaskItem } from '../models/my-task-item';
import { PrTaskListResultItem } from '../models/pr-task-list-result-item';
import { RfqTaskListResultItem } from '../models/rfq-task-list-result-item';
import { RpfTaskListResultItem } from '../models/rpf-task-list-result-item';
import { SynergyTaskListResultItem } from '../models/synergy-task-list-result-item';

@Injectable({ providedIn: 'root' })
export class TaskListAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTaskListAllDailyInfoItemTypeGet()` */
  static readonly ApiTaskListAllDailyInfoItemTypeGetPath = '/api/task-list/AllDailyInfoItemType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListAllDailyInfoItemTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListAllDailyInfoItemTypeGet$Response(params?: ApiTaskListAllDailyInfoItemTypeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiTaskListAllDailyInfoItemTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListAllDailyInfoItemTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListAllDailyInfoItemTypeGet(params?: ApiTaskListAllDailyInfoItemTypeGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiTaskListAllDailyInfoItemTypeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiTaskListRfqTaskListPost()` */
  static readonly ApiTaskListRfqTaskListPostPath = '/api/task-list/RfqTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListRfqTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRfqTaskListPost$Response(params?: ApiTaskListRfqTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTaskListResultItem>>> {
    return apiTaskListRfqTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListRfqTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRfqTaskListPost(params?: ApiTaskListRfqTaskListPost$Params, context?: HttpContext): Observable<Array<RfqTaskListResultItem>> {
    return this.apiTaskListRfqTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTaskListResultItem>>): Array<RfqTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListRfqTaskListGroupEmailNotifyPost()` */
  static readonly ApiTaskListRfqTaskListGroupEmailNotifyPostPath = '/api/task-list/RfqTaskList/GroupEmailNotify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListRfqTaskListGroupEmailNotifyPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRfqTaskListGroupEmailNotifyPost$Response(params?: ApiTaskListRfqTaskListGroupEmailNotifyPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiTaskListRfqTaskListGroupEmailNotifyPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListRfqTaskListGroupEmailNotifyPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRfqTaskListGroupEmailNotifyPost(params?: ApiTaskListRfqTaskListGroupEmailNotifyPost$Params, context?: HttpContext): Observable<void> {
    return this.apiTaskListRfqTaskListGroupEmailNotifyPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTaskListPrTaskListPost()` */
  static readonly ApiTaskListPrTaskListPostPath = '/api/task-list/PrTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListPrTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListPrTaskListPost$Response(params?: ApiTaskListPrTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<PrTaskListResultItem>>> {
    return apiTaskListPrTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListPrTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListPrTaskListPost(params?: ApiTaskListPrTaskListPost$Params, context?: HttpContext): Observable<Array<PrTaskListResultItem>> {
    return this.apiTaskListPrTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<PrTaskListResultItem>>): Array<PrTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListPrTaskListExportPost()` */
  static readonly ApiTaskListPrTaskListExportPostPath = '/api/task-list/PrTaskListExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListPrTaskListExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListPrTaskListExportPost$Response(params?: ApiTaskListPrTaskListExportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiTaskListPrTaskListExportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListPrTaskListExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListPrTaskListExportPost(params?: ApiTaskListPrTaskListExportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiTaskListPrTaskListExportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiTaskListSynergyTaskListPost()` */
  static readonly ApiTaskListSynergyTaskListPostPath = '/api/task-list/SynergyTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListSynergyTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListSynergyTaskListPost$Response(params?: ApiTaskListSynergyTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<SynergyTaskListResultItem>>> {
    return apiTaskListSynergyTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListSynergyTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListSynergyTaskListPost(params?: ApiTaskListSynergyTaskListPost$Params, context?: HttpContext): Observable<Array<SynergyTaskListResultItem>> {
    return this.apiTaskListSynergyTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<SynergyTaskListResultItem>>): Array<SynergyTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListHomeTaskListPost()` */
  static readonly ApiTaskListHomeTaskListPostPath = '/api/task-list/HomeTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListHomeTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListHomeTaskListPost$Response(params?: ApiTaskListHomeTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<HomeTaskListResultItem>> {
    return apiTaskListHomeTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListHomeTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListHomeTaskListPost(params?: ApiTaskListHomeTaskListPost$Params, context?: HttpContext): Observable<HomeTaskListResultItem> {
    return this.apiTaskListHomeTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<HomeTaskListResultItem>): HomeTaskListResultItem => r.body)
    );
  }

  /** Path part for operation `apiTaskListBuyerWorksheetTaskListPost()` */
  static readonly ApiTaskListBuyerWorksheetTaskListPostPath = '/api/task-list/BuyerWorksheetTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListBuyerWorksheetTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListBuyerWorksheetTaskListPost$Response(params?: ApiTaskListBuyerWorksheetTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<BuyerWorksheetTaskListResultItem>>> {
    return apiTaskListBuyerWorksheetTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListBuyerWorksheetTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListBuyerWorksheetTaskListPost(params?: ApiTaskListBuyerWorksheetTaskListPost$Params, context?: HttpContext): Observable<Array<BuyerWorksheetTaskListResultItem>> {
    return this.apiTaskListBuyerWorksheetTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<BuyerWorksheetTaskListResultItem>>): Array<BuyerWorksheetTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListRpfTaskListPost()` */
  static readonly ApiTaskListRpfTaskListPostPath = '/api/task-list/RpfTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListRpfTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRpfTaskListPost$Response(params?: ApiTaskListRpfTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RpfTaskListResultItem>>> {
    return apiTaskListRpfTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListRpfTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRpfTaskListPost(params?: ApiTaskListRpfTaskListPost$Params, context?: HttpContext): Observable<Array<RpfTaskListResultItem>> {
    return this.apiTaskListRpfTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RpfTaskListResultItem>>): Array<RpfTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListRpfTaskListExportPost()` */
  static readonly ApiTaskListRpfTaskListExportPostPath = '/api/task-list/RpfTaskListExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListRpfTaskListExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRpfTaskListExportPost$Response(params?: ApiTaskListRpfTaskListExportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiTaskListRpfTaskListExportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListRpfTaskListExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTaskListRpfTaskListExportPost(params?: ApiTaskListRpfTaskListExportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiTaskListRpfTaskListExportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiTaskListRpfMyTaskPost()` */
  static readonly ApiTaskListRpfMyTaskPostPath = '/api/task-list/RpfMyTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListRpfMyTaskPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListRpfMyTaskPost$Response(params?: ApiTaskListRpfMyTaskPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MyTaskItem>>> {
    return apiTaskListRpfMyTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListRpfMyTaskPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListRpfMyTaskPost(params?: ApiTaskListRpfMyTaskPost$Params, context?: HttpContext): Observable<Array<MyTaskItem>> {
    return this.apiTaskListRpfMyTaskPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MyTaskItem>>): Array<MyTaskItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListContractAmendmentMyTaskPost()` */
  static readonly ApiTaskListContractAmendmentMyTaskPostPath = '/api/task-list/ContractAmendmentMyTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListContractAmendmentMyTaskPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListContractAmendmentMyTaskPost$Response(params?: ApiTaskListContractAmendmentMyTaskPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MyTaskItem>>> {
    return apiTaskListContractAmendmentMyTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListContractAmendmentMyTaskPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListContractAmendmentMyTaskPost(params?: ApiTaskListContractAmendmentMyTaskPost$Params, context?: HttpContext): Observable<Array<MyTaskItem>> {
    return this.apiTaskListContractAmendmentMyTaskPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MyTaskItem>>): Array<MyTaskItem> => r.body)
    );
  }

  /** Path part for operation `apiTaskListContractWorksheetMyTaskPost()` */
  static readonly ApiTaskListContractWorksheetMyTaskPostPath = '/api/task-list/ContractWorksheetMyTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaskListContractWorksheetMyTaskPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListContractWorksheetMyTaskPost$Response(params?: ApiTaskListContractWorksheetMyTaskPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MyTaskItem>>> {
    return apiTaskListContractWorksheetMyTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaskListContractWorksheetMyTaskPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaskListContractWorksheetMyTaskPost(params?: ApiTaskListContractWorksheetMyTaskPost$Params, context?: HttpContext): Observable<Array<MyTaskItem>> {
    return this.apiTaskListContractWorksheetMyTaskPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MyTaskItem>>): Array<MyTaskItem> => r.body)
    );
  }

}
