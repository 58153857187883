/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiMasterDataPortActiveDistinctPortNamePost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiMasterDataPortActiveDistinctPortNamePost(http: HttpClient, rootUrl: string, params?: ApiMasterDataPortActiveDistinctPortNamePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiMasterDataPortActiveDistinctPortNamePost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<string>>;
    })
  );
}

apiMasterDataPortActiveDistinctPortNamePost.PATH = '/api/MasterData/Port/ActiveDistinctPortName';
