/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost } from '../fn/master-po-doc-type/api-master-data-po-doc-type-active-for-buyer-work-sheet-post';
import { ApiMasterDataPoDocTypeActiveForBuyerWorkSheetPost$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-active-for-buyer-work-sheet-post';
import { apiMasterDataPoDocTypeActiveForPrPost } from '../fn/master-po-doc-type/api-master-data-po-doc-type-active-for-pr-post';
import { ApiMasterDataPoDocTypeActiveForPrPost$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-active-for-pr-post';
import { apiMasterDataPoDocTypeActivePost } from '../fn/master-po-doc-type/api-master-data-po-doc-type-active-post';
import { ApiMasterDataPoDocTypeActivePost$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-active-post';
import { apiMasterDataPoDocTypeAddPost } from '../fn/master-po-doc-type/api-master-data-po-doc-type-add-post';
import { ApiMasterDataPoDocTypeAddPost$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-add-post';
import { apiMasterDataPoDocTypeAllGet } from '../fn/master-po-doc-type/api-master-data-po-doc-type-all-get';
import { ApiMasterDataPoDocTypeAllGet$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-all-get';
import { apiMasterDataPoDocTypeDeleteGet } from '../fn/master-po-doc-type/api-master-data-po-doc-type-delete-get';
import { ApiMasterDataPoDocTypeDeleteGet$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-delete-get';
import { apiMasterDataPoDocTypeEditPost } from '../fn/master-po-doc-type/api-master-data-po-doc-type-edit-post';
import { ApiMasterDataPoDocTypeEditPost$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-edit-post';
import { apiMasterDataPoDocTypeMaintainGet } from '../fn/master-po-doc-type/api-master-data-po-doc-type-maintain-get';
import { ApiMasterDataPoDocTypeMaintainGet$Params } from '../fn/master-po-doc-type/api-master-data-po-doc-type-maintain-get';
import { MasterPoDocType } from '../models/master-po-doc-type';

@Injectable({ providedIn: 'root' })
export class MasterPoDocTypeAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataPoDocTypeActivePost()` */
  static readonly ApiMasterDataPoDocTypeActivePostPath = '/api/MasterData/PODocType/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeActivePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeActivePost$Response(params?: ApiMasterDataPoDocTypeActivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPoDocType>>> {
    return apiMasterDataPoDocTypeActivePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeActivePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeActivePost(params?: ApiMasterDataPoDocTypeActivePost$Params, context?: HttpContext): Observable<Array<MasterPoDocType>> {
    return this.apiMasterDataPoDocTypeActivePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPoDocType>>): Array<MasterPoDocType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeActiveForPrPost()` */
  static readonly ApiMasterDataPoDocTypeActiveForPrPostPath = '/api/MasterData/PODocType/ActiveForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeActiveForPrPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeActiveForPrPost$Response(params?: ApiMasterDataPoDocTypeActiveForPrPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPoDocType>>> {
    return apiMasterDataPoDocTypeActiveForPrPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeActiveForPrPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeActiveForPrPost(params?: ApiMasterDataPoDocTypeActiveForPrPost$Params, context?: HttpContext): Observable<Array<MasterPoDocType>> {
    return this.apiMasterDataPoDocTypeActiveForPrPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPoDocType>>): Array<MasterPoDocType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost()` */
  static readonly ApiMasterDataPoDocTypeActiveForBuyerWorkSheetPostPath = '/api/MasterData/PODocType/ActiveForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost$Response(params?: ApiMasterDataPoDocTypeActiveForBuyerWorkSheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPoDocType>>> {
    return apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost(params?: ApiMasterDataPoDocTypeActiveForBuyerWorkSheetPost$Params, context?: HttpContext): Observable<Array<MasterPoDocType>> {
    return this.apiMasterDataPoDocTypeActiveForBuyerWorkSheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPoDocType>>): Array<MasterPoDocType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeMaintainGet()` */
  static readonly ApiMasterDataPoDocTypeMaintainGetPath = '/api/MasterData/PODocType/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeMaintainGet$Response(params?: ApiMasterDataPoDocTypeMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPoDocType>>> {
    return apiMasterDataPoDocTypeMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeMaintainGet(params?: ApiMasterDataPoDocTypeMaintainGet$Params, context?: HttpContext): Observable<Array<MasterPoDocType>> {
    return this.apiMasterDataPoDocTypeMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPoDocType>>): Array<MasterPoDocType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeAllGet()` */
  static readonly ApiMasterDataPoDocTypeAllGetPath = '/api/MasterData/PODocType/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeAllGet$Response(params?: ApiMasterDataPoDocTypeAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPoDocType>>> {
    return apiMasterDataPoDocTypeAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeAllGet(params?: ApiMasterDataPoDocTypeAllGet$Params, context?: HttpContext): Observable<Array<MasterPoDocType>> {
    return this.apiMasterDataPoDocTypeAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPoDocType>>): Array<MasterPoDocType> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeAddPost()` */
  static readonly ApiMasterDataPoDocTypeAddPostPath = '/api/MasterData/PODocType/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPoDocTypeAddPost$Response(params?: ApiMasterDataPoDocTypeAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPoDocTypeAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPoDocTypeAddPost(params?: ApiMasterDataPoDocTypeAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPoDocTypeAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeEditPost()` */
  static readonly ApiMasterDataPoDocTypeEditPostPath = '/api/MasterData/PODocType/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPoDocTypeEditPost$Response(params?: ApiMasterDataPoDocTypeEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPoDocTypeEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPoDocTypeEditPost(params?: ApiMasterDataPoDocTypeEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPoDocTypeEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPoDocTypeDeleteGet()` */
  static readonly ApiMasterDataPoDocTypeDeleteGetPath = '/api/MasterData/PODocType/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPoDocTypeDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeDeleteGet$Response(params?: ApiMasterDataPoDocTypeDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPoDocTypeDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPoDocTypeDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPoDocTypeDeleteGet(params?: ApiMasterDataPoDocTypeDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPoDocTypeDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
