/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiEmailnotificationlogSearchPost } from '../fn/email-notification-log/api-emailnotificationlog-search-post';
import { ApiEmailnotificationlogSearchPost$Params } from '../fn/email-notification-log/api-emailnotificationlog-search-post';
import { EmailNotificationLog } from '../models/email-notification-log';

@Injectable({ providedIn: 'root' })
export class EmailNotificationLogAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiEmailnotificationlogSearchPost()` */
  static readonly ApiEmailnotificationlogSearchPostPath = '/api/emailnotificationlog/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailnotificationlogSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmailnotificationlogSearchPost$Response(params?: ApiEmailnotificationlogSearchPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<EmailNotificationLog>>> {
    return apiEmailnotificationlogSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmailnotificationlogSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmailnotificationlogSearchPost(params?: ApiEmailnotificationlogSearchPost$Params, context?: HttpContext): Observable<Array<EmailNotificationLog>> {
    return this.apiEmailnotificationlogSearchPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<EmailNotificationLog>>): Array<EmailNotificationLog> => r.body)
    );
  }

}
