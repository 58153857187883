/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { MasterPdpa } from '../../models/master-pdpa';

export interface ApiMasterDataPdpaAllGet$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiMasterDataPdpaAllGet(http: HttpClient, rootUrl: string, params?: ApiMasterDataPdpaAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPdpa>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiMasterDataPdpaAllGet.PATH, 'get');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<MasterPdpa>>;
    })
  );
}

apiMasterDataPdpaAllGet.PATH = '/api/MasterData/PDPA/All';
