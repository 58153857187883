import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, Observable, of, switchMap } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { WorkGroupAzureApiService } from '../api-azure/services';
import { RecheckTransactionWorkingGroupDto } from '../api-azure/models';

@Injectable({ providedIn: 'root' })
export class WorkingGroupGuard implements CanActivate {

   // Define the list of routes that require recheck
   private routesToRecheck: WorkingGroupRouteConfig[] = 
   [
    {
        type : 'RPF',
        routes : [
            {url : '/form-rpf', indicater : 'pk'},

        ]
    },
    {
        type : 'DAILY',
        routes : [
            //{url : '', indicater : ''},
        ]
    },
    {
        type : 'RFQ',
        routes : [
            {url : '/rfq-detail/rfq-transaction', indicater : 'pk'},
            {url : '/vendor/rfq', indicater : 'pk'}
        ]
    },
    {
        type : 'SYNG',
        routes : [
            {url : '/synergy', indicater : 'pk'}
        ]
    },  
    {
        type : 'BUYER',
        routes : [
            {url : '/buyer-worksheet', indicater : 'pk'},
        ]
    },
    {
        type : 'CONTRACT',
        routes : [
            {url : '/contract-worksheet', indicater : 'id'},
        ]
    },
    {
        type : 'CONTRACT_AMD',
        routes : [
            {url : '/contract-amendment-form', indicater : 'pk'},
        ]
    },
    {
        type : 'AUCTION',
        routes : [
            {url : '/auction-worksheet/event-detail', indicater : 'pk'},
        ]
    }
   ]; 
   
   constructor(
    private workgroupService: WorkGroupAzureApiService,
    private router: Router,
    private notification: NzNotificationService
  ) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Get the current route path
    const currentPath = state.url;

    this.routesToRecheck.forEach(routeType => {
        // Check if the current route is in the list of routes that require recheck
        const routeWithQueryParam = routeType.routes.find(r => currentPath.startsWith(r.url));
        if (routeWithQueryParam) {
            
            // Get the value of the query parameter
            const queryStringValue = route.queryParams[routeWithQueryParam.indicater];
            
            if(!queryStringValue){
                return true;
            }

            // Call your recheck API here with the query string value
            return this.workgroupService.apiWorkGroupWorkGroupRecheckTransactionWorkingGroupGet({type : routeType.type , id : queryStringValue})
            .subscribe({
                next: (res : RecheckTransactionWorkingGroupDto) => {
                    if (res.isValid) {
                        return true; // Proceed with navigation
                    } else {
                        // Display NZ alert popup
                        this.notification.error('Access Denied', 'Current Working group is not match with ' + res.targetWorkingGroup);
                        // Redirect to error page
                        this.router.navigate(['/error/403']);
                        return false; // Prevent navigation
                    }
                },
                error: (err) => {
                    this.notification.error('Error occurred', 'Cannot validate current Working group');
                    console.error('Error occurred:', err);
                    return false; // Prevent navigation
                },
              });

            //return true;
        } else {
            return true; // Allow navigation for routes not in the list
        }
    });

    return true;
  }
}

interface WorkingGroupRouteConfig {
    type : string;
    routes : WorkingGroupRouteConfigRoute[];
 }

interface WorkingGroupRouteConfigRoute {
    url : string;
    indicater : string;
 }
