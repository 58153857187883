/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface FileBuyerWorksheetDistTypeTokenGet$Params {
  type: string;
  token: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function fileBuyerWorksheetDistTypeTokenGet(http: HttpClient, rootUrl: string, params: FileBuyerWorksheetDistTypeTokenGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
  const rb = new AzureApiRequestBuilder(rootUrl, fileBuyerWorksheetDistTypeTokenGet.PATH, 'get');
  if (params) {
    rb.path('type', params.type, {});
    rb.path('token', params.token, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AzureApiStrictHttpResponse<void>;
    })
  );
}

fileBuyerWorksheetDistTypeTokenGet.PATH = '/file/buyer-worksheet/dist/{type}/{token}';
