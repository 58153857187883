/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiDailyInfoItemExportContractPost } from '../fn/daily-info-item/api-daily-info-item-export-contract-post';
import { ApiDailyInfoItemExportContractPost$Params } from '../fn/daily-info-item/api-daily-info-item-export-contract-post';
import { apiDailyInfoItemGetAttachmentGet } from '../fn/daily-info-item/api-daily-info-item-get-attachment-get';
import { ApiDailyInfoItemGetAttachmentGet$Params } from '../fn/daily-info-item/api-daily-info-item-get-attachment-get';
import { apiDailyInfoItemGetChangeLogGet } from '../fn/daily-info-item/api-daily-info-item-get-change-log-get';
import { ApiDailyInfoItemGetChangeLogGet$Params } from '../fn/daily-info-item/api-daily-info-item-get-change-log-get';
import { apiDailyInfoItemGetListPost } from '../fn/daily-info-item/api-daily-info-item-get-list-post';
import { ApiDailyInfoItemGetListPost$Params } from '../fn/daily-info-item/api-daily-info-item-get-list-post';
import { apiDailyInfoItemGetPrItemGet } from '../fn/daily-info-item/api-daily-info-item-get-pr-item-get';
import { ApiDailyInfoItemGetPrItemGet$Params } from '../fn/daily-info-item/api-daily-info-item-get-pr-item-get';
import { apiDailyInfoItemMockApiGet } from '../fn/daily-info-item/api-daily-info-item-mock-api-get';
import { ApiDailyInfoItemMockApiGet$Params } from '../fn/daily-info-item/api-daily-info-item-mock-api-get';
import { apiDailyInfoItemPreviewEmailRequestDocPost } from '../fn/daily-info-item/api-daily-info-item-preview-email-request-doc-post';
import { ApiDailyInfoItemPreviewEmailRequestDocPost$Params } from '../fn/daily-info-item/api-daily-info-item-preview-email-request-doc-post';
import { apiDailyInfoItemSendEmailRequestDocPost } from '../fn/daily-info-item/api-daily-info-item-send-email-request-doc-post';
import { ApiDailyInfoItemSendEmailRequestDocPost$Params } from '../fn/daily-info-item/api-daily-info-item-send-email-request-doc-post';
import { apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost } from '../fn/daily-info-item/api-daily-info-item-update-daily-info-items-from-workstation-post';
import { ApiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost$Params } from '../fn/daily-info-item/api-daily-info-item-update-daily-info-items-from-workstation-post';
import { apiDailyInfoItemUpdateDailyInfoItemsPost } from '../fn/daily-info-item/api-daily-info-item-update-daily-info-items-post';
import { ApiDailyInfoItemUpdateDailyInfoItemsPost$Params } from '../fn/daily-info-item/api-daily-info-item-update-daily-info-items-post';
import { apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost } from '../fn/daily-info-item/api-daily-info-item-update-daily-info-items-pur-group-post';
import { ApiDailyInfoItemUpdateDailyInfoItemsPurGroupPost$Params } from '../fn/daily-info-item/api-daily-info-item-update-daily-info-items-pur-group-post';
import { apiDailyInfoItemUpdateStatusBackPost } from '../fn/daily-info-item/api-daily-info-item-update-status-back-post';
import { ApiDailyInfoItemUpdateStatusBackPost$Params } from '../fn/daily-info-item/api-daily-info-item-update-status-back-post';
import { apiDailyInfoItemUpdateStatusIncompletePrPost } from '../fn/daily-info-item/api-daily-info-item-update-status-incomplete-pr-post';
import { ApiDailyInfoItemUpdateStatusIncompletePrPost$Params } from '../fn/daily-info-item/api-daily-info-item-update-status-incomplete-pr-post';
import { DailyInfoItemChangeLog } from '../models/daily-info-item-change-log';
import { DailyInfoRequestItem } from '../models/daily-info-request-item';
import { DailyInfoRequestItemAttachment } from '../models/daily-info-request-item-attachment';
import { DailyInfoSummaryItem } from '../models/daily-info-summary-item';
import { EmailNotifyItem } from '../models/email-notify-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { PrServiceItem } from '../models/pr-service-item';

@Injectable({ providedIn: 'root' })
export class DailyInfoItemAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiDailyInfoItemGetListPost()` */
  static readonly ApiDailyInfoItemGetListPostPath = '/api/DailyInfoItem/GetList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemGetListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemGetListPost$Response(params?: ApiDailyInfoItemGetListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<DailyInfoRequestItem>>> {
    return apiDailyInfoItemGetListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemGetListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemGetListPost(params?: ApiDailyInfoItemGetListPost$Params, context?: HttpContext): Observable<Array<DailyInfoRequestItem>> {
    return this.apiDailyInfoItemGetListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<DailyInfoRequestItem>>): Array<DailyInfoRequestItem> => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost()` */
  static readonly ApiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPostPath = '/api/DailyInfoItem/UpdateDailyInfoItemsFromWorkstation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost$Response(params?: ApiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost(params?: ApiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDailyInfoItemUpdateDailyInfoItemsFromWorkstationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemUpdateDailyInfoItemsPost()` */
  static readonly ApiDailyInfoItemUpdateDailyInfoItemsPostPath = '/api/DailyInfoItem/UpdateDailyInfoItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemUpdateDailyInfoItemsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateDailyInfoItemsPost$Response(params?: ApiDailyInfoItemUpdateDailyInfoItemsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDailyInfoItemUpdateDailyInfoItemsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemUpdateDailyInfoItemsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateDailyInfoItemsPost(params?: ApiDailyInfoItemUpdateDailyInfoItemsPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDailyInfoItemUpdateDailyInfoItemsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost()` */
  static readonly ApiDailyInfoItemUpdateDailyInfoItemsPurGroupPostPath = '/api/DailyInfoItem/UpdateDailyInfoItemsPurGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost$Response(params?: ApiDailyInfoItemUpdateDailyInfoItemsPurGroupPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost(params?: ApiDailyInfoItemUpdateDailyInfoItemsPurGroupPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDailyInfoItemUpdateDailyInfoItemsPurGroupPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemUpdateStatusIncompletePrPost()` */
  static readonly ApiDailyInfoItemUpdateStatusIncompletePrPostPath = '/api/DailyInfoItem/UpdateStatusIncompletePR';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemUpdateStatusIncompletePrPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateStatusIncompletePrPost$Response(params?: ApiDailyInfoItemUpdateStatusIncompletePrPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDailyInfoItemUpdateStatusIncompletePrPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemUpdateStatusIncompletePrPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateStatusIncompletePrPost(params?: ApiDailyInfoItemUpdateStatusIncompletePrPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDailyInfoItemUpdateStatusIncompletePrPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemUpdateStatusBackPost()` */
  static readonly ApiDailyInfoItemUpdateStatusBackPostPath = '/api/DailyInfoItem/UpdateStatusBack';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemUpdateStatusBackPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateStatusBackPost$Response(params?: ApiDailyInfoItemUpdateStatusBackPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDailyInfoItemUpdateStatusBackPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemUpdateStatusBackPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemUpdateStatusBackPost(params?: ApiDailyInfoItemUpdateStatusBackPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDailyInfoItemUpdateStatusBackPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemGetChangeLogGet()` */
  static readonly ApiDailyInfoItemGetChangeLogGetPath = '/api/DailyInfoItem/GetChangeLog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemGetChangeLogGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemGetChangeLogGet$Response(params?: ApiDailyInfoItemGetChangeLogGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<DailyInfoItemChangeLog>>> {
    return apiDailyInfoItemGetChangeLogGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemGetChangeLogGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemGetChangeLogGet(params?: ApiDailyInfoItemGetChangeLogGet$Params, context?: HttpContext): Observable<Array<DailyInfoItemChangeLog>> {
    return this.apiDailyInfoItemGetChangeLogGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<DailyInfoItemChangeLog>>): Array<DailyInfoItemChangeLog> => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemGetAttachmentGet()` */
  static readonly ApiDailyInfoItemGetAttachmentGetPath = '/api/DailyInfoItem/GetAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemGetAttachmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemGetAttachmentGet$Response(params?: ApiDailyInfoItemGetAttachmentGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<DailyInfoRequestItemAttachment>>> {
    return apiDailyInfoItemGetAttachmentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemGetAttachmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemGetAttachmentGet(params?: ApiDailyInfoItemGetAttachmentGet$Params, context?: HttpContext): Observable<Array<DailyInfoRequestItemAttachment>> {
    return this.apiDailyInfoItemGetAttachmentGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<DailyInfoRequestItemAttachment>>): Array<DailyInfoRequestItemAttachment> => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemExportContractPost()` */
  static readonly ApiDailyInfoItemExportContractPostPath = '/api/DailyInfoItem/ExportContract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemExportContractPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemExportContractPost$Response(params?: ApiDailyInfoItemExportContractPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiDailyInfoItemExportContractPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemExportContractPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemExportContractPost(params?: ApiDailyInfoItemExportContractPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiDailyInfoItemExportContractPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemPreviewEmailRequestDocPost()` */
  static readonly ApiDailyInfoItemPreviewEmailRequestDocPostPath = '/api/DailyInfoItem/PreviewEmailRequestDoc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemPreviewEmailRequestDocPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemPreviewEmailRequestDocPost$Response(params?: ApiDailyInfoItemPreviewEmailRequestDocPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<EmailNotifyItem>>> {
    return apiDailyInfoItemPreviewEmailRequestDocPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemPreviewEmailRequestDocPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemPreviewEmailRequestDocPost(params?: ApiDailyInfoItemPreviewEmailRequestDocPost$Params, context?: HttpContext): Observable<Array<EmailNotifyItem>> {
    return this.apiDailyInfoItemPreviewEmailRequestDocPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<EmailNotifyItem>>): Array<EmailNotifyItem> => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemSendEmailRequestDocPost()` */
  static readonly ApiDailyInfoItemSendEmailRequestDocPostPath = '/api/DailyInfoItem/SendEmailRequestDoc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemSendEmailRequestDocPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemSendEmailRequestDocPost$Response(params?: ApiDailyInfoItemSendEmailRequestDocPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDailyInfoItemSendEmailRequestDocPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemSendEmailRequestDocPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDailyInfoItemSendEmailRequestDocPost(params?: ApiDailyInfoItemSendEmailRequestDocPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDailyInfoItemSendEmailRequestDocPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemGetPrItemGet()` */
  static readonly ApiDailyInfoItemGetPrItemGetPath = '/api/DailyInfoItem/GetPRItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemGetPrItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemGetPrItemGet$Response(params?: ApiDailyInfoItemGetPrItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<PrServiceItem>>> {
    return apiDailyInfoItemGetPrItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemGetPrItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemGetPrItemGet(params?: ApiDailyInfoItemGetPrItemGet$Params, context?: HttpContext): Observable<Array<PrServiceItem>> {
    return this.apiDailyInfoItemGetPrItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<PrServiceItem>>): Array<PrServiceItem> => r.body)
    );
  }

  /** Path part for operation `apiDailyInfoItemMockApiGet()` */
  static readonly ApiDailyInfoItemMockApiGetPath = '/api/DailyInfoItem/MockAPI';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDailyInfoItemMockApiGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemMockApiGet$Response(params?: ApiDailyInfoItemMockApiGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<DailyInfoSummaryItem>>> {
    return apiDailyInfoItemMockApiGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDailyInfoItemMockApiGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDailyInfoItemMockApiGet(params?: ApiDailyInfoItemMockApiGet$Params, context?: HttpContext): Observable<Array<DailyInfoSummaryItem>> {
    return this.apiDailyInfoItemMockApiGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<DailyInfoSummaryItem>>): Array<DailyInfoSummaryItem> => r.body)
    );
  }

}
