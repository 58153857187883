/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { RfqTransactionItem } from '../../models/rfq-transaction-item';

export interface ApiRequestNewRfqItemsGet$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: Array<string>
}

export function apiRequestNewRfqItemsGet(http: HttpClient, rootUrl: string, params?: ApiRequestNewRfqItemsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTransactionItem>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestNewRfqItemsGet.PATH, 'get');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<RfqTransactionItem>>;
    })
  );
}

apiRequestNewRfqItemsGet.PATH = '/api/request/new-rfq/Items';
