/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { PrCognitiveResultItem } from '../../models/pr-cognitive-result-item';

export interface ApiRequestPrCognitiveVendorDetailFromCognitivePost$Params {
  prNumber?: string;
  prItem?: string;
  vendorCode?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestPrCognitiveVendorDetailFromCognitivePost(http: HttpClient, rootUrl: string, params?: ApiRequestPrCognitiveVendorDetailFromCognitivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<PrCognitiveResultItem>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestPrCognitiveVendorDetailFromCognitivePost.PATH, 'post');
  if (params) {
    rb.query('prNumber', params.prNumber, {});
    rb.query('prItem', params.prItem, {});
    rb.query('vendorCode', params.vendorCode, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<PrCognitiveResultItem>>;
    })
  );
}

apiRequestPrCognitiveVendorDetailFromCognitivePost.PATH = '/api/request/pr-cognitive/VendorDetailFromCognitive';
