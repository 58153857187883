/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestAuctionWorksheetCopyGet } from '../fn/auction-worksheet/api-request-auction-worksheet-copy-get';
import { ApiRequestAuctionWorksheetCopyGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-copy-get';
import { apiRequestAuctionWorksheetCreateBuyerWorksheetPost } from '../fn/auction-worksheet/api-request-auction-worksheet-create-buyer-worksheet-post';
import { ApiRequestAuctionWorksheetCreateBuyerWorksheetPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-create-buyer-worksheet-post';
import { apiRequestAuctionWorksheetCreateContractWorksheetPost } from '../fn/auction-worksheet/api-request-auction-worksheet-create-contract-worksheet-post';
import { ApiRequestAuctionWorksheetCreateContractWorksheetPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-create-contract-worksheet-post';
import { apiRequestAuctionWorksheetExportBiddingPost } from '../fn/auction-worksheet/api-request-auction-worksheet-export-bidding-post';
import { ApiRequestAuctionWorksheetExportBiddingPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-export-bidding-post';
import { apiRequestAuctionWorksheetGetAgreementPdfPost } from '../fn/auction-worksheet/api-request-auction-worksheet-get-agreement-pdf-post';
import { ApiRequestAuctionWorksheetGetAgreementPdfPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-get-agreement-pdf-post';
import { apiRequestAuctionWorksheetGetBiddingPost } from '../fn/auction-worksheet/api-request-auction-worksheet-get-bidding-post';
import { ApiRequestAuctionWorksheetGetBiddingPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-get-bidding-post';
import { apiRequestAuctionWorksheetGetGet } from '../fn/auction-worksheet/api-request-auction-worksheet-get-get';
import { ApiRequestAuctionWorksheetGetGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-get-get';
import { apiRequestAuctionWorksheetGetIdGet } from '../fn/auction-worksheet/api-request-auction-worksheet-get-id-get';
import { ApiRequestAuctionWorksheetGetIdGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-get-id-get';
import { apiRequestAuctionWorksheetGetStatusAllGet } from '../fn/auction-worksheet/api-request-auction-worksheet-get-status-all-get';
import { ApiRequestAuctionWorksheetGetStatusAllGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-get-status-all-get';
import { apiRequestAuctionWorksheetGetVendorAttachmentGet } from '../fn/auction-worksheet/api-request-auction-worksheet-get-vendor-attachment-get';
import { ApiRequestAuctionWorksheetGetVendorAttachmentGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-get-vendor-attachment-get';
import { apiRequestAuctionWorksheetInitialGet } from '../fn/auction-worksheet/api-request-auction-worksheet-initial-get';
import { ApiRequestAuctionWorksheetInitialGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-initial-get';
import { apiRequestAuctionWorksheetInitialRequestPost } from '../fn/auction-worksheet/api-request-auction-worksheet-initial-request-post';
import { ApiRequestAuctionWorksheetInitialRequestPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-initial-request-post';
import { apiRequestAuctionWorksheetOpenBiddingPricePost } from '../fn/auction-worksheet/api-request-auction-worksheet-open-bidding-price-post';
import { ApiRequestAuctionWorksheetOpenBiddingPricePost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-open-bidding-price-post';
import { apiRequestAuctionWorksheetResendPasswordPost } from '../fn/auction-worksheet/api-request-auction-worksheet-resend-password-post';
import { ApiRequestAuctionWorksheetResendPasswordPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-resend-password-post';
import { apiRequestAuctionWorksheetRevokeRejectedInvitationGet } from '../fn/auction-worksheet/api-request-auction-worksheet-revoke-rejected-invitation-get';
import { ApiRequestAuctionWorksheetRevokeRejectedInvitationGet$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-revoke-rejected-invitation-get';
import { apiRequestAuctionWorksheetSearchRpfItemPost } from '../fn/auction-worksheet/api-request-auction-worksheet-search-rpf-item-post';
import { ApiRequestAuctionWorksheetSearchRpfItemPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-search-rpf-item-post';
import { apiRequestAuctionWorksheetSearchVendorVenusPost } from '../fn/auction-worksheet/api-request-auction-worksheet-search-vendor-venus-post';
import { ApiRequestAuctionWorksheetSearchVendorVenusPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-search-vendor-venus-post';
import { apiRequestAuctionWorksheetSubmitPost } from '../fn/auction-worksheet/api-request-auction-worksheet-submit-post';
import { ApiRequestAuctionWorksheetSubmitPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-submit-post';
import { apiRequestAuctionWorksheetUploadAttactmentPost } from '../fn/auction-worksheet/api-request-auction-worksheet-upload-attactment-post';
import { ApiRequestAuctionWorksheetUploadAttactmentPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-upload-attactment-post';
import { apiRequestAuctionWorksheetUploadVendorAttactmentPost } from '../fn/auction-worksheet/api-request-auction-worksheet-upload-vendor-attactment-post';
import { ApiRequestAuctionWorksheetUploadVendorAttactmentPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-upload-vendor-attactment-post';
import { apiRequestAuctionWorksheetValidateGeneralEventItemPost } from '../fn/auction-worksheet/api-request-auction-worksheet-validate-general-event-item-post';
import { ApiRequestAuctionWorksheetValidateGeneralEventItemPost$Params } from '../fn/auction-worksheet/api-request-auction-worksheet-validate-general-event-item-post';
import { AuctionWorksheetBiddingDto } from '../models/auction-worksheet-bidding-dto';
import { AuctionWorksheetBiddingDtoResponseModel } from '../models/auction-worksheet-bidding-dto-response-model';
import { AuctionWorksheetBuyerWorksheetResponseDto } from '../models/auction-worksheet-buyer-worksheet-response-dto';
import { AuctionWorksheetContractWorkhseetResponseDto } from '../models/auction-worksheet-contract-workhseet-response-dto';
import { AuctionWorksheetDto } from '../models/auction-worksheet-dto';
import { AuctionWorksheetDtoResponseModel } from '../models/auction-worksheet-dto-response-model';
import { AuctionWorksheetFileDto } from '../models/auction-worksheet-file-dto';
import { AuctionWorksheetGeneralEventItemValidateDto } from '../models/auction-worksheet-general-event-item-validate-dto';
import { AuctionWorksheetInvitationDto } from '../models/auction-worksheet-invitation-dto';
import { AuctionWorksheetRpfItemResponseDto } from '../models/auction-worksheet-rpf-item-response-dto';
import { BooleanNullableResponseModel } from '../models/boolean-nullable-response-model';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { VendorAuctionWorksheetAttachmentDto } from '../models/vendor-auction-worksheet-attachment-dto';

@Injectable({ providedIn: 'root' })
export class AuctionWorksheetAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestAuctionWorksheetGetIdGet()` */
  static readonly ApiRequestAuctionWorksheetGetIdGetPath = '/api/request/auction-worksheet/GetId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetGetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetIdGet$Response(params?: ApiRequestAuctionWorksheetGetIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestAuctionWorksheetGetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetGetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetIdGet(params?: ApiRequestAuctionWorksheetGetIdGet$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestAuctionWorksheetGetIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetGetGet()` */
  static readonly ApiRequestAuctionWorksheetGetGetPath = '/api/request/auction-worksheet/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetGet$Response(params?: ApiRequestAuctionWorksheetGetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetDtoResponseModel>> {
    return apiRequestAuctionWorksheetGetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetGet(params?: ApiRequestAuctionWorksheetGetGet$Params, context?: HttpContext): Observable<AuctionWorksheetDtoResponseModel> {
    return this.apiRequestAuctionWorksheetGetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetDtoResponseModel>): AuctionWorksheetDtoResponseModel => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetGetStatusAllGet()` */
  static readonly ApiRequestAuctionWorksheetGetStatusAllGetPath = '/api/request/auction-worksheet/GetStatusAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetGetStatusAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetStatusAllGet$Response(params?: ApiRequestAuctionWorksheetGetStatusAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestAuctionWorksheetGetStatusAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetGetStatusAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetStatusAllGet(params?: ApiRequestAuctionWorksheetGetStatusAllGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestAuctionWorksheetGetStatusAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetInitialGet()` */
  static readonly ApiRequestAuctionWorksheetInitialGetPath = '/api/request/auction-worksheet/Initial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetInitialGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetInitialGet$Response(params?: ApiRequestAuctionWorksheetInitialGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetDto>> {
    return apiRequestAuctionWorksheetInitialGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetInitialGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetInitialGet(params?: ApiRequestAuctionWorksheetInitialGet$Params, context?: HttpContext): Observable<AuctionWorksheetDto> {
    return this.apiRequestAuctionWorksheetInitialGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetDto>): AuctionWorksheetDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetInitialRequestPost()` */
  static readonly ApiRequestAuctionWorksheetInitialRequestPostPath = '/api/request/auction-worksheet/InitialRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetInitialRequestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetInitialRequestPost$Response(params?: ApiRequestAuctionWorksheetInitialRequestPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetDto>> {
    return apiRequestAuctionWorksheetInitialRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetInitialRequestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetInitialRequestPost(params?: ApiRequestAuctionWorksheetInitialRequestPost$Params, context?: HttpContext): Observable<AuctionWorksheetDto> {
    return this.apiRequestAuctionWorksheetInitialRequestPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetDto>): AuctionWorksheetDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetCopyGet()` */
  static readonly ApiRequestAuctionWorksheetCopyGetPath = '/api/request/auction-worksheet/Copy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetCopyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetCopyGet$Response(params?: ApiRequestAuctionWorksheetCopyGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetDtoResponseModel>> {
    return apiRequestAuctionWorksheetCopyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetCopyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetCopyGet(params?: ApiRequestAuctionWorksheetCopyGet$Params, context?: HttpContext): Observable<AuctionWorksheetDtoResponseModel> {
    return this.apiRequestAuctionWorksheetCopyGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetDtoResponseModel>): AuctionWorksheetDtoResponseModel => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetSubmitPost()` */
  static readonly ApiRequestAuctionWorksheetSubmitPostPath = '/api/request/auction-worksheet/Submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetSubmitPost$Response(params?: ApiRequestAuctionWorksheetSubmitPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetDto>> {
    return apiRequestAuctionWorksheetSubmitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetSubmitPost(params?: ApiRequestAuctionWorksheetSubmitPost$Params, context?: HttpContext): Observable<AuctionWorksheetDto> {
    return this.apiRequestAuctionWorksheetSubmitPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetDto>): AuctionWorksheetDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetSearchRpfItemPost()` */
  static readonly ApiRequestAuctionWorksheetSearchRpfItemPostPath = '/api/request/auction-worksheet/SearchRPFItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetSearchRpfItemPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetSearchRpfItemPost$Response(params?: ApiRequestAuctionWorksheetSearchRpfItemPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetRpfItemResponseDto>> {
    return apiRequestAuctionWorksheetSearchRpfItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetSearchRpfItemPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetSearchRpfItemPost(params?: ApiRequestAuctionWorksheetSearchRpfItemPost$Params, context?: HttpContext): Observable<AuctionWorksheetRpfItemResponseDto> {
    return this.apiRequestAuctionWorksheetSearchRpfItemPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetRpfItemResponseDto>): AuctionWorksheetRpfItemResponseDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetSearchVendorVenusPost()` */
  static readonly ApiRequestAuctionWorksheetSearchVendorVenusPostPath = '/api/request/auction-worksheet/SearchVendorVenus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetSearchVendorVenusPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetSearchVendorVenusPost$Response(params?: ApiRequestAuctionWorksheetSearchVendorVenusPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AuctionWorksheetInvitationDto>>> {
    return apiRequestAuctionWorksheetSearchVendorVenusPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetSearchVendorVenusPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetSearchVendorVenusPost(params?: ApiRequestAuctionWorksheetSearchVendorVenusPost$Params, context?: HttpContext): Observable<Array<AuctionWorksheetInvitationDto>> {
    return this.apiRequestAuctionWorksheetSearchVendorVenusPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AuctionWorksheetInvitationDto>>): Array<AuctionWorksheetInvitationDto> => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetCreateBuyerWorksheetPost()` */
  static readonly ApiRequestAuctionWorksheetCreateBuyerWorksheetPostPath = '/api/request/auction-worksheet/CreateBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetCreateBuyerWorksheetPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetCreateBuyerWorksheetPost$Response(params?: ApiRequestAuctionWorksheetCreateBuyerWorksheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetBuyerWorksheetResponseDto>> {
    return apiRequestAuctionWorksheetCreateBuyerWorksheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetCreateBuyerWorksheetPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetCreateBuyerWorksheetPost(params?: ApiRequestAuctionWorksheetCreateBuyerWorksheetPost$Params, context?: HttpContext): Observable<AuctionWorksheetBuyerWorksheetResponseDto> {
    return this.apiRequestAuctionWorksheetCreateBuyerWorksheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetBuyerWorksheetResponseDto>): AuctionWorksheetBuyerWorksheetResponseDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetCreateContractWorksheetPost()` */
  static readonly ApiRequestAuctionWorksheetCreateContractWorksheetPostPath = '/api/request/auction-worksheet/CreateContractWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetCreateContractWorksheetPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetCreateContractWorksheetPost$Response(params?: ApiRequestAuctionWorksheetCreateContractWorksheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetContractWorkhseetResponseDto>> {
    return apiRequestAuctionWorksheetCreateContractWorksheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetCreateContractWorksheetPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetCreateContractWorksheetPost(params?: ApiRequestAuctionWorksheetCreateContractWorksheetPost$Params, context?: HttpContext): Observable<AuctionWorksheetContractWorkhseetResponseDto> {
    return this.apiRequestAuctionWorksheetCreateContractWorksheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetContractWorkhseetResponseDto>): AuctionWorksheetContractWorkhseetResponseDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetGetBiddingPost()` */
  static readonly ApiRequestAuctionWorksheetGetBiddingPostPath = '/api/request/auction-worksheet/GetBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetGetBiddingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetGetBiddingPost$Response(params?: ApiRequestAuctionWorksheetGetBiddingPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetBiddingDto>> {
    return apiRequestAuctionWorksheetGetBiddingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetGetBiddingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetGetBiddingPost(params?: ApiRequestAuctionWorksheetGetBiddingPost$Params, context?: HttpContext): Observable<AuctionWorksheetBiddingDto> {
    return this.apiRequestAuctionWorksheetGetBiddingPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetBiddingDto>): AuctionWorksheetBiddingDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetExportBiddingPost()` */
  static readonly ApiRequestAuctionWorksheetExportBiddingPostPath = '/api/request/auction-worksheet/ExportBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetExportBiddingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetExportBiddingPost$Response(params?: ApiRequestAuctionWorksheetExportBiddingPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestAuctionWorksheetExportBiddingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetExportBiddingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetExportBiddingPost(params?: ApiRequestAuctionWorksheetExportBiddingPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestAuctionWorksheetExportBiddingPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetOpenBiddingPricePost()` */
  static readonly ApiRequestAuctionWorksheetOpenBiddingPricePostPath = '/api/request/auction-worksheet/OpenBiddingPrice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetOpenBiddingPricePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetOpenBiddingPricePost$Response(params?: ApiRequestAuctionWorksheetOpenBiddingPricePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetBiddingDtoResponseModel>> {
    return apiRequestAuctionWorksheetOpenBiddingPricePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetOpenBiddingPricePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetOpenBiddingPricePost(params?: ApiRequestAuctionWorksheetOpenBiddingPricePost$Params, context?: HttpContext): Observable<AuctionWorksheetBiddingDtoResponseModel> {
    return this.apiRequestAuctionWorksheetOpenBiddingPricePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetBiddingDtoResponseModel>): AuctionWorksheetBiddingDtoResponseModel => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetGetAgreementPdfPost()` */
  static readonly ApiRequestAuctionWorksheetGetAgreementPdfPostPath = '/api/request/auction-worksheet/GetAgreementPDF';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetGetAgreementPdfPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetGetAgreementPdfPost$Response(params?: ApiRequestAuctionWorksheetGetAgreementPdfPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestAuctionWorksheetGetAgreementPdfPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetGetAgreementPdfPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetGetAgreementPdfPost(params?: ApiRequestAuctionWorksheetGetAgreementPdfPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestAuctionWorksheetGetAgreementPdfPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetUploadVendorAttactmentPost()` */
  static readonly ApiRequestAuctionWorksheetUploadVendorAttactmentPostPath = '/api/request/auction-worksheet/UploadVendorAttactment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetUploadVendorAttactmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetUploadVendorAttactmentPost$Response(params?: ApiRequestAuctionWorksheetUploadVendorAttactmentPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>> {
    return apiRequestAuctionWorksheetUploadVendorAttactmentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetUploadVendorAttactmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetUploadVendorAttactmentPost(params?: ApiRequestAuctionWorksheetUploadVendorAttactmentPost$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetAttachmentDto>> {
    return this.apiRequestAuctionWorksheetUploadVendorAttactmentPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>): Array<VendorAuctionWorksheetAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetUploadAttactmentPost()` */
  static readonly ApiRequestAuctionWorksheetUploadAttactmentPostPath = '/api/request/auction-worksheet/UploadAttactment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetUploadAttactmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetUploadAttactmentPost$Response(params?: ApiRequestAuctionWorksheetUploadAttactmentPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<AuctionWorksheetFileDto>>> {
    return apiRequestAuctionWorksheetUploadAttactmentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetUploadAttactmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetUploadAttactmentPost(params?: ApiRequestAuctionWorksheetUploadAttactmentPost$Params, context?: HttpContext): Observable<Array<AuctionWorksheetFileDto>> {
    return this.apiRequestAuctionWorksheetUploadAttactmentPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<AuctionWorksheetFileDto>>): Array<AuctionWorksheetFileDto> => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetGetVendorAttachmentGet()` */
  static readonly ApiRequestAuctionWorksheetGetVendorAttachmentGetPath = '/api/request/auction-worksheet/GetVendorAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetGetVendorAttachmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetVendorAttachmentGet$Response(params?: ApiRequestAuctionWorksheetGetVendorAttachmentGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>> {
    return apiRequestAuctionWorksheetGetVendorAttachmentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetGetVendorAttachmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetGetVendorAttachmentGet(params?: ApiRequestAuctionWorksheetGetVendorAttachmentGet$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetAttachmentDto>> {
    return this.apiRequestAuctionWorksheetGetVendorAttachmentGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>): Array<VendorAuctionWorksheetAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetValidateGeneralEventItemPost()` */
  static readonly ApiRequestAuctionWorksheetValidateGeneralEventItemPostPath = '/api/request/auction-worksheet/ValidateGeneralEventItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetValidateGeneralEventItemPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetValidateGeneralEventItemPost$Response(params?: ApiRequestAuctionWorksheetValidateGeneralEventItemPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetGeneralEventItemValidateDto>> {
    return apiRequestAuctionWorksheetValidateGeneralEventItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetValidateGeneralEventItemPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestAuctionWorksheetValidateGeneralEventItemPost(params?: ApiRequestAuctionWorksheetValidateGeneralEventItemPost$Params, context?: HttpContext): Observable<AuctionWorksheetGeneralEventItemValidateDto> {
    return this.apiRequestAuctionWorksheetValidateGeneralEventItemPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<AuctionWorksheetGeneralEventItemValidateDto>): AuctionWorksheetGeneralEventItemValidateDto => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetResendPasswordPost()` */
  static readonly ApiRequestAuctionWorksheetResendPasswordPostPath = '/api/request/auction-worksheet/ResendPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetResendPasswordPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetResendPasswordPost$Response(params?: ApiRequestAuctionWorksheetResendPasswordPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BooleanNullableResponseModel>> {
    return apiRequestAuctionWorksheetResendPasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetResendPasswordPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetResendPasswordPost(params?: ApiRequestAuctionWorksheetResendPasswordPost$Params, context?: HttpContext): Observable<BooleanNullableResponseModel> {
    return this.apiRequestAuctionWorksheetResendPasswordPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<BooleanNullableResponseModel>): BooleanNullableResponseModel => r.body)
    );
  }

  /** Path part for operation `apiRequestAuctionWorksheetRevokeRejectedInvitationGet()` */
  static readonly ApiRequestAuctionWorksheetRevokeRejectedInvitationGetPath = '/api/request/auction-worksheet/RevokeRejectedInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestAuctionWorksheetRevokeRejectedInvitationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetRevokeRejectedInvitationGet$Response(params?: ApiRequestAuctionWorksheetRevokeRejectedInvitationGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestAuctionWorksheetRevokeRejectedInvitationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestAuctionWorksheetRevokeRejectedInvitationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestAuctionWorksheetRevokeRejectedInvitationGet(params?: ApiRequestAuctionWorksheetRevokeRejectedInvitationGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestAuctionWorksheetRevokeRejectedInvitationGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
