/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataAuctionBiddingAgreementActiveGet } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-active-get';
import { ApiMasterDataAuctionBiddingAgreementActiveGet$Params } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-active-get';
import { apiMasterDataAuctionBiddingAgreementAddPost } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-add-post';
import { ApiMasterDataAuctionBiddingAgreementAddPost$Params } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-add-post';
import { apiMasterDataAuctionBiddingAgreementAllGet } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-all-get';
import { ApiMasterDataAuctionBiddingAgreementAllGet$Params } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-all-get';
import { apiMasterDataAuctionBiddingAgreementDeleteGet } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-delete-get';
import { ApiMasterDataAuctionBiddingAgreementDeleteGet$Params } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-delete-get';
import { apiMasterDataAuctionBiddingAgreementEditPost } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-edit-post';
import { ApiMasterDataAuctionBiddingAgreementEditPost$Params } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-edit-post';
import { apiMasterDataAuctionBiddingAgreementMaintainGet } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-maintain-get';
import { ApiMasterDataAuctionBiddingAgreementMaintainGet$Params } from '../fn/master-auction-bidding-agreement/api-master-data-auction-bidding-agreement-maintain-get';
import { MasterAuctionBiddingAgreement } from '../models/master-auction-bidding-agreement';

@Injectable({ providedIn: 'root' })
export class MasterAuctionBiddingAgreementAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataAuctionBiddingAgreementActiveGet()` */
  static readonly ApiMasterDataAuctionBiddingAgreementActiveGetPath = '/api/MasterData/AuctionBiddingAgreement/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionBiddingAgreementActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementActiveGet$Response(params?: ApiMasterDataAuctionBiddingAgreementActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterAuctionBiddingAgreement>>> {
    return apiMasterDataAuctionBiddingAgreementActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionBiddingAgreementActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementActiveGet(params?: ApiMasterDataAuctionBiddingAgreementActiveGet$Params, context?: HttpContext): Observable<Array<MasterAuctionBiddingAgreement>> {
    return this.apiMasterDataAuctionBiddingAgreementActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterAuctionBiddingAgreement>>): Array<MasterAuctionBiddingAgreement> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionBiddingAgreementMaintainGet()` */
  static readonly ApiMasterDataAuctionBiddingAgreementMaintainGetPath = '/api/MasterData/AuctionBiddingAgreement/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionBiddingAgreementMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementMaintainGet$Response(params?: ApiMasterDataAuctionBiddingAgreementMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterAuctionBiddingAgreement>>> {
    return apiMasterDataAuctionBiddingAgreementMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionBiddingAgreementMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementMaintainGet(params?: ApiMasterDataAuctionBiddingAgreementMaintainGet$Params, context?: HttpContext): Observable<Array<MasterAuctionBiddingAgreement>> {
    return this.apiMasterDataAuctionBiddingAgreementMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterAuctionBiddingAgreement>>): Array<MasterAuctionBiddingAgreement> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionBiddingAgreementAllGet()` */
  static readonly ApiMasterDataAuctionBiddingAgreementAllGetPath = '/api/MasterData/AuctionBiddingAgreement/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionBiddingAgreementAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementAllGet$Response(params?: ApiMasterDataAuctionBiddingAgreementAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterAuctionBiddingAgreement>>> {
    return apiMasterDataAuctionBiddingAgreementAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionBiddingAgreementAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementAllGet(params?: ApiMasterDataAuctionBiddingAgreementAllGet$Params, context?: HttpContext): Observable<Array<MasterAuctionBiddingAgreement>> {
    return this.apiMasterDataAuctionBiddingAgreementAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterAuctionBiddingAgreement>>): Array<MasterAuctionBiddingAgreement> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionBiddingAgreementAddPost()` */
  static readonly ApiMasterDataAuctionBiddingAgreementAddPostPath = '/api/MasterData/AuctionBiddingAgreement/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionBiddingAgreementAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionBiddingAgreementAddPost$Response(params?: ApiMasterDataAuctionBiddingAgreementAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataAuctionBiddingAgreementAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionBiddingAgreementAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionBiddingAgreementAddPost(params?: ApiMasterDataAuctionBiddingAgreementAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataAuctionBiddingAgreementAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionBiddingAgreementEditPost()` */
  static readonly ApiMasterDataAuctionBiddingAgreementEditPostPath = '/api/MasterData/AuctionBiddingAgreement/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionBiddingAgreementEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionBiddingAgreementEditPost$Response(params?: ApiMasterDataAuctionBiddingAgreementEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataAuctionBiddingAgreementEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionBiddingAgreementEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataAuctionBiddingAgreementEditPost(params?: ApiMasterDataAuctionBiddingAgreementEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataAuctionBiddingAgreementEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataAuctionBiddingAgreementDeleteGet()` */
  static readonly ApiMasterDataAuctionBiddingAgreementDeleteGetPath = '/api/MasterData/AuctionBiddingAgreement/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataAuctionBiddingAgreementDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementDeleteGet$Response(params?: ApiMasterDataAuctionBiddingAgreementDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataAuctionBiddingAgreementDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataAuctionBiddingAgreementDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataAuctionBiddingAgreementDeleteGet(params?: ApiMasterDataAuctionBiddingAgreementDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataAuctionBiddingAgreementDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
