/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataCsmApproveActiveGet } from '../fn/master-csm-approve/api-master-data-csm-approve-active-get';
import { ApiMasterDataCsmApproveActiveGet$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-active-get';
import { apiMasterDataCsmApproveAddPost } from '../fn/master-csm-approve/api-master-data-csm-approve-add-post';
import { ApiMasterDataCsmApproveAddPost$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-add-post';
import { apiMasterDataCsmApproveAllGet } from '../fn/master-csm-approve/api-master-data-csm-approve-all-get';
import { ApiMasterDataCsmApproveAllGet$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-all-get';
import { apiMasterDataCsmApproveDeleteGet } from '../fn/master-csm-approve/api-master-data-csm-approve-delete-get';
import { ApiMasterDataCsmApproveDeleteGet$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-delete-get';
import { apiMasterDataCsmApproveEditPost } from '../fn/master-csm-approve/api-master-data-csm-approve-edit-post';
import { ApiMasterDataCsmApproveEditPost$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-edit-post';
import { apiMasterDataCsmApproveExportGet } from '../fn/master-csm-approve/api-master-data-csm-approve-export-get';
import { ApiMasterDataCsmApproveExportGet$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-export-get';
import { apiMasterDataCsmApproveImportPost } from '../fn/master-csm-approve/api-master-data-csm-approve-import-post';
import { ApiMasterDataCsmApproveImportPost$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-import-post';
import { apiMasterDataCsmApproveMaintainGet } from '../fn/master-csm-approve/api-master-data-csm-approve-maintain-get';
import { ApiMasterDataCsmApproveMaintainGet$Params } from '../fn/master-csm-approve/api-master-data-csm-approve-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterCsmItem } from '../models/master-csm-item';

@Injectable({ providedIn: 'root' })
export class MasterCsmApproveAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataCsmApproveActiveGet()` */
  static readonly ApiMasterDataCsmApproveActiveGetPath = '/api/MasterData/CSMApprove/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveActiveGet$Response(params?: ApiMasterDataCsmApproveActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCsmItem>>> {
    return apiMasterDataCsmApproveActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveActiveGet(params?: ApiMasterDataCsmApproveActiveGet$Params, context?: HttpContext): Observable<Array<MasterCsmItem>> {
    return this.apiMasterDataCsmApproveActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCsmItem>>): Array<MasterCsmItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveMaintainGet()` */
  static readonly ApiMasterDataCsmApproveMaintainGetPath = '/api/MasterData/CSMApprove/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveMaintainGet$Response(params?: ApiMasterDataCsmApproveMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCsmItem>>> {
    return apiMasterDataCsmApproveMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveMaintainGet(params?: ApiMasterDataCsmApproveMaintainGet$Params, context?: HttpContext): Observable<Array<MasterCsmItem>> {
    return this.apiMasterDataCsmApproveMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCsmItem>>): Array<MasterCsmItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveAllGet()` */
  static readonly ApiMasterDataCsmApproveAllGetPath = '/api/MasterData/CSMApprove/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveAllGet$Response(params?: ApiMasterDataCsmApproveAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCsmItem>>> {
    return apiMasterDataCsmApproveAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveAllGet(params?: ApiMasterDataCsmApproveAllGet$Params, context?: HttpContext): Observable<Array<MasterCsmItem>> {
    return this.apiMasterDataCsmApproveAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCsmItem>>): Array<MasterCsmItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveAddPost()` */
  static readonly ApiMasterDataCsmApproveAddPostPath = '/api/MasterData/CSMApprove/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCsmApproveAddPost$Response(params?: ApiMasterDataCsmApproveAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataCsmApproveAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCsmApproveAddPost(params?: ApiMasterDataCsmApproveAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataCsmApproveAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveEditPost()` */
  static readonly ApiMasterDataCsmApproveEditPostPath = '/api/MasterData/CSMApprove/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCsmApproveEditPost$Response(params?: ApiMasterDataCsmApproveEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataCsmApproveEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCsmApproveEditPost(params?: ApiMasterDataCsmApproveEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataCsmApproveEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveDeleteGet()` */
  static readonly ApiMasterDataCsmApproveDeleteGetPath = '/api/MasterData/CSMApprove/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveDeleteGet$Response(params?: ApiMasterDataCsmApproveDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataCsmApproveDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveDeleteGet(params?: ApiMasterDataCsmApproveDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataCsmApproveDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveImportPost()` */
  static readonly ApiMasterDataCsmApproveImportPostPath = '/api/MasterData/CSMApprove/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveImportPost$Response(params?: ApiMasterDataCsmApproveImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataCsmApproveImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveImportPost(params?: ApiMasterDataCsmApproveImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataCsmApproveImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCsmApproveExportGet()` */
  static readonly ApiMasterDataCsmApproveExportGetPath = '/api/MasterData/CSMApprove/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCsmApproveExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveExportGet$Response(params?: ApiMasterDataCsmApproveExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataCsmApproveExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCsmApproveExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCsmApproveExportGet(params?: ApiMasterDataCsmApproveExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataCsmApproveExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
