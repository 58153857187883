/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { BooleanNullableResponseModel } from '../../models/boolean-nullable-response-model';

export interface ApiRequestAuctionWorksheetResendPasswordPost$Params {
  auctionId?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestAuctionWorksheetResendPasswordPost(http: HttpClient, rootUrl: string, params?: ApiRequestAuctionWorksheetResendPasswordPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BooleanNullableResponseModel>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestAuctionWorksheetResendPasswordPost.PATH, 'post');
  if (params) {
    rb.query('auctionId', params.auctionId, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<BooleanNullableResponseModel>;
    })
  );
}

apiRequestAuctionWorksheetResendPasswordPost.PATH = '/api/request/auction-worksheet/ResendPassword';
