/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiAmazonS3CreateBucketPost } from '../fn/amazon-s-3/api-amazon-s-3-create-bucket-post';
import { ApiAmazonS3CreateBucketPost$Params } from '../fn/amazon-s-3/api-amazon-s-3-create-bucket-post';
import { apiAmazonS3DownloadObjectFromBucketGet } from '../fn/amazon-s-3/api-amazon-s-3-download-object-from-bucket-get';
import { ApiAmazonS3DownloadObjectFromBucketGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-download-object-from-bucket-get';
import { apiAmazonS3DownloadObjectGet } from '../fn/amazon-s-3/api-amazon-s-3-download-object-get';
import { ApiAmazonS3DownloadObjectGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-download-object-get';
import { apiAmazonS3GetBucketsGet } from '../fn/amazon-s-3/api-amazon-s-3-get-buckets-get';
import { ApiAmazonS3GetBucketsGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-get-buckets-get';
import { apiAmazonS3GetFileLinkFromPathGet } from '../fn/amazon-s-3/api-amazon-s-3-get-file-link-from-path-get';
import { ApiAmazonS3GetFileLinkFromPathGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-get-file-link-from-path-get';
import { apiAmazonS3GetObjectListWithAllVersionsGet } from '../fn/amazon-s-3/api-amazon-s-3-get-object-list-with-all-versions-get';
import { ApiAmazonS3GetObjectListWithAllVersionsGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-get-object-list-with-all-versions-get';
import { apiAmazonS3ListBucketContentsGet } from '../fn/amazon-s-3/api-amazon-s-3-list-bucket-contents-get';
import { ApiAmazonS3ListBucketContentsGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-list-bucket-contents-get';
import { apiAmazonS3RetrieveCorsConfigurationGet } from '../fn/amazon-s-3/api-amazon-s-3-retrieve-cors-configuration-get';
import { ApiAmazonS3RetrieveCorsConfigurationGet$Params } from '../fn/amazon-s-3/api-amazon-s-3-retrieve-cors-configuration-get';
import { CorsConfiguration } from '../models/cors-configuration';
import { FileCommonModel } from '../models/file-common-model';
import { ListBucketsResponse } from '../models/list-buckets-response';
import { ListObjectsV2Response } from '../models/list-objects-v-2-response';

@Injectable({ providedIn: 'root' })
export class AmazonS3AzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAmazonS3GetBucketsGet()` */
  static readonly ApiAmazonS3GetBucketsGetPath = '/api/AmazonS3/GetBuckets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3GetBucketsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3GetBucketsGet$Response(params?: ApiAmazonS3GetBucketsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ListBucketsResponse>> {
    return apiAmazonS3GetBucketsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3GetBucketsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3GetBucketsGet(params?: ApiAmazonS3GetBucketsGet$Params, context?: HttpContext): Observable<ListBucketsResponse> {
    return this.apiAmazonS3GetBucketsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ListBucketsResponse>): ListBucketsResponse => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3CreateBucketPost()` */
  static readonly ApiAmazonS3CreateBucketPostPath = '/api/AmazonS3/CreateBucket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3CreateBucketPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3CreateBucketPost$Response(params?: ApiAmazonS3CreateBucketPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<boolean>> {
    return apiAmazonS3CreateBucketPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3CreateBucketPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3CreateBucketPost(params?: ApiAmazonS3CreateBucketPost$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAmazonS3CreateBucketPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3RetrieveCorsConfigurationGet()` */
  static readonly ApiAmazonS3RetrieveCorsConfigurationGetPath = '/api/AmazonS3/RetrieveCORSConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3RetrieveCorsConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3RetrieveCorsConfigurationGet$Response(params?: ApiAmazonS3RetrieveCorsConfigurationGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<CorsConfiguration>> {
    return apiAmazonS3RetrieveCorsConfigurationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3RetrieveCorsConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3RetrieveCorsConfigurationGet(params?: ApiAmazonS3RetrieveCorsConfigurationGet$Params, context?: HttpContext): Observable<CorsConfiguration> {
    return this.apiAmazonS3RetrieveCorsConfigurationGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<CorsConfiguration>): CorsConfiguration => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3ListBucketContentsGet()` */
  static readonly ApiAmazonS3ListBucketContentsGetPath = '/api/AmazonS3/ListBucketContents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3ListBucketContentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3ListBucketContentsGet$Response(params?: ApiAmazonS3ListBucketContentsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ListObjectsV2Response>> {
    return apiAmazonS3ListBucketContentsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3ListBucketContentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3ListBucketContentsGet(params?: ApiAmazonS3ListBucketContentsGet$Params, context?: HttpContext): Observable<ListObjectsV2Response> {
    return this.apiAmazonS3ListBucketContentsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ListObjectsV2Response>): ListObjectsV2Response => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3GetObjectListWithAllVersionsGet()` */
  static readonly ApiAmazonS3GetObjectListWithAllVersionsGetPath = '/api/AmazonS3/GetObjectListWithAllVersions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3GetObjectListWithAllVersionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3GetObjectListWithAllVersionsGet$Response(params?: ApiAmazonS3GetObjectListWithAllVersionsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<boolean>> {
    return apiAmazonS3GetObjectListWithAllVersionsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3GetObjectListWithAllVersionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3GetObjectListWithAllVersionsGet(params?: ApiAmazonS3GetObjectListWithAllVersionsGet$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAmazonS3GetObjectListWithAllVersionsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3DownloadObjectFromBucketGet()` */
  static readonly ApiAmazonS3DownloadObjectFromBucketGetPath = '/api/AmazonS3/DownloadObjectFromBucket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3DownloadObjectFromBucketGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3DownloadObjectFromBucketGet$Response(params?: ApiAmazonS3DownloadObjectFromBucketGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiAmazonS3DownloadObjectFromBucketGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3DownloadObjectFromBucketGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3DownloadObjectFromBucketGet(params?: ApiAmazonS3DownloadObjectFromBucketGet$Params, context?: HttpContext): Observable<string> {
    return this.apiAmazonS3DownloadObjectFromBucketGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3DownloadObjectGet()` */
  static readonly ApiAmazonS3DownloadObjectGetPath = '/api/AmazonS3/DownloadObject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3DownloadObjectGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3DownloadObjectGet$Response(params?: ApiAmazonS3DownloadObjectGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<FileCommonModel>> {
    return apiAmazonS3DownloadObjectGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3DownloadObjectGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3DownloadObjectGet(params?: ApiAmazonS3DownloadObjectGet$Params, context?: HttpContext): Observable<FileCommonModel> {
    return this.apiAmazonS3DownloadObjectGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<FileCommonModel>): FileCommonModel => r.body)
    );
  }

  /** Path part for operation `apiAmazonS3GetFileLinkFromPathGet()` */
  static readonly ApiAmazonS3GetFileLinkFromPathGetPath = '/api/AmazonS3/GetFileLinkFromPath';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAmazonS3GetFileLinkFromPathGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3GetFileLinkFromPathGet$Response(params?: ApiAmazonS3GetFileLinkFromPathGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiAmazonS3GetFileLinkFromPathGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAmazonS3GetFileLinkFromPathGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAmazonS3GetFileLinkFromPathGet(params?: ApiAmazonS3GetFileLinkFromPathGet$Params, context?: HttpContext): Observable<string> {
    return this.apiAmazonS3GetFileLinkFromPathGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

}
