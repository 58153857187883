/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestSynergyRfqCancelSynergyGet } from '../fn/synergy-rfq/api-request-synergy-rfq-cancel-synergy-get';
import { ApiRequestSynergyRfqCancelSynergyGet$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-cancel-synergy-get';
import { apiRequestSynergyRfqInitialItemDailyInfoItemPost } from '../fn/synergy-rfq/api-request-synergy-rfq-initial-item-daily-info-item-post';
import { ApiRequestSynergyRfqInitialItemDailyInfoItemPost$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-initial-item-daily-info-item-post';
import { apiRequestSynergyRfqItemGet } from '../fn/synergy-rfq/api-request-synergy-rfq-item-get';
import { ApiRequestSynergyRfqItemGet$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-item-get';
import { apiRequestSynergyRfqMockApiGet } from '../fn/synergy-rfq/api-request-synergy-rfq-mock-api-get';
import { ApiRequestSynergyRfqMockApiGet$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-mock-api-get';
import { apiRequestSynergyRfqPreviewEmailNegotiationPost } from '../fn/synergy-rfq/api-request-synergy-rfq-preview-email-negotiation-post';
import { ApiRequestSynergyRfqPreviewEmailNegotiationPost$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-preview-email-negotiation-post';
import { apiRequestSynergyRfqSavePost } from '../fn/synergy-rfq/api-request-synergy-rfq-save-post';
import { ApiRequestSynergyRfqSavePost$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-save-post';
import { apiRequestSynergyRfqSendEmailNegotiationPost } from '../fn/synergy-rfq/api-request-synergy-rfq-send-email-negotiation-post';
import { ApiRequestSynergyRfqSendEmailNegotiationPost$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-send-email-negotiation-post';
import { apiRequestSynergyRfqSubmitPost } from '../fn/synergy-rfq/api-request-synergy-rfq-submit-post';
import { ApiRequestSynergyRfqSubmitPost$Params } from '../fn/synergy-rfq/api-request-synergy-rfq-submit-post';
import { SynergyEmailNotifyItem } from '../models/synergy-email-notify-item';
import { SynergySummaryItem } from '../models/synergy-summary-item';
import { SynergyTransactionItem } from '../models/synergy-transaction-item';

@Injectable({ providedIn: 'root' })
export class SynergyRfqAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestSynergyRfqInitialItemDailyInfoItemPost()` */
  static readonly ApiRequestSynergyRfqInitialItemDailyInfoItemPostPath = '/api/request/synergy-rfq/InitialItemDailyInfoItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqInitialItemDailyInfoItemPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqInitialItemDailyInfoItemPost$Response(params?: ApiRequestSynergyRfqInitialItemDailyInfoItemPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<SynergyTransactionItem>> {
    return apiRequestSynergyRfqInitialItemDailyInfoItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqInitialItemDailyInfoItemPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqInitialItemDailyInfoItemPost(params?: ApiRequestSynergyRfqInitialItemDailyInfoItemPost$Params, context?: HttpContext): Observable<SynergyTransactionItem> {
    return this.apiRequestSynergyRfqInitialItemDailyInfoItemPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<SynergyTransactionItem>): SynergyTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqItemGet()` */
  static readonly ApiRequestSynergyRfqItemGetPath = '/api/request/synergy-rfq/Item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestSynergyRfqItemGet$Response(params?: ApiRequestSynergyRfqItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<SynergyTransactionItem>> {
    return apiRequestSynergyRfqItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestSynergyRfqItemGet(params?: ApiRequestSynergyRfqItemGet$Params, context?: HttpContext): Observable<SynergyTransactionItem> {
    return this.apiRequestSynergyRfqItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<SynergyTransactionItem>): SynergyTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqSavePost()` */
  static readonly ApiRequestSynergyRfqSavePostPath = '/api/request/synergy-rfq/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqSavePost$Response(params?: ApiRequestSynergyRfqSavePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<SynergyTransactionItem>> {
    return apiRequestSynergyRfqSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqSavePost(params?: ApiRequestSynergyRfqSavePost$Params, context?: HttpContext): Observable<SynergyTransactionItem> {
    return this.apiRequestSynergyRfqSavePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<SynergyTransactionItem>): SynergyTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqSubmitPost()` */
  static readonly ApiRequestSynergyRfqSubmitPostPath = '/api/request/synergy-rfq/Submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqSubmitPost$Response(params?: ApiRequestSynergyRfqSubmitPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<SynergyTransactionItem>> {
    return apiRequestSynergyRfqSubmitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqSubmitPost(params?: ApiRequestSynergyRfqSubmitPost$Params, context?: HttpContext): Observable<SynergyTransactionItem> {
    return this.apiRequestSynergyRfqSubmitPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<SynergyTransactionItem>): SynergyTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqCancelSynergyGet()` */
  static readonly ApiRequestSynergyRfqCancelSynergyGetPath = '/api/request/synergy-rfq/CancelSynergy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqCancelSynergyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestSynergyRfqCancelSynergyGet$Response(params?: ApiRequestSynergyRfqCancelSynergyGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<SynergyTransactionItem>> {
    return apiRequestSynergyRfqCancelSynergyGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqCancelSynergyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestSynergyRfqCancelSynergyGet(params?: ApiRequestSynergyRfqCancelSynergyGet$Params, context?: HttpContext): Observable<SynergyTransactionItem> {
    return this.apiRequestSynergyRfqCancelSynergyGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<SynergyTransactionItem>): SynergyTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqPreviewEmailNegotiationPost()` */
  static readonly ApiRequestSynergyRfqPreviewEmailNegotiationPostPath = '/api/request/synergy-rfq/PreviewEmailNegotiation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqPreviewEmailNegotiationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqPreviewEmailNegotiationPost$Response(params?: ApiRequestSynergyRfqPreviewEmailNegotiationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<SynergyEmailNotifyItem>>> {
    return apiRequestSynergyRfqPreviewEmailNegotiationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqPreviewEmailNegotiationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqPreviewEmailNegotiationPost(params?: ApiRequestSynergyRfqPreviewEmailNegotiationPost$Params, context?: HttpContext): Observable<Array<SynergyEmailNotifyItem>> {
    return this.apiRequestSynergyRfqPreviewEmailNegotiationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<SynergyEmailNotifyItem>>): Array<SynergyEmailNotifyItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqSendEmailNegotiationPost()` */
  static readonly ApiRequestSynergyRfqSendEmailNegotiationPostPath = '/api/request/synergy-rfq/SendEmailNegotiation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqSendEmailNegotiationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqSendEmailNegotiationPost$Response(params?: ApiRequestSynergyRfqSendEmailNegotiationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<SynergyEmailNotifyItem>>> {
    return apiRequestSynergyRfqSendEmailNegotiationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqSendEmailNegotiationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestSynergyRfqSendEmailNegotiationPost(params?: ApiRequestSynergyRfqSendEmailNegotiationPost$Params, context?: HttpContext): Observable<Array<SynergyEmailNotifyItem>> {
    return this.apiRequestSynergyRfqSendEmailNegotiationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<SynergyEmailNotifyItem>>): Array<SynergyEmailNotifyItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestSynergyRfqMockApiGet()` */
  static readonly ApiRequestSynergyRfqMockApiGetPath = '/api/request/synergy-rfq/MockAPI';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestSynergyRfqMockApiGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestSynergyRfqMockApiGet$Response(params?: ApiRequestSynergyRfqMockApiGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<SynergySummaryItem>> {
    return apiRequestSynergyRfqMockApiGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestSynergyRfqMockApiGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestSynergyRfqMockApiGet(params?: ApiRequestSynergyRfqMockApiGet$Params, context?: HttpContext): Observable<SynergySummaryItem> {
    return this.apiRequestSynergyRfqMockApiGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<SynergySummaryItem>): SynergySummaryItem => r.body)
    );
  }

}
