export const encodeQueryParams = (obj: any): string => {
  let json: string = JSON.stringify(obj);
  let encode: string = btoa(json);
  return encodeURIComponent(encode);
}

export const decodeQueryParams = (str: string): any => {
  let decodeURI: string = decodeURIComponent(str);
  let decode: string = atob(decodeURI);
  return JSON.parse(decode);
}
