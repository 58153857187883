/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestBuyerWorksheetCalImportDutyPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-cal-import-duty-post';
import { ApiRequestBuyerWorksheetCalImportDutyPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-cal-import-duty-post';
import { apiRequestBuyerWorksheetCancelGet } from '../fn/buyer-worksheet/api-request-buyer-worksheet-cancel-get';
import { ApiRequestBuyerWorksheetCancelGet$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-cancel-get';
import { apiRequestBuyerWorksheetExportBomPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-export-bom-post';
import { ApiRequestBuyerWorksheetExportBomPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-export-bom-post';
import { apiRequestBuyerWorksheetGetBiddingEvaluationFormGet } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-bidding-evaluation-form-get';
import { ApiRequestBuyerWorksheetGetBiddingEvaluationFormGet$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-bidding-evaluation-form-get';
import { apiRequestBuyerWorksheetGetGet } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-get';
import { ApiRequestBuyerWorksheetGetGet$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-get';
import { apiRequestBuyerWorksheetGetIdGet } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-id-get';
import { ApiRequestBuyerWorksheetGetIdGet$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-id-get';
import { apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-reference-detail-when-change-po-currency-post';
import { ApiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-reference-detail-when-change-po-currency-post';
import { apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-reference-detail-when-change-vendor-post';
import { ApiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-reference-detail-when-change-vendor-post';
import { apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-ref-pr-detail-for-buyer-worksheet-post';
import { ApiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-ref-pr-detail-for-buyer-worksheet-post';
import { apiRequestBuyerWorksheetGetRefPrDetailPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-ref-pr-detail-post';
import { ApiRequestBuyerWorksheetGetRefPrDetailPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-get-ref-pr-detail-post';
import { apiRequestBuyerWorksheetImportBomPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-import-bom-post';
import { ApiRequestBuyerWorksheetImportBomPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-import-bom-post';
import { apiRequestBuyerWorksheetSavePost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-save-post';
import { ApiRequestBuyerWorksheetSavePost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-save-post';
import { apiRequestBuyerWorksheetSetDefaultValueByIncotermPost } from '../fn/buyer-worksheet/api-request-buyer-worksheet-set-default-value-by-incoterm-post';
import { ApiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Params } from '../fn/buyer-worksheet/api-request-buyer-worksheet-set-default-value-by-incoterm-post';
import { BuyerWorksheetBomItem } from '../models/buyer-worksheet-bom-item';
import { BuyerWorksheetItem } from '../models/buyer-worksheet-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { InterfacePoDetailForTheArch } from '../models/interface-po-detail-for-the-arch';
import { VendorCommercialBidEvaluationItem } from '../models/vendor-commercial-bid-evaluation-item';

@Injectable({ providedIn: 'root' })
export class BuyerWorksheetAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetGet()` */
  static readonly ApiRequestBuyerWorksheetGetGetPath = '/api/request/buyer-worksheet/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetGetGet$Response(params?: ApiRequestBuyerWorksheetGetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BuyerWorksheetItem>> {
    return apiRequestBuyerWorksheetGetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetGetGet(params?: ApiRequestBuyerWorksheetGetGet$Params, context?: HttpContext): Observable<BuyerWorksheetItem> {
    return this.apiRequestBuyerWorksheetGetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<BuyerWorksheetItem>): BuyerWorksheetItem => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetIdGet()` */
  static readonly ApiRequestBuyerWorksheetGetIdGetPath = '/api/request/buyer-worksheet/GetId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetGetIdGet$Response(params?: ApiRequestBuyerWorksheetGetIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiRequestBuyerWorksheetGetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetGetIdGet(params?: ApiRequestBuyerWorksheetGetIdGet$Params, context?: HttpContext): Observable<string> {
    return this.apiRequestBuyerWorksheetGetIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetSavePost()` */
  static readonly ApiRequestBuyerWorksheetSavePostPath = '/api/request/buyer-worksheet/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetSavePost$Response(params?: ApiRequestBuyerWorksheetSavePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestBuyerWorksheetSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetSavePost(params?: ApiRequestBuyerWorksheetSavePost$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestBuyerWorksheetSavePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost()` */
  static readonly ApiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPostPath = '/api/request/buyer-worksheet/GetReferenceDetailWhenChangePOCurrency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost$Response(params?: ApiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BuyerWorksheetItem>> {
    return apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost(params?: ApiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost$Params, context?: HttpContext): Observable<BuyerWorksheetItem> {
    return this.apiRequestBuyerWorksheetGetReferenceDetailWhenChangePoCurrencyPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<BuyerWorksheetItem>): BuyerWorksheetItem => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost()` */
  static readonly ApiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPostPath = '/api/request/buyer-worksheet/GetReferenceDetailWhenChangeVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost$Response(params?: ApiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BuyerWorksheetItem>> {
    return apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost(params?: ApiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost$Params, context?: HttpContext): Observable<BuyerWorksheetItem> {
    return this.apiRequestBuyerWorksheetGetReferenceDetailWhenChangeVendorPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<BuyerWorksheetItem>): BuyerWorksheetItem => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetRefPrDetailPost()` */
  static readonly ApiRequestBuyerWorksheetGetRefPrDetailPostPath = '/api/request/buyer-worksheet/GetRefPRDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetRefPrDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetRefPrDetailPost$Response(params?: ApiRequestBuyerWorksheetGetRefPrDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<InterfacePoDetailForTheArch>>> {
    return apiRequestBuyerWorksheetGetRefPrDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetRefPrDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetRefPrDetailPost(params?: ApiRequestBuyerWorksheetGetRefPrDetailPost$Params, context?: HttpContext): Observable<Array<InterfacePoDetailForTheArch>> {
    return this.apiRequestBuyerWorksheetGetRefPrDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<InterfacePoDetailForTheArch>>): Array<InterfacePoDetailForTheArch> => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost()` */
  static readonly ApiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPostPath = '/api/request/buyer-worksheet/GetRefPRDetailForBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost$Response(params?: ApiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<InterfacePoDetailForTheArch>>> {
    return apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost(params?: ApiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost$Params, context?: HttpContext): Observable<Array<InterfacePoDetailForTheArch>> {
    return this.apiRequestBuyerWorksheetGetRefPrDetailForBuyerWorksheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<InterfacePoDetailForTheArch>>): Array<InterfacePoDetailForTheArch> => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetCancelGet()` */
  static readonly ApiRequestBuyerWorksheetCancelGetPath = '/api/request/buyer-worksheet/Cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetCancelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetCancelGet$Response(params?: ApiRequestBuyerWorksheetCancelGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestBuyerWorksheetCancelGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetCancelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetCancelGet(params?: ApiRequestBuyerWorksheetCancelGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestBuyerWorksheetCancelGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetImportBomPost()` */
  static readonly ApiRequestBuyerWorksheetImportBomPostPath = '/api/request/buyer-worksheet/ImportBOM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetImportBomPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetImportBomPost$Response(params?: ApiRequestBuyerWorksheetImportBomPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<BuyerWorksheetBomItem>>> {
    return apiRequestBuyerWorksheetImportBomPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetImportBomPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetImportBomPost(params?: ApiRequestBuyerWorksheetImportBomPost$Params, context?: HttpContext): Observable<Array<BuyerWorksheetBomItem>> {
    return this.apiRequestBuyerWorksheetImportBomPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<BuyerWorksheetBomItem>>): Array<BuyerWorksheetBomItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetExportBomPost()` */
  static readonly ApiRequestBuyerWorksheetExportBomPostPath = '/api/request/buyer-worksheet/ExportBOM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetExportBomPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetExportBomPost$Response(params?: ApiRequestBuyerWorksheetExportBomPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestBuyerWorksheetExportBomPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetExportBomPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetExportBomPost(params?: ApiRequestBuyerWorksheetExportBomPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestBuyerWorksheetExportBomPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetGetBiddingEvaluationFormGet()` */
  static readonly ApiRequestBuyerWorksheetGetBiddingEvaluationFormGetPath = '/api/request/buyer-worksheet/GetBiddingEvaluationForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetGetBiddingEvaluationFormGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetGetBiddingEvaluationFormGet$Response(params?: ApiRequestBuyerWorksheetGetBiddingEvaluationFormGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestBuyerWorksheetGetBiddingEvaluationFormGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetGetBiddingEvaluationFormGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestBuyerWorksheetGetBiddingEvaluationFormGet(params?: ApiRequestBuyerWorksheetGetBiddingEvaluationFormGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestBuyerWorksheetGetBiddingEvaluationFormGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetCalImportDutyPost()` */
  static readonly ApiRequestBuyerWorksheetCalImportDutyPostPath = '/api/request/buyer-worksheet/CalImportDuty';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetCalImportDutyPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetCalImportDutyPost$Response(params?: ApiRequestBuyerWorksheetCalImportDutyPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<number>> {
    return apiRequestBuyerWorksheetCalImportDutyPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetCalImportDutyPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetCalImportDutyPost(params?: ApiRequestBuyerWorksheetCalImportDutyPost$Params, context?: HttpContext): Observable<number> {
    return this.apiRequestBuyerWorksheetCalImportDutyPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `apiRequestBuyerWorksheetSetDefaultValueByIncotermPost()` */
  static readonly ApiRequestBuyerWorksheetSetDefaultValueByIncotermPostPath = '/api/request/buyer-worksheet/SetDefaultValueByIncoterm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestBuyerWorksheetSetDefaultValueByIncotermPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Response(params?: ApiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<VendorCommercialBidEvaluationItem>> {
    return apiRequestBuyerWorksheetSetDefaultValueByIncotermPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestBuyerWorksheetSetDefaultValueByIncotermPost(params?: ApiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Params, context?: HttpContext): Observable<VendorCommercialBidEvaluationItem> {
    return this.apiRequestBuyerWorksheetSetDefaultValueByIncotermPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<VendorCommercialBidEvaluationItem>): VendorCommercialBidEvaluationItem => r.body)
    );
  }

}
