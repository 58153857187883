import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
import { environment } from "../../environments/environment";
import { includes, replace } from "lodash";
import { SpinnerService } from "../services/spinner.service";
import { apiAzureApiBaseUrlGetter } from "../commons/global-config";

@Injectable()
export class SpinInterceptor implements HttpInterceptor {

  private ignoreUrls: string[] = [
    '/api/dashboard/RfqTaskChangeList',
    '/api/dashboard/POCreationTaskList',
    '/api/rfqTaskInfo/GetTaskInfos',
    '/api/rfqTaskInfo/GetRfqTaskLiveLogs',
    '/api/request/auction-worksheet/GetBidding',
    '/api/request/auction-worksheet/GetVendorAttachment',
    '/api/request/buyer-worksheet/CalImportDuty'
  ];

  constructor(private spin: SpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let uuid: string | null = null;
    const urlAzure = replace(request.url, apiAzureApiBaseUrlGetter(), "");
    const ignore = this.ignoreUrls.some(x => urlAzure.includes(x) || request.url?.includes(x));
    if (!ignore) {
      uuid = uuidv4();
      this.spin.add(uuid);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!ignore && uuid) {
          this.spin.stop(uuid);
        }
      })
    );
  }
}
