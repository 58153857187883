/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiDashboardCancelPoCreationPost } from '../fn/dashboard/api-dashboard-cancel-po-creation-post';
import { ApiDashboardCancelPoCreationPost$Params } from '../fn/dashboard/api-dashboard-cancel-po-creation-post';
import { apiDashboardCancelRfqTaskChangePost } from '../fn/dashboard/api-dashboard-cancel-rfq-task-change-post';
import { ApiDashboardCancelRfqTaskChangePost$Params } from '../fn/dashboard/api-dashboard-cancel-rfq-task-change-post';
import { apiDashboardPoCreationTaskListPost } from '../fn/dashboard/api-dashboard-po-creation-task-list-post';
import { ApiDashboardPoCreationTaskListPost$Params } from '../fn/dashboard/api-dashboard-po-creation-task-list-post';
import { apiDashboardRetryPoCreationPost } from '../fn/dashboard/api-dashboard-retry-po-creation-post';
import { ApiDashboardRetryPoCreationPost$Params } from '../fn/dashboard/api-dashboard-retry-po-creation-post';
import { apiDashboardRetryRfqTaskChangePost } from '../fn/dashboard/api-dashboard-retry-rfq-task-change-post';
import { ApiDashboardRetryRfqTaskChangePost$Params } from '../fn/dashboard/api-dashboard-retry-rfq-task-change-post';
import { apiDashboardRfqTaskChangeListPost } from '../fn/dashboard/api-dashboard-rfq-task-change-list-post';
import { ApiDashboardRfqTaskChangeListPost$Params } from '../fn/dashboard/api-dashboard-rfq-task-change-list-post';
import { PoCreationTaskListResultItem } from '../models/po-creation-task-list-result-item';
import { RfqTaskChangeResultItem } from '../models/rfq-task-change-result-item';

@Injectable({ providedIn: 'root' })
export class DashboardAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiDashboardRfqTaskChangeListPost()` */
  static readonly ApiDashboardRfqTaskChangeListPostPath = '/api/dashboard/RfqTaskChangeList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardRfqTaskChangeListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardRfqTaskChangeListPost$Response(params?: ApiDashboardRfqTaskChangeListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RfqTaskChangeResultItem>>> {
    return apiDashboardRfqTaskChangeListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardRfqTaskChangeListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardRfqTaskChangeListPost(params?: ApiDashboardRfqTaskChangeListPost$Params, context?: HttpContext): Observable<Array<RfqTaskChangeResultItem>> {
    return this.apiDashboardRfqTaskChangeListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RfqTaskChangeResultItem>>): Array<RfqTaskChangeResultItem> => r.body)
    );
  }

  /** Path part for operation `apiDashboardRetryRfqTaskChangePost()` */
  static readonly ApiDashboardRetryRfqTaskChangePostPath = '/api/dashboard/RetryRfqTaskChange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardRetryRfqTaskChangePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardRetryRfqTaskChangePost$Response(params?: ApiDashboardRetryRfqTaskChangePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDashboardRetryRfqTaskChangePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardRetryRfqTaskChangePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardRetryRfqTaskChangePost(params?: ApiDashboardRetryRfqTaskChangePost$Params, context?: HttpContext): Observable<void> {
    return this.apiDashboardRetryRfqTaskChangePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDashboardCancelRfqTaskChangePost()` */
  static readonly ApiDashboardCancelRfqTaskChangePostPath = '/api/dashboard/CancelRfqTaskChange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardCancelRfqTaskChangePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardCancelRfqTaskChangePost$Response(params?: ApiDashboardCancelRfqTaskChangePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDashboardCancelRfqTaskChangePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardCancelRfqTaskChangePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardCancelRfqTaskChangePost(params?: ApiDashboardCancelRfqTaskChangePost$Params, context?: HttpContext): Observable<void> {
    return this.apiDashboardCancelRfqTaskChangePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDashboardPoCreationTaskListPost()` */
  static readonly ApiDashboardPoCreationTaskListPostPath = '/api/dashboard/POCreationTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardPoCreationTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardPoCreationTaskListPost$Response(params?: ApiDashboardPoCreationTaskListPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<PoCreationTaskListResultItem>>> {
    return apiDashboardPoCreationTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardPoCreationTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardPoCreationTaskListPost(params?: ApiDashboardPoCreationTaskListPost$Params, context?: HttpContext): Observable<Array<PoCreationTaskListResultItem>> {
    return this.apiDashboardPoCreationTaskListPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<PoCreationTaskListResultItem>>): Array<PoCreationTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiDashboardRetryPoCreationPost()` */
  static readonly ApiDashboardRetryPoCreationPostPath = '/api/dashboard/RetryPOCreation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardRetryPoCreationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardRetryPoCreationPost$Response(params?: ApiDashboardRetryPoCreationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDashboardRetryPoCreationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardRetryPoCreationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardRetryPoCreationPost(params?: ApiDashboardRetryPoCreationPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDashboardRetryPoCreationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiDashboardCancelPoCreationPost()` */
  static readonly ApiDashboardCancelPoCreationPostPath = '/api/dashboard/CancelPOCreation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardCancelPoCreationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardCancelPoCreationPost$Response(params?: ApiDashboardCancelPoCreationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiDashboardCancelPoCreationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardCancelPoCreationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDashboardCancelPoCreationPost(params?: ApiDashboardCancelPoCreationPost$Params, context?: HttpContext): Observable<void> {
    return this.apiDashboardCancelPoCreationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
