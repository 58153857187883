/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { AuctionWorksheetDto } from '../../models/auction-worksheet-dto';
import { AuctionWorksheetGeneralEventItemValidateDto } from '../../models/auction-worksheet-general-event-item-validate-dto';

export interface ApiRequestAuctionWorksheetValidateGeneralEventItemPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: AuctionWorksheetDto
}

export function apiRequestAuctionWorksheetValidateGeneralEventItemPost(http: HttpClient, rootUrl: string, params?: ApiRequestAuctionWorksheetValidateGeneralEventItemPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<AuctionWorksheetGeneralEventItemValidateDto>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestAuctionWorksheetValidateGeneralEventItemPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<AuctionWorksheetGeneralEventItemValidateDto>;
    })
  );
}

apiRequestAuctionWorksheetValidateGeneralEventItemPost.PATH = '/api/request/auction-worksheet/ValidateGeneralEventItem';
