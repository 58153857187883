/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataFtaActiveGet } from '../fn/master-fta/api-master-data-fta-active-get';
import { ApiMasterDataFtaActiveGet$Params } from '../fn/master-fta/api-master-data-fta-active-get';
import { apiMasterDataFtaAddPost } from '../fn/master-fta/api-master-data-fta-add-post';
import { ApiMasterDataFtaAddPost$Params } from '../fn/master-fta/api-master-data-fta-add-post';
import { apiMasterDataFtaAllGet } from '../fn/master-fta/api-master-data-fta-all-get';
import { ApiMasterDataFtaAllGet$Params } from '../fn/master-fta/api-master-data-fta-all-get';
import { apiMasterDataFtaDeleteGet } from '../fn/master-fta/api-master-data-fta-delete-get';
import { ApiMasterDataFtaDeleteGet$Params } from '../fn/master-fta/api-master-data-fta-delete-get';
import { apiMasterDataFtaEditPost } from '../fn/master-fta/api-master-data-fta-edit-post';
import { ApiMasterDataFtaEditPost$Params } from '../fn/master-fta/api-master-data-fta-edit-post';
import { apiMasterDataFtaMaintainGet } from '../fn/master-fta/api-master-data-fta-maintain-get';
import { ApiMasterDataFtaMaintainGet$Params } from '../fn/master-fta/api-master-data-fta-maintain-get';
import { apiMasterDataFtaPrivilegesForBuyerWorkSheetGet } from '../fn/master-fta/api-master-data-fta-privileges-for-buyer-work-sheet-get';
import { ApiMasterDataFtaPrivilegesForBuyerWorkSheetGet$Params } from '../fn/master-fta/api-master-data-fta-privileges-for-buyer-work-sheet-get';
import { apiMasterDataFtaPrivilegesGet } from '../fn/master-fta/api-master-data-fta-privileges-get';
import { ApiMasterDataFtaPrivilegesGet$Params } from '../fn/master-fta/api-master-data-fta-privileges-get';
import { MasterFtaItem } from '../models/master-fta-item';

@Injectable({ providedIn: 'root' })
export class MasterFtaAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataFtaPrivilegesGet()` */
  static readonly ApiMasterDataFtaPrivilegesGetPath = '/api/MasterData/FTA/Privileges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaPrivilegesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaPrivilegesGet$Response(params?: ApiMasterDataFtaPrivilegesGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<{
[key: string]: Array<string>;
}>> {
    return apiMasterDataFtaPrivilegesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaPrivilegesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaPrivilegesGet(params?: ApiMasterDataFtaPrivilegesGet$Params, context?: HttpContext): Observable<{
[key: string]: Array<string>;
}> {
    return this.apiMasterDataFtaPrivilegesGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<{
[key: string]: Array<string>;
}>): {
[key: string]: Array<string>;
} => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaPrivilegesForBuyerWorkSheetGet()` */
  static readonly ApiMasterDataFtaPrivilegesForBuyerWorkSheetGetPath = '/api/MasterData/FTA/PrivilegesForBuyerWorkSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaPrivilegesForBuyerWorkSheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaPrivilegesForBuyerWorkSheetGet$Response(params?: ApiMasterDataFtaPrivilegesForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<{
[key: string]: Array<string>;
}>> {
    return apiMasterDataFtaPrivilegesForBuyerWorkSheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaPrivilegesForBuyerWorkSheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaPrivilegesForBuyerWorkSheetGet(params?: ApiMasterDataFtaPrivilegesForBuyerWorkSheetGet$Params, context?: HttpContext): Observable<{
[key: string]: Array<string>;
}> {
    return this.apiMasterDataFtaPrivilegesForBuyerWorkSheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<{
[key: string]: Array<string>;
}>): {
[key: string]: Array<string>;
} => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaActiveGet()` */
  static readonly ApiMasterDataFtaActiveGetPath = '/api/MasterData/FTA/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaActiveGet$Response(params?: ApiMasterDataFtaActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterFtaItem>>> {
    return apiMasterDataFtaActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaActiveGet(params?: ApiMasterDataFtaActiveGet$Params, context?: HttpContext): Observable<Array<MasterFtaItem>> {
    return this.apiMasterDataFtaActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterFtaItem>>): Array<MasterFtaItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaMaintainGet()` */
  static readonly ApiMasterDataFtaMaintainGetPath = '/api/MasterData/FTA/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaMaintainGet$Response(params?: ApiMasterDataFtaMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterFtaItem>>> {
    return apiMasterDataFtaMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaMaintainGet(params?: ApiMasterDataFtaMaintainGet$Params, context?: HttpContext): Observable<Array<MasterFtaItem>> {
    return this.apiMasterDataFtaMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterFtaItem>>): Array<MasterFtaItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaAllGet()` */
  static readonly ApiMasterDataFtaAllGetPath = '/api/MasterData/FTA/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaAllGet$Response(params?: ApiMasterDataFtaAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterFtaItem>>> {
    return apiMasterDataFtaAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaAllGet(params?: ApiMasterDataFtaAllGet$Params, context?: HttpContext): Observable<Array<MasterFtaItem>> {
    return this.apiMasterDataFtaAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterFtaItem>>): Array<MasterFtaItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaAddPost()` */
  static readonly ApiMasterDataFtaAddPostPath = '/api/MasterData/FTA/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataFtaAddPost$Response(params?: ApiMasterDataFtaAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataFtaAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataFtaAddPost(params?: ApiMasterDataFtaAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataFtaAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaEditPost()` */
  static readonly ApiMasterDataFtaEditPostPath = '/api/MasterData/FTA/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataFtaEditPost$Response(params?: ApiMasterDataFtaEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataFtaEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataFtaEditPost(params?: ApiMasterDataFtaEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataFtaEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataFtaDeleteGet()` */
  static readonly ApiMasterDataFtaDeleteGetPath = '/api/MasterData/FTA/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataFtaDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaDeleteGet$Response(params?: ApiMasterDataFtaDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataFtaDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataFtaDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataFtaDeleteGet(params?: ApiMasterDataFtaDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataFtaDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
