/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost } from '../fn/i-customs-interface/api-interface-i-customs-get-buyer-work-sheet-detail-from-p-os-post';
import { ApiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost$Params } from '../fn/i-customs-interface/api-interface-i-customs-get-buyer-work-sheet-detail-from-p-os-post';
import { apiInterfaceICustomsGetPoAttachmentGet } from '../fn/i-customs-interface/api-interface-i-customs-get-po-attachment-get';
import { ApiInterfaceICustomsGetPoAttachmentGet$Params } from '../fn/i-customs-interface/api-interface-i-customs-get-po-attachment-get';
import { apiInterfaceICustomsGetPoAttachmentsPost } from '../fn/i-customs-interface/api-interface-i-customs-get-po-attachments-post';
import { ApiInterfaceICustomsGetPoAttachmentsPost$Params } from '../fn/i-customs-interface/api-interface-i-customs-get-po-attachments-post';
import { apiInterfaceICustomsMasterDataPortActivePost } from '../fn/i-customs-interface/api-interface-i-customs-master-data-port-active-post';
import { ApiInterfaceICustomsMasterDataPortActivePost$Params } from '../fn/i-customs-interface/api-interface-i-customs-master-data-port-active-post';
import { ICustomBwDetailReponse } from '../models/i-custom-bw-detail-reponse';
import { ICustomFileResponse } from '../models/i-custom-file-response';
import { MasterPortItem } from '../models/master-port-item';

@Injectable({ providedIn: 'root' })
export class ICustomsInterfaceAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInterfaceICustomsGetPoAttachmentGet()` */
  static readonly ApiInterfaceICustomsGetPoAttachmentGetPath = '/api/interface/iCustoms/GetPOAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceICustomsGetPoAttachmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceICustomsGetPoAttachmentGet$Response(params?: ApiInterfaceICustomsGetPoAttachmentGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ICustomFileResponse>>> {
    return apiInterfaceICustomsGetPoAttachmentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceICustomsGetPoAttachmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceICustomsGetPoAttachmentGet(params?: ApiInterfaceICustomsGetPoAttachmentGet$Params, context?: HttpContext): Observable<Array<ICustomFileResponse>> {
    return this.apiInterfaceICustomsGetPoAttachmentGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ICustomFileResponse>>): Array<ICustomFileResponse> => r.body)
    );
  }

  /** Path part for operation `apiInterfaceICustomsGetPoAttachmentsPost()` */
  static readonly ApiInterfaceICustomsGetPoAttachmentsPostPath = '/api/interface/iCustoms/GetPOAttachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceICustomsGetPoAttachmentsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceICustomsGetPoAttachmentsPost$Response(params?: ApiInterfaceICustomsGetPoAttachmentsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ICustomFileResponse>>> {
    return apiInterfaceICustomsGetPoAttachmentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceICustomsGetPoAttachmentsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceICustomsGetPoAttachmentsPost(params?: ApiInterfaceICustomsGetPoAttachmentsPost$Params, context?: HttpContext): Observable<Array<ICustomFileResponse>> {
    return this.apiInterfaceICustomsGetPoAttachmentsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ICustomFileResponse>>): Array<ICustomFileResponse> => r.body)
    );
  }

  /** Path part for operation `apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost()` */
  static readonly ApiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPostPath = '/api/interface/iCustoms/GetBuyerWorkSheetDetailFromPOs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost$Response(params?: ApiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ICustomBwDetailReponse>>> {
    return apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost(params?: ApiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost$Params, context?: HttpContext): Observable<Array<ICustomBwDetailReponse>> {
    return this.apiInterfaceICustomsGetBuyerWorkSheetDetailFromPOsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ICustomBwDetailReponse>>): Array<ICustomBwDetailReponse> => r.body)
    );
  }

  /** Path part for operation `apiInterfaceICustomsMasterDataPortActivePost()` */
  static readonly ApiInterfaceICustomsMasterDataPortActivePostPath = '/api/interface/iCustoms/MasterData/Port/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceICustomsMasterDataPortActivePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceICustomsMasterDataPortActivePost$Response(params?: ApiInterfaceICustomsMasterDataPortActivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPortItem>>> {
    return apiInterfaceICustomsMasterDataPortActivePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceICustomsMasterDataPortActivePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceICustomsMasterDataPortActivePost(params?: ApiInterfaceICustomsMasterDataPortActivePost$Params, context?: HttpContext): Observable<Array<MasterPortItem>> {
    return this.apiInterfaceICustomsMasterDataPortActivePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPortItem>>): Array<MasterPortItem> => r.body)
    );
  }

}
