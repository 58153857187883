/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiSystemlogSearchPost } from '../fn/system-log/api-systemlog-search-post';
import { ApiSystemlogSearchPost$Params } from '../fn/system-log/api-systemlog-search-post';
import { SystemLog } from '../models/system-log';

@Injectable({ providedIn: 'root' })
export class SystemLogAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSystemlogSearchPost()` */
  static readonly ApiSystemlogSearchPostPath = '/api/systemlog/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemlogSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSystemlogSearchPost$Response(params?: ApiSystemlogSearchPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<SystemLog>>> {
    return apiSystemlogSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSystemlogSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSystemlogSearchPost(params?: ApiSystemlogSearchPost$Params, context?: HttpContext): Observable<Array<SystemLog>> {
    return this.apiSystemlogSearchPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<SystemLog>>): Array<SystemLog> => r.body)
    );
  }

}
