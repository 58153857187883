/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestNewRfqExportItemDetailPost } from '../fn/rpf-request/api-request-new-rfq-export-item-detail-post';
import { ApiRequestNewRfqExportItemDetailPost$Params } from '../fn/rpf-request/api-request-new-rfq-export-item-detail-post';
import { apiRequestNewRfqGetByPkGet } from '../fn/rpf-request/api-request-new-rfq-get-by-pk-get';
import { ApiRequestNewRfqGetByPkGet$Params } from '../fn/rpf-request/api-request-new-rfq-get-by-pk-get';
import { apiRequestNewRfqImportItemDetailPost } from '../fn/rpf-request/api-request-new-rfq-import-item-detail-post';
import { ApiRequestNewRfqImportItemDetailPost$Params } from '../fn/rpf-request/api-request-new-rfq-import-item-detail-post';
import { apiRequestNewRfqInitGet } from '../fn/rpf-request/api-request-new-rfq-init-get';
import { ApiRequestNewRfqInitGet$Params } from '../fn/rpf-request/api-request-new-rfq-init-get';
import { apiRequestNewRfqTakeActionPost } from '../fn/rpf-request/api-request-new-rfq-take-action-post';
import { ApiRequestNewRfqTakeActionPost$Params } from '../fn/rpf-request/api-request-new-rfq-take-action-post';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { RpfTransactionItem } from '../models/rpf-transaction-item';
import { RpfTransactionItemDetail } from '../models/rpf-transaction-item-detail';

@Injectable({ providedIn: 'root' })
export class RpfRequestAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestNewRfqInitGet()` */
  static readonly ApiRequestNewRfqInitGetPath = '/api/request/new-rfq/Init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqInitGet$Response(params?: ApiRequestNewRfqInitGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RpfTransactionItem>> {
    return apiRequestNewRfqInitGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqInitGet(params?: ApiRequestNewRfqInitGet$Params, context?: HttpContext): Observable<RpfTransactionItem> {
    return this.apiRequestNewRfqInitGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RpfTransactionItem>): RpfTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqGetByPkGet()` */
  static readonly ApiRequestNewRfqGetByPkGetPath = '/api/request/new-rfq/GetByPk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqGetByPkGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqGetByPkGet$Response(params?: ApiRequestNewRfqGetByPkGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RpfTransactionItem>> {
    return apiRequestNewRfqGetByPkGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqGetByPkGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqGetByPkGet(params?: ApiRequestNewRfqGetByPkGet$Params, context?: HttpContext): Observable<RpfTransactionItem> {
    return this.apiRequestNewRfqGetByPkGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RpfTransactionItem>): RpfTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqTakeActionPost()` */
  static readonly ApiRequestNewRfqTakeActionPostPath = '/api/request/new-rfq/TakeAction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqTakeActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqTakeActionPost$Response(params?: ApiRequestNewRfqTakeActionPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<RpfTransactionItem>> {
    return apiRequestNewRfqTakeActionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqTakeActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqTakeActionPost(params?: ApiRequestNewRfqTakeActionPost$Params, context?: HttpContext): Observable<RpfTransactionItem> {
    return this.apiRequestNewRfqTakeActionPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<RpfTransactionItem>): RpfTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqImportItemDetailPost()` */
  static readonly ApiRequestNewRfqImportItemDetailPostPath = '/api/request/new-rfq/ImportItemDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqImportItemDetailPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqImportItemDetailPost$Response(params?: ApiRequestNewRfqImportItemDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<RpfTransactionItemDetail>>> {
    return apiRequestNewRfqImportItemDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqImportItemDetailPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestNewRfqImportItemDetailPost(params?: ApiRequestNewRfqImportItemDetailPost$Params, context?: HttpContext): Observable<Array<RpfTransactionItemDetail>> {
    return this.apiRequestNewRfqImportItemDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<RpfTransactionItemDetail>>): Array<RpfTransactionItemDetail> => r.body)
    );
  }

  /** Path part for operation `apiRequestNewRfqExportItemDetailPost()` */
  static readonly ApiRequestNewRfqExportItemDetailPostPath = '/api/request/new-rfq/ExportItemDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestNewRfqExportItemDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqExportItemDetailPost$Response(params?: ApiRequestNewRfqExportItemDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestNewRfqExportItemDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestNewRfqExportItemDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestNewRfqExportItemDetailPost(params?: ApiRequestNewRfqExportItemDetailPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestNewRfqExportItemDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
