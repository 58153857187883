/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet } from '../fn/sap-interface-po-creation/api-interface-sap-interface-po-creation-manual-submit-po-to-sap-by-task-pk-get';
import { ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet$Params } from '../fn/sap-interface-po-creation/api-interface-sap-interface-po-creation-manual-submit-po-to-sap-by-task-pk-get';
import { apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet } from '../fn/sap-interface-po-creation/api-interface-sap-interface-po-creation-manual-submit-po-to-sap-get';
import { ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet$Params } from '../fn/sap-interface-po-creation/api-interface-sap-interface-po-creation-manual-submit-po-to-sap-get';

@Injectable({ providedIn: 'root' })
export class SapInterfacePoCreationAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet()` */
  static readonly ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapGetPath = '/api/interface/SAPInterfacePOCreation/ManualSubmitPOToSAP';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet$Response(params?: ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet(params?: ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet$Params, context?: HttpContext): Observable<void> {
    return this.apiInterfaceSapInterfacePoCreationManualSubmitPoToSapGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet()` */
  static readonly ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGetPath = '/api/interface/SAPInterfacePOCreation/ManualSubmitPOToSAPByTaskPK';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet$Response(params?: ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet(params?: ApiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet$Params, context?: HttpContext): Observable<void> {
    return this.apiInterfaceSapInterfacePoCreationManualSubmitPoToSapByTaskPkGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
