/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataCountryActivePost } from '../fn/master-country/api-master-data-country-active-post';
import { ApiMasterDataCountryActivePost$Params } from '../fn/master-country/api-master-data-country-active-post';
import { MasterCountry } from '../models/master-country';

@Injectable({ providedIn: 'root' })
export class MasterCountryAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataCountryActivePost()` */
  static readonly ApiMasterDataCountryActivePostPath = '/api/MasterData/Country/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCountryActivePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCountryActivePost$Response(params?: ApiMasterDataCountryActivePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCountry>>> {
    return apiMasterDataCountryActivePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCountryActivePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCountryActivePost(params?: ApiMasterDataCountryActivePost$Params, context?: HttpContext): Observable<Array<MasterCountry>> {
    return this.apiMasterDataCountryActivePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCountry>>): Array<MasterCountry> => r.body)
    );
  }

}
