/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { MasterPdpa } from '../../models/master-pdpa';

export interface ApiMasterDataPdpaAddPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: MasterPdpa
}

export function apiMasterDataPdpaAddPost(http: HttpClient, rootUrl: string, params?: ApiMasterDataPdpaAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiMasterDataPdpaAddPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AzureApiStrictHttpResponse<void>;
    })
  );
}

apiMasterDataPdpaAddPost.PATH = '/api/MasterData/PDPA/Add';
