/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataEmailTemplateActiveGet } from '../fn/master-email-template/api-master-data-email-template-active-get';
import { ApiMasterDataEmailTemplateActiveGet$Params } from '../fn/master-email-template/api-master-data-email-template-active-get';
import { apiMasterDataEmailTemplateAddPost } from '../fn/master-email-template/api-master-data-email-template-add-post';
import { ApiMasterDataEmailTemplateAddPost$Params } from '../fn/master-email-template/api-master-data-email-template-add-post';
import { apiMasterDataEmailTemplateAllGet } from '../fn/master-email-template/api-master-data-email-template-all-get';
import { ApiMasterDataEmailTemplateAllGet$Params } from '../fn/master-email-template/api-master-data-email-template-all-get';
import { apiMasterDataEmailTemplateDeleteGet } from '../fn/master-email-template/api-master-data-email-template-delete-get';
import { ApiMasterDataEmailTemplateDeleteGet$Params } from '../fn/master-email-template/api-master-data-email-template-delete-get';
import { apiMasterDataEmailTemplateEditPost } from '../fn/master-email-template/api-master-data-email-template-edit-post';
import { ApiMasterDataEmailTemplateEditPost$Params } from '../fn/master-email-template/api-master-data-email-template-edit-post';
import { apiMasterDataEmailTemplateMaintainGet } from '../fn/master-email-template/api-master-data-email-template-maintain-get';
import { ApiMasterDataEmailTemplateMaintainGet$Params } from '../fn/master-email-template/api-master-data-email-template-maintain-get';
import { MasterEmailTemplateItem } from '../models/master-email-template-item';

@Injectable({ providedIn: 'root' })
export class MasterEmailTemplateAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataEmailTemplateActiveGet()` */
  static readonly ApiMasterDataEmailTemplateActiveGetPath = '/api/MasterData/EmailTemplate/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataEmailTemplateActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateActiveGet$Response(params?: ApiMasterDataEmailTemplateActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterEmailTemplateItem>>> {
    return apiMasterDataEmailTemplateActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataEmailTemplateActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateActiveGet(params?: ApiMasterDataEmailTemplateActiveGet$Params, context?: HttpContext): Observable<Array<MasterEmailTemplateItem>> {
    return this.apiMasterDataEmailTemplateActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterEmailTemplateItem>>): Array<MasterEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataEmailTemplateMaintainGet()` */
  static readonly ApiMasterDataEmailTemplateMaintainGetPath = '/api/MasterData/EmailTemplate/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataEmailTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateMaintainGet$Response(params?: ApiMasterDataEmailTemplateMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterEmailTemplateItem>>> {
    return apiMasterDataEmailTemplateMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataEmailTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateMaintainGet(params?: ApiMasterDataEmailTemplateMaintainGet$Params, context?: HttpContext): Observable<Array<MasterEmailTemplateItem>> {
    return this.apiMasterDataEmailTemplateMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterEmailTemplateItem>>): Array<MasterEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataEmailTemplateAllGet()` */
  static readonly ApiMasterDataEmailTemplateAllGetPath = '/api/MasterData/EmailTemplate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataEmailTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateAllGet$Response(params?: ApiMasterDataEmailTemplateAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterEmailTemplateItem>>> {
    return apiMasterDataEmailTemplateAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataEmailTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateAllGet(params?: ApiMasterDataEmailTemplateAllGet$Params, context?: HttpContext): Observable<Array<MasterEmailTemplateItem>> {
    return this.apiMasterDataEmailTemplateAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterEmailTemplateItem>>): Array<MasterEmailTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataEmailTemplateAddPost()` */
  static readonly ApiMasterDataEmailTemplateAddPostPath = '/api/MasterData/EmailTemplate/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataEmailTemplateAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataEmailTemplateAddPost$Response(params?: ApiMasterDataEmailTemplateAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataEmailTemplateAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataEmailTemplateAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataEmailTemplateAddPost(params?: ApiMasterDataEmailTemplateAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataEmailTemplateAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataEmailTemplateEditPost()` */
  static readonly ApiMasterDataEmailTemplateEditPostPath = '/api/MasterData/EmailTemplate/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataEmailTemplateEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataEmailTemplateEditPost$Response(params?: ApiMasterDataEmailTemplateEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataEmailTemplateEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataEmailTemplateEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataEmailTemplateEditPost(params?: ApiMasterDataEmailTemplateEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataEmailTemplateEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataEmailTemplateDeleteGet()` */
  static readonly ApiMasterDataEmailTemplateDeleteGetPath = '/api/MasterData/EmailTemplate/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataEmailTemplateDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateDeleteGet$Response(params?: ApiMasterDataEmailTemplateDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataEmailTemplateDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataEmailTemplateDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataEmailTemplateDeleteGet(params?: ApiMasterDataEmailTemplateDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataEmailTemplateDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
