/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataCurrencyActiveGet } from '../fn/master-currency/api-master-data-currency-active-get';
import { ApiMasterDataCurrencyActiveGet$Params } from '../fn/master-currency/api-master-data-currency-active-get';
import { apiMasterDataCurrencyAddPost } from '../fn/master-currency/api-master-data-currency-add-post';
import { ApiMasterDataCurrencyAddPost$Params } from '../fn/master-currency/api-master-data-currency-add-post';
import { apiMasterDataCurrencyAllGet } from '../fn/master-currency/api-master-data-currency-all-get';
import { ApiMasterDataCurrencyAllGet$Params } from '../fn/master-currency/api-master-data-currency-all-get';
import { apiMasterDataCurrencyDeleteGet } from '../fn/master-currency/api-master-data-currency-delete-get';
import { ApiMasterDataCurrencyDeleteGet$Params } from '../fn/master-currency/api-master-data-currency-delete-get';
import { apiMasterDataCurrencyEditPost } from '../fn/master-currency/api-master-data-currency-edit-post';
import { ApiMasterDataCurrencyEditPost$Params } from '../fn/master-currency/api-master-data-currency-edit-post';
import { apiMasterDataCurrencyMaintainGet } from '../fn/master-currency/api-master-data-currency-maintain-get';
import { ApiMasterDataCurrencyMaintainGet$Params } from '../fn/master-currency/api-master-data-currency-maintain-get';
import { MasterCurrencyItem } from '../models/master-currency-item';

@Injectable({ providedIn: 'root' })
export class MasterCurrencyAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataCurrencyActiveGet()` */
  static readonly ApiMasterDataCurrencyActiveGetPath = '/api/MasterData/Currency/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCurrencyActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyActiveGet$Response(params?: ApiMasterDataCurrencyActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCurrencyItem>>> {
    return apiMasterDataCurrencyActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCurrencyActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyActiveGet(params?: ApiMasterDataCurrencyActiveGet$Params, context?: HttpContext): Observable<Array<MasterCurrencyItem>> {
    return this.apiMasterDataCurrencyActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCurrencyItem>>): Array<MasterCurrencyItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCurrencyMaintainGet()` */
  static readonly ApiMasterDataCurrencyMaintainGetPath = '/api/MasterData/Currency/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCurrencyMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyMaintainGet$Response(params?: ApiMasterDataCurrencyMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCurrencyItem>>> {
    return apiMasterDataCurrencyMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCurrencyMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyMaintainGet(params?: ApiMasterDataCurrencyMaintainGet$Params, context?: HttpContext): Observable<Array<MasterCurrencyItem>> {
    return this.apiMasterDataCurrencyMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCurrencyItem>>): Array<MasterCurrencyItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCurrencyAllGet()` */
  static readonly ApiMasterDataCurrencyAllGetPath = '/api/MasterData/Currency/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCurrencyAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyAllGet$Response(params?: ApiMasterDataCurrencyAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterCurrencyItem>>> {
    return apiMasterDataCurrencyAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCurrencyAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyAllGet(params?: ApiMasterDataCurrencyAllGet$Params, context?: HttpContext): Observable<Array<MasterCurrencyItem>> {
    return this.apiMasterDataCurrencyAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterCurrencyItem>>): Array<MasterCurrencyItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCurrencyAddPost()` */
  static readonly ApiMasterDataCurrencyAddPostPath = '/api/MasterData/Currency/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCurrencyAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCurrencyAddPost$Response(params?: ApiMasterDataCurrencyAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataCurrencyAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCurrencyAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCurrencyAddPost(params?: ApiMasterDataCurrencyAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataCurrencyAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCurrencyEditPost()` */
  static readonly ApiMasterDataCurrencyEditPostPath = '/api/MasterData/Currency/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCurrencyEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCurrencyEditPost$Response(params?: ApiMasterDataCurrencyEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataCurrencyEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCurrencyEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataCurrencyEditPost(params?: ApiMasterDataCurrencyEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataCurrencyEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataCurrencyDeleteGet()` */
  static readonly ApiMasterDataCurrencyDeleteGetPath = '/api/MasterData/Currency/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataCurrencyDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyDeleteGet$Response(params?: ApiMasterDataCurrencyDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataCurrencyDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataCurrencyDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataCurrencyDeleteGet(params?: ApiMasterDataCurrencyDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataCurrencyDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
