/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { fileBuyerWorksheetDistTypeTokenGet } from '../fn/file/file-buyer-worksheet-dist-type-token-get';
import { FileBuyerWorksheetDistTypeTokenGet$Params } from '../fn/file/file-buyer-worksheet-dist-type-token-get';
import { fileDistNameUniqueGet } from '../fn/file/file-dist-name-unique-get';
import { FileDistNameUniqueGet$Params } from '../fn/file/file-dist-name-unique-get';
import { fileItemIdGet } from '../fn/file/file-item-id-get';
import { FileItemIdGet$Params } from '../fn/file/file-item-id-get';
import { fileQueryPkGet } from '../fn/file/file-query-pk-get';
import { FileQueryPkGet$Params } from '../fn/file/file-query-pk-get';
import { fileS3DistTokenGet } from '../fn/file/file-s-3-dist-token-get';
import { FileS3DistTokenGet$Params } from '../fn/file/file-s-3-dist-token-get';
import { fileViewsFileNameGet } from '../fn/file/file-views-file-name-get';
import { FileViewsFileNameGet$Params } from '../fn/file/file-views-file-name-get';

@Injectable({ providedIn: 'root' })
export class FileAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fileDistNameUniqueGet()` */
  static readonly FileDistNameUniqueGetPath = '/file/dist/{nameUnique}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileDistNameUniqueGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileDistNameUniqueGet$Response(params: FileDistNameUniqueGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return fileDistNameUniqueGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileDistNameUniqueGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileDistNameUniqueGet(params: FileDistNameUniqueGet$Params, context?: HttpContext): Observable<void> {
    return this.fileDistNameUniqueGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileQueryPkGet()` */
  static readonly FileQueryPkGetPath = '/file/query/{pk}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileQueryPkGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileQueryPkGet$Response(params: FileQueryPkGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return fileQueryPkGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileQueryPkGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileQueryPkGet(params: FileQueryPkGet$Params, context?: HttpContext): Observable<void> {
    return this.fileQueryPkGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileItemIdGet()` */
  static readonly FileItemIdGetPath = '/file/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileItemIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileItemIdGet$Response(params: FileItemIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return fileItemIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileItemIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileItemIdGet(params: FileItemIdGet$Params, context?: HttpContext): Observable<void> {
    return this.fileItemIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileViewsFileNameGet()` */
  static readonly FileViewsFileNameGetPath = '/file/views/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileViewsFileNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileViewsFileNameGet$Response(params: FileViewsFileNameGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return fileViewsFileNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileViewsFileNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileViewsFileNameGet(params: FileViewsFileNameGet$Params, context?: HttpContext): Observable<void> {
    return this.fileViewsFileNameGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileS3DistTokenGet()` */
  static readonly FileS3DistTokenGetPath = '/file/s3/dist/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileS3DistTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileS3DistTokenGet$Response(params: FileS3DistTokenGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return fileS3DistTokenGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileS3DistTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileS3DistTokenGet(params: FileS3DistTokenGet$Params, context?: HttpContext): Observable<void> {
    return this.fileS3DistTokenGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileBuyerWorksheetDistTypeTokenGet()` */
  static readonly FileBuyerWorksheetDistTypeTokenGetPath = '/file/buyer-worksheet/dist/{type}/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBuyerWorksheetDistTypeTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileBuyerWorksheetDistTypeTokenGet$Response(params: FileBuyerWorksheetDistTypeTokenGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return fileBuyerWorksheetDistTypeTokenGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBuyerWorksheetDistTypeTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileBuyerWorksheetDistTypeTokenGet(params: FileBuyerWorksheetDistTypeTokenGet$Params, context?: HttpContext): Observable<void> {
    return this.fileBuyerWorksheetDistTypeTokenGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
