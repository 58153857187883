/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataPdpaActiveGet } from '../fn/master-pdpa/api-master-data-pdpa-active-get';
import { ApiMasterDataPdpaActiveGet$Params } from '../fn/master-pdpa/api-master-data-pdpa-active-get';
import { apiMasterDataPdpaAddPost } from '../fn/master-pdpa/api-master-data-pdpa-add-post';
import { ApiMasterDataPdpaAddPost$Params } from '../fn/master-pdpa/api-master-data-pdpa-add-post';
import { apiMasterDataPdpaAllGet } from '../fn/master-pdpa/api-master-data-pdpa-all-get';
import { ApiMasterDataPdpaAllGet$Params } from '../fn/master-pdpa/api-master-data-pdpa-all-get';
import { apiMasterDataPdpaDeleteGet } from '../fn/master-pdpa/api-master-data-pdpa-delete-get';
import { ApiMasterDataPdpaDeleteGet$Params } from '../fn/master-pdpa/api-master-data-pdpa-delete-get';
import { apiMasterDataPdpaEditPost } from '../fn/master-pdpa/api-master-data-pdpa-edit-post';
import { ApiMasterDataPdpaEditPost$Params } from '../fn/master-pdpa/api-master-data-pdpa-edit-post';
import { apiMasterDataPdpaMaintainGet } from '../fn/master-pdpa/api-master-data-pdpa-maintain-get';
import { ApiMasterDataPdpaMaintainGet$Params } from '../fn/master-pdpa/api-master-data-pdpa-maintain-get';
import { MasterPdpa } from '../models/master-pdpa';

@Injectable({ providedIn: 'root' })
export class MasterPdpaAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataPdpaActiveGet()` */
  static readonly ApiMasterDataPdpaActiveGetPath = '/api/MasterData/PDPA/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPdpaActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaActiveGet$Response(params?: ApiMasterDataPdpaActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPdpa>>> {
    return apiMasterDataPdpaActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPdpaActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaActiveGet(params?: ApiMasterDataPdpaActiveGet$Params, context?: HttpContext): Observable<Array<MasterPdpa>> {
    return this.apiMasterDataPdpaActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPdpa>>): Array<MasterPdpa> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPdpaMaintainGet()` */
  static readonly ApiMasterDataPdpaMaintainGetPath = '/api/MasterData/PDPA/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPdpaMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaMaintainGet$Response(params?: ApiMasterDataPdpaMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPdpa>>> {
    return apiMasterDataPdpaMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPdpaMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaMaintainGet(params?: ApiMasterDataPdpaMaintainGet$Params, context?: HttpContext): Observable<Array<MasterPdpa>> {
    return this.apiMasterDataPdpaMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPdpa>>): Array<MasterPdpa> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPdpaAllGet()` */
  static readonly ApiMasterDataPdpaAllGetPath = '/api/MasterData/PDPA/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPdpaAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaAllGet$Response(params?: ApiMasterDataPdpaAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPdpa>>> {
    return apiMasterDataPdpaAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPdpaAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaAllGet(params?: ApiMasterDataPdpaAllGet$Params, context?: HttpContext): Observable<Array<MasterPdpa>> {
    return this.apiMasterDataPdpaAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPdpa>>): Array<MasterPdpa> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPdpaAddPost()` */
  static readonly ApiMasterDataPdpaAddPostPath = '/api/MasterData/PDPA/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPdpaAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPdpaAddPost$Response(params?: ApiMasterDataPdpaAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPdpaAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPdpaAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPdpaAddPost(params?: ApiMasterDataPdpaAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPdpaAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPdpaEditPost()` */
  static readonly ApiMasterDataPdpaEditPostPath = '/api/MasterData/PDPA/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPdpaEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPdpaEditPost$Response(params?: ApiMasterDataPdpaEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPdpaEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPdpaEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPdpaEditPost(params?: ApiMasterDataPdpaEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPdpaEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPdpaDeleteGet()` */
  static readonly ApiMasterDataPdpaDeleteGetPath = '/api/MasterData/PDPA/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPdpaDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaDeleteGet$Response(params?: ApiMasterDataPdpaDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPdpaDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPdpaDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPdpaDeleteGet(params?: ApiMasterDataPdpaDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPdpaDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
