import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { apiAzureApiBaseUrlGetter } from './commons/global-config';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AzureApiModule } from './api-azure/azure-api.module';
import { MsalConfigDynamicModule } from './msal/msal-config-dynamic.module';

registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  message: { nzTop: 120 },
  notification: { nzTop: 240 }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    AzureApiModule.forRoot({ rootUrl: apiAzureApiBaseUrlGetter() }), // Set root url for API new in Azure
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule,
    MsalConfigDynamicModule.forRoot(apiAzureApiBaseUrlGetter()),
    NgxSpinnerModule,
  ],
  providers: [
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: NZ_I18N, useValue: en_US },
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
