/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataContractTermConditionTemplateActiveByContractTypeGet } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-active-by-contract-type-get';
import { ApiMasterDataContractTermConditionTemplateActiveByContractTypeGet$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-active-by-contract-type-get';
import { apiMasterDataContractTermConditionTemplateActiveGet } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-active-get';
import { ApiMasterDataContractTermConditionTemplateActiveGet$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-active-get';
import { apiMasterDataContractTermConditionTemplateAddPost } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-add-post';
import { ApiMasterDataContractTermConditionTemplateAddPost$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-add-post';
import { apiMasterDataContractTermConditionTemplateAllGet } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-all-get';
import { ApiMasterDataContractTermConditionTemplateAllGet$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-all-get';
import { apiMasterDataContractTermConditionTemplateDeleteGet } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-delete-get';
import { ApiMasterDataContractTermConditionTemplateDeleteGet$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-delete-get';
import { apiMasterDataContractTermConditionTemplateEditPost } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-edit-post';
import { ApiMasterDataContractTermConditionTemplateEditPost$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-edit-post';
import { apiMasterDataContractTermConditionTemplateMaintainGet } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-maintain-get';
import { ApiMasterDataContractTermConditionTemplateMaintainGet$Params } from '../fn/master-contract-term-condition-template/api-master-data-contract-term-condition-template-maintain-get';
import { MasterContractTermConditionTemplateItem } from '../models/master-contract-term-condition-template-item';

@Injectable({ providedIn: 'root' })
export class MasterContractTermConditionTemplateAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateActiveGet()` */
  static readonly ApiMasterDataContractTermConditionTemplateActiveGetPath = '/api/MasterData/ContractTermConditionTemplate/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateActiveGet$Response(params?: ApiMasterDataContractTermConditionTemplateActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>> {
    return apiMasterDataContractTermConditionTemplateActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateActiveGet(params?: ApiMasterDataContractTermConditionTemplateActiveGet$Params, context?: HttpContext): Observable<Array<MasterContractTermConditionTemplateItem>> {
    return this.apiMasterDataContractTermConditionTemplateActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>): Array<MasterContractTermConditionTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateActiveByContractTypeGet()` */
  static readonly ApiMasterDataContractTermConditionTemplateActiveByContractTypeGetPath = '/api/MasterData/ContractTermConditionTemplate/ActiveByContractType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateActiveByContractTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateActiveByContractTypeGet$Response(params?: ApiMasterDataContractTermConditionTemplateActiveByContractTypeGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>> {
    return apiMasterDataContractTermConditionTemplateActiveByContractTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateActiveByContractTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateActiveByContractTypeGet(params?: ApiMasterDataContractTermConditionTemplateActiveByContractTypeGet$Params, context?: HttpContext): Observable<Array<MasterContractTermConditionTemplateItem>> {
    return this.apiMasterDataContractTermConditionTemplateActiveByContractTypeGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>): Array<MasterContractTermConditionTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateMaintainGet()` */
  static readonly ApiMasterDataContractTermConditionTemplateMaintainGetPath = '/api/MasterData/ContractTermConditionTemplate/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateMaintainGet$Response(params?: ApiMasterDataContractTermConditionTemplateMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>> {
    return apiMasterDataContractTermConditionTemplateMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateMaintainGet(params?: ApiMasterDataContractTermConditionTemplateMaintainGet$Params, context?: HttpContext): Observable<Array<MasterContractTermConditionTemplateItem>> {
    return this.apiMasterDataContractTermConditionTemplateMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>): Array<MasterContractTermConditionTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateAllGet()` */
  static readonly ApiMasterDataContractTermConditionTemplateAllGetPath = '/api/MasterData/ContractTermConditionTemplate/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateAllGet$Response(params?: ApiMasterDataContractTermConditionTemplateAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>> {
    return apiMasterDataContractTermConditionTemplateAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateAllGet(params?: ApiMasterDataContractTermConditionTemplateAllGet$Params, context?: HttpContext): Observable<Array<MasterContractTermConditionTemplateItem>> {
    return this.apiMasterDataContractTermConditionTemplateAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterContractTermConditionTemplateItem>>): Array<MasterContractTermConditionTemplateItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateAddPost()` */
  static readonly ApiMasterDataContractTermConditionTemplateAddPostPath = '/api/MasterData/ContractTermConditionTemplate/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractTermConditionTemplateAddPost$Response(params?: ApiMasterDataContractTermConditionTemplateAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataContractTermConditionTemplateAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractTermConditionTemplateAddPost(params?: ApiMasterDataContractTermConditionTemplateAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataContractTermConditionTemplateAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateEditPost()` */
  static readonly ApiMasterDataContractTermConditionTemplateEditPostPath = '/api/MasterData/ContractTermConditionTemplate/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractTermConditionTemplateEditPost$Response(params?: ApiMasterDataContractTermConditionTemplateEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataContractTermConditionTemplateEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataContractTermConditionTemplateEditPost(params?: ApiMasterDataContractTermConditionTemplateEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataContractTermConditionTemplateEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataContractTermConditionTemplateDeleteGet()` */
  static readonly ApiMasterDataContractTermConditionTemplateDeleteGetPath = '/api/MasterData/ContractTermConditionTemplate/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataContractTermConditionTemplateDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateDeleteGet$Response(params?: ApiMasterDataContractTermConditionTemplateDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataContractTermConditionTemplateDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataContractTermConditionTemplateDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataContractTermConditionTemplateDeleteGet(params?: ApiMasterDataContractTermConditionTemplateDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataContractTermConditionTemplateDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
