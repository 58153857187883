/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataPurGroupMemberActiveByPurGroupGet } from '../fn/master-pur-group-member/api-master-data-pur-group-member-active-by-pur-group-get';
import { ApiMasterDataPurGroupMemberActiveByPurGroupGet$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-active-by-pur-group-get';
import { apiMasterDataPurGroupMemberActiveGet } from '../fn/master-pur-group-member/api-master-data-pur-group-member-active-get';
import { ApiMasterDataPurGroupMemberActiveGet$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-active-get';
import { apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost } from '../fn/master-pur-group-member/api-master-data-pur-group-member-active-member-by-pur-groups-post';
import { ApiMasterDataPurGroupMemberActiveMemberByPurGroupsPost$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-active-member-by-pur-groups-post';
import { apiMasterDataPurGroupMemberAddPost } from '../fn/master-pur-group-member/api-master-data-pur-group-member-add-post';
import { ApiMasterDataPurGroupMemberAddPost$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-add-post';
import { apiMasterDataPurGroupMemberAllGet } from '../fn/master-pur-group-member/api-master-data-pur-group-member-all-get';
import { ApiMasterDataPurGroupMemberAllGet$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-all-get';
import { apiMasterDataPurGroupMemberDeleteGet } from '../fn/master-pur-group-member/api-master-data-pur-group-member-delete-get';
import { ApiMasterDataPurGroupMemberDeleteGet$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-delete-get';
import { apiMasterDataPurGroupMemberEditPost } from '../fn/master-pur-group-member/api-master-data-pur-group-member-edit-post';
import { ApiMasterDataPurGroupMemberEditPost$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-edit-post';
import { apiMasterDataPurGroupMemberExportGet } from '../fn/master-pur-group-member/api-master-data-pur-group-member-export-get';
import { ApiMasterDataPurGroupMemberExportGet$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-export-get';
import { apiMasterDataPurGroupMemberImportPost } from '../fn/master-pur-group-member/api-master-data-pur-group-member-import-post';
import { ApiMasterDataPurGroupMemberImportPost$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-import-post';
import { apiMasterDataPurGroupMemberMaintainGet } from '../fn/master-pur-group-member/api-master-data-pur-group-member-maintain-get';
import { ApiMasterDataPurGroupMemberMaintainGet$Params } from '../fn/master-pur-group-member/api-master-data-pur-group-member-maintain-get';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { MasterPurGroupMemberItem } from '../models/master-pur-group-member-item';

@Injectable({ providedIn: 'root' })
export class MasterPurGroupMemberAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataPurGroupMemberActiveByPurGroupGet()` */
  static readonly ApiMasterDataPurGroupMemberActiveByPurGroupGetPath = '/api/MasterData/PurGroupMember/ActiveByPurGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberActiveByPurGroupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberActiveByPurGroupGet$Response(params?: ApiMasterDataPurGroupMemberActiveByPurGroupGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>> {
    return apiMasterDataPurGroupMemberActiveByPurGroupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberActiveByPurGroupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberActiveByPurGroupGet(params?: ApiMasterDataPurGroupMemberActiveByPurGroupGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupMemberItem>> {
    return this.apiMasterDataPurGroupMemberActiveByPurGroupGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>): Array<MasterPurGroupMemberItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberActiveGet()` */
  static readonly ApiMasterDataPurGroupMemberActiveGetPath = '/api/MasterData/PurGroupMember/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberActiveGet$Response(params?: ApiMasterDataPurGroupMemberActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>> {
    return apiMasterDataPurGroupMemberActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberActiveGet(params?: ApiMasterDataPurGroupMemberActiveGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupMemberItem>> {
    return this.apiMasterDataPurGroupMemberActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>): Array<MasterPurGroupMemberItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberMaintainGet()` */
  static readonly ApiMasterDataPurGroupMemberMaintainGetPath = '/api/MasterData/PurGroupMember/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberMaintainGet$Response(params?: ApiMasterDataPurGroupMemberMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>> {
    return apiMasterDataPurGroupMemberMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberMaintainGet(params?: ApiMasterDataPurGroupMemberMaintainGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupMemberItem>> {
    return this.apiMasterDataPurGroupMemberMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>): Array<MasterPurGroupMemberItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberAllGet()` */
  static readonly ApiMasterDataPurGroupMemberAllGetPath = '/api/MasterData/PurGroupMember/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberAllGet$Response(params?: ApiMasterDataPurGroupMemberAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>> {
    return apiMasterDataPurGroupMemberAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberAllGet(params?: ApiMasterDataPurGroupMemberAllGet$Params, context?: HttpContext): Observable<Array<MasterPurGroupMemberItem>> {
    return this.apiMasterDataPurGroupMemberAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>): Array<MasterPurGroupMemberItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost()` */
  static readonly ApiMasterDataPurGroupMemberActiveMemberByPurGroupsPostPath = '/api/MasterData/PurGroupMember/ActiveMemberByPurGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost$Response(params?: ApiMasterDataPurGroupMemberActiveMemberByPurGroupsPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>> {
    return apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost(params?: ApiMasterDataPurGroupMemberActiveMemberByPurGroupsPost$Params, context?: HttpContext): Observable<Array<MasterPurGroupMemberItem>> {
    return this.apiMasterDataPurGroupMemberActiveMemberByPurGroupsPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterPurGroupMemberItem>>): Array<MasterPurGroupMemberItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberAddPost()` */
  static readonly ApiMasterDataPurGroupMemberAddPostPath = '/api/MasterData/PurGroupMember/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupMemberAddPost$Response(params?: ApiMasterDataPurGroupMemberAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPurGroupMemberAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupMemberAddPost(params?: ApiMasterDataPurGroupMemberAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPurGroupMemberAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberEditPost()` */
  static readonly ApiMasterDataPurGroupMemberEditPostPath = '/api/MasterData/PurGroupMember/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupMemberEditPost$Response(params?: ApiMasterDataPurGroupMemberEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPurGroupMemberEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataPurGroupMemberEditPost(params?: ApiMasterDataPurGroupMemberEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPurGroupMemberEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberDeleteGet()` */
  static readonly ApiMasterDataPurGroupMemberDeleteGetPath = '/api/MasterData/PurGroupMember/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberDeleteGet$Response(params?: ApiMasterDataPurGroupMemberDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataPurGroupMemberDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberDeleteGet(params?: ApiMasterDataPurGroupMemberDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataPurGroupMemberDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberImportPost()` */
  static readonly ApiMasterDataPurGroupMemberImportPostPath = '/api/MasterData/PurGroupMember/Import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberImportPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberImportPost$Response(params?: ApiMasterDataPurGroupMemberImportPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataPurGroupMemberImportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberImportPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberImportPost(params?: ApiMasterDataPurGroupMemberImportPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataPurGroupMemberImportPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiMasterDataPurGroupMemberExportGet()` */
  static readonly ApiMasterDataPurGroupMemberExportGetPath = '/api/MasterData/PurGroupMember/Export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataPurGroupMemberExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberExportGet$Response(params?: ApiMasterDataPurGroupMemberExportGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiMasterDataPurGroupMemberExportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataPurGroupMemberExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataPurGroupMemberExportGet(params?: ApiMasterDataPurGroupMemberExportGet$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiMasterDataPurGroupMemberExportGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

}
