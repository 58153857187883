/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiServerTestDbTestPost } from '../fn/server-test/api-server-test-db-test-post';
import { ApiServerTestDbTestPost$Params } from '../fn/server-test/api-server-test-db-test-post';
import { apiServerTestHttpTestPost } from '../fn/server-test/api-server-test-http-test-post';
import { ApiServerTestHttpTestPost$Params } from '../fn/server-test/api-server-test-http-test-post';
import { apiServerTestHttpTestSocketPost } from '../fn/server-test/api-server-test-http-test-socket-post';
import { ApiServerTestHttpTestSocketPost$Params } from '../fn/server-test/api-server-test-http-test-socket-post';
import { apiServerTestRestHttpTestPost } from '../fn/server-test/api-server-test-rest-http-test-post';
import { ApiServerTestRestHttpTestPost$Params } from '../fn/server-test/api-server-test-rest-http-test-post';
import { apiServerTestTryAwsS3Post } from '../fn/server-test/api-server-test-try-aws-s-3-post';
import { ApiServerTestTryAwsS3Post$Params } from '../fn/server-test/api-server-test-try-aws-s-3-post';
import { apiServerTestTryChemdwdbGet } from '../fn/server-test/api-server-test-try-chemdwdb-get';
import { ApiServerTestTryChemdwdbGet$Params } from '../fn/server-test/api-server-test-try-chemdwdb-get';
import { apiServerTestTryChempmmspendGet } from '../fn/server-test/api-server-test-try-chempmmspend-get';
import { ApiServerTestTryChempmmspendGet$Params } from '../fn/server-test/api-server-test-try-chempmmspend-get';
import { apiServerTestTryConfigurationPost } from '../fn/server-test/api-server-test-try-configuration-post';
import { ApiServerTestTryConfigurationPost$Params } from '../fn/server-test/api-server-test-try-configuration-post';
import { apiServerTestTryConnectAzure2Get } from '../fn/server-test/api-server-test-try-connect-azure-2-get';
import { ApiServerTestTryConnectAzure2Get$Params } from '../fn/server-test/api-server-test-try-connect-azure-2-get';
import { apiServerTestTryConnectAzureGet } from '../fn/server-test/api-server-test-try-connect-azure-get';
import { ApiServerTestTryConnectAzureGet$Params } from '../fn/server-test/api-server-test-try-connect-azure-get';
import { apiServerTestTryDnsGet } from '../fn/server-test/api-server-test-try-dns-get';
import { ApiServerTestTryDnsGet$Params } from '../fn/server-test/api-server-test-try-dns-get';
import { apiServerTestTryQueryGet } from '../fn/server-test/api-server-test-try-query-get';
import { ApiServerTestTryQueryGet$Params } from '../fn/server-test/api-server-test-try-query-get';
import { apiServerTestTrySmtpGet } from '../fn/server-test/api-server-test-try-smtp-get';
import { ApiServerTestTrySmtpGet$Params } from '../fn/server-test/api-server-test-try-smtp-get';
import { apiServerTestTryVenusInboxGet } from '../fn/server-test/api-server-test-try-venus-inbox-get';
import { ApiServerTestTryVenusInboxGet$Params } from '../fn/server-test/api-server-test-try-venus-inbox-get';
import { TestDbRes } from '../models/test-db-res';

@Injectable({ providedIn: 'root' })
export class ServerTestAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiServerTestDbTestPost()` */
  static readonly ApiServerTestDbTestPostPath = '/api/server-test/db-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestDbTestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestDbTestPost$Response(params?: ApiServerTestDbTestPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestDbTestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestDbTestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestDbTestPost(params?: ApiServerTestDbTestPost$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestDbTestPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestHttpTestPost()` */
  static readonly ApiServerTestHttpTestPostPath = '/api/server-test/http-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestHttpTestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestHttpTestPost$Response(params?: ApiServerTestHttpTestPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestHttpTestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestHttpTestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestHttpTestPost(params?: ApiServerTestHttpTestPost$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestHttpTestPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestHttpTestSocketPost()` */
  static readonly ApiServerTestHttpTestSocketPostPath = '/api/server-test/http-test-socket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestHttpTestSocketPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestHttpTestSocketPost$Response(params?: ApiServerTestHttpTestSocketPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestHttpTestSocketPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestHttpTestSocketPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestHttpTestSocketPost(params?: ApiServerTestHttpTestSocketPost$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestHttpTestSocketPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestRestHttpTestPost()` */
  static readonly ApiServerTestRestHttpTestPostPath = '/api/server-test/rest-http-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestRestHttpTestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestRestHttpTestPost$Response(params?: ApiServerTestRestHttpTestPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestRestHttpTestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestRestHttpTestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestRestHttpTestPost(params?: ApiServerTestRestHttpTestPost$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestRestHttpTestPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryConnectAzureGet()` */
  static readonly ApiServerTestTryConnectAzureGetPath = '/api/server-test/try-connect-azure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryConnectAzureGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryConnectAzureGet$Response(params?: ApiServerTestTryConnectAzureGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<boolean>> {
    return apiServerTestTryConnectAzureGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryConnectAzureGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryConnectAzureGet(params?: ApiServerTestTryConnectAzureGet$Params, context?: HttpContext): Observable<boolean> {
    return this.apiServerTestTryConnectAzureGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryConnectAzure2Get()` */
  static readonly ApiServerTestTryConnectAzure2GetPath = '/api/server-test/try-connect-azure2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryConnectAzure2Get()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryConnectAzure2Get$Response(params?: ApiServerTestTryConnectAzure2Get$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<boolean>> {
    return apiServerTestTryConnectAzure2Get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryConnectAzure2Get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryConnectAzure2Get(params?: ApiServerTestTryConnectAzure2Get$Params, context?: HttpContext): Observable<boolean> {
    return this.apiServerTestTryConnectAzure2Get$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiServerTestTrySmtpGet()` */
  static readonly ApiServerTestTrySmtpGetPath = '/api/server-test/try-smtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTrySmtpGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTrySmtpGet$Response(params?: ApiServerTestTrySmtpGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiServerTestTrySmtpGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTrySmtpGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTrySmtpGet(params?: ApiServerTestTrySmtpGet$Params, context?: HttpContext): Observable<void> {
    return this.apiServerTestTrySmtpGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryDnsGet()` */
  static readonly ApiServerTestTryDnsGetPath = '/api/server-test/try-dns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryDnsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryDnsGet$Response(params?: ApiServerTestTryDnsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestTryDnsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryDnsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryDnsGet(params?: ApiServerTestTryDnsGet$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestTryDnsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryChempmmspendGet()` */
  static readonly ApiServerTestTryChempmmspendGetPath = '/api/server-test/try-chempmmspend';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryChempmmspendGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryChempmmspendGet$Response(params?: ApiServerTestTryChempmmspendGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<TestDbRes>> {
    return apiServerTestTryChempmmspendGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryChempmmspendGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryChempmmspendGet(params?: ApiServerTestTryChempmmspendGet$Params, context?: HttpContext): Observable<TestDbRes> {
    return this.apiServerTestTryChempmmspendGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<TestDbRes>): TestDbRes => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryChemdwdbGet()` */
  static readonly ApiServerTestTryChemdwdbGetPath = '/api/server-test/try-chemdwdb';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryChemdwdbGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryChemdwdbGet$Response(params?: ApiServerTestTryChemdwdbGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<TestDbRes>> {
    return apiServerTestTryChemdwdbGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryChemdwdbGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryChemdwdbGet(params?: ApiServerTestTryChemdwdbGet$Params, context?: HttpContext): Observable<TestDbRes> {
    return this.apiServerTestTryChemdwdbGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<TestDbRes>): TestDbRes => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryQueryGet()` */
  static readonly ApiServerTestTryQueryGetPath = '/api/server-test/try-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryQueryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryQueryGet$Response(params?: ApiServerTestTryQueryGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<TestDbRes>>> {
    return apiServerTestTryQueryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryQueryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryQueryGet(params?: ApiServerTestTryQueryGet$Params, context?: HttpContext): Observable<Array<TestDbRes>> {
    return this.apiServerTestTryQueryGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<TestDbRes>>): Array<TestDbRes> => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryVenusInboxGet()` */
  static readonly ApiServerTestTryVenusInboxGetPath = '/api/server-test/try-venus-inbox';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryVenusInboxGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryVenusInboxGet$Response(params?: ApiServerTestTryVenusInboxGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestTryVenusInboxGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryVenusInboxGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryVenusInboxGet(params?: ApiServerTestTryVenusInboxGet$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestTryVenusInboxGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryAwsS3Post()` */
  static readonly ApiServerTestTryAwsS3PostPath = '/api/server-test/try-aws-s3';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryAwsS3Post()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestTryAwsS3Post$Response(params?: ApiServerTestTryAwsS3Post$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
    return apiServerTestTryAwsS3Post(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryAwsS3Post$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerTestTryAwsS3Post(params?: ApiServerTestTryAwsS3Post$Params, context?: HttpContext): Observable<string> {
    return this.apiServerTestTryAwsS3Post$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiServerTestTryConfigurationPost()` */
  static readonly ApiServerTestTryConfigurationPostPath = '/api/server-test/try-configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerTestTryConfigurationPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryConfigurationPost$Response(params?: ApiServerTestTryConfigurationPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiServerTestTryConfigurationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerTestTryConfigurationPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerTestTryConfigurationPost(params?: ApiServerTestTryConfigurationPost$Params, context?: HttpContext): Observable<void> {
    return this.apiServerTestTryConfigurationPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
