/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataVendorMatchingTypeActiveForPrGet } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-active-for-pr-get';
import { ApiMasterDataVendorMatchingTypeActiveForPrGet$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-active-for-pr-get';
import { apiMasterDataVendorMatchingTypeActiveGet } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-active-get';
import { ApiMasterDataVendorMatchingTypeActiveGet$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-active-get';
import { apiMasterDataVendorMatchingTypeAddPost } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-add-post';
import { ApiMasterDataVendorMatchingTypeAddPost$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-add-post';
import { apiMasterDataVendorMatchingTypeAllGet } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-all-get';
import { ApiMasterDataVendorMatchingTypeAllGet$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-all-get';
import { apiMasterDataVendorMatchingTypeDeleteGet } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-delete-get';
import { ApiMasterDataVendorMatchingTypeDeleteGet$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-delete-get';
import { apiMasterDataVendorMatchingTypeEditPost } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-edit-post';
import { ApiMasterDataVendorMatchingTypeEditPost$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-edit-post';
import { apiMasterDataVendorMatchingTypeMaintainGet } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-maintain-get';
import { ApiMasterDataVendorMatchingTypeMaintainGet$Params } from '../fn/master-vendor-matching-type/api-master-data-vendor-matching-type-maintain-get';
import { MasterVendorMatchingTypeItem } from '../models/master-vendor-matching-type-item';

@Injectable({ providedIn: 'root' })
export class MasterVendorMatchingTypeAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeActiveGet()` */
  static readonly ApiMasterDataVendorMatchingTypeActiveGetPath = '/api/MasterData/VendorMatchingType/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeActiveGet$Response(params?: ApiMasterDataVendorMatchingTypeActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>> {
    return apiMasterDataVendorMatchingTypeActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeActiveGet(params?: ApiMasterDataVendorMatchingTypeActiveGet$Params, context?: HttpContext): Observable<Array<MasterVendorMatchingTypeItem>> {
    return this.apiMasterDataVendorMatchingTypeActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>): Array<MasterVendorMatchingTypeItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeActiveForPrGet()` */
  static readonly ApiMasterDataVendorMatchingTypeActiveForPrGetPath = '/api/MasterData/VendorMatchingType/ActiveForPr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeActiveForPrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeActiveForPrGet$Response(params?: ApiMasterDataVendorMatchingTypeActiveForPrGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>> {
    return apiMasterDataVendorMatchingTypeActiveForPrGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeActiveForPrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeActiveForPrGet(params?: ApiMasterDataVendorMatchingTypeActiveForPrGet$Params, context?: HttpContext): Observable<Array<MasterVendorMatchingTypeItem>> {
    return this.apiMasterDataVendorMatchingTypeActiveForPrGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>): Array<MasterVendorMatchingTypeItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeMaintainGet()` */
  static readonly ApiMasterDataVendorMatchingTypeMaintainGetPath = '/api/MasterData/VendorMatchingType/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeMaintainGet$Response(params?: ApiMasterDataVendorMatchingTypeMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>> {
    return apiMasterDataVendorMatchingTypeMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeMaintainGet(params?: ApiMasterDataVendorMatchingTypeMaintainGet$Params, context?: HttpContext): Observable<Array<MasterVendorMatchingTypeItem>> {
    return this.apiMasterDataVendorMatchingTypeMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>): Array<MasterVendorMatchingTypeItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeAllGet()` */
  static readonly ApiMasterDataVendorMatchingTypeAllGetPath = '/api/MasterData/VendorMatchingType/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeAllGet$Response(params?: ApiMasterDataVendorMatchingTypeAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>> {
    return apiMasterDataVendorMatchingTypeAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeAllGet(params?: ApiMasterDataVendorMatchingTypeAllGet$Params, context?: HttpContext): Observable<Array<MasterVendorMatchingTypeItem>> {
    return this.apiMasterDataVendorMatchingTypeAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterVendorMatchingTypeItem>>): Array<MasterVendorMatchingTypeItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeAddPost()` */
  static readonly ApiMasterDataVendorMatchingTypeAddPostPath = '/api/MasterData/VendorMatchingType/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorMatchingTypeAddPost$Response(params?: ApiMasterDataVendorMatchingTypeAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataVendorMatchingTypeAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorMatchingTypeAddPost(params?: ApiMasterDataVendorMatchingTypeAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataVendorMatchingTypeAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeEditPost()` */
  static readonly ApiMasterDataVendorMatchingTypeEditPostPath = '/api/MasterData/VendorMatchingType/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorMatchingTypeEditPost$Response(params?: ApiMasterDataVendorMatchingTypeEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataVendorMatchingTypeEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataVendorMatchingTypeEditPost(params?: ApiMasterDataVendorMatchingTypeEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataVendorMatchingTypeEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataVendorMatchingTypeDeleteGet()` */
  static readonly ApiMasterDataVendorMatchingTypeDeleteGetPath = '/api/MasterData/VendorMatchingType/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataVendorMatchingTypeDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeDeleteGet$Response(params?: ApiMasterDataVendorMatchingTypeDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataVendorMatchingTypeDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataVendorMatchingTypeDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataVendorMatchingTypeDeleteGet(params?: ApiMasterDataVendorMatchingTypeDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataVendorMatchingTypeDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
