/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiEmailSendingSendEmailPost } from '../fn/email-sending/api-email-sending-send-email-post';
import { ApiEmailSendingSendEmailPost$Params } from '../fn/email-sending/api-email-sending-send-email-post';
import { ExtEmailNotifyItemRes } from '../models/ext-email-notify-item-res';

@Injectable({ providedIn: 'root' })
export class EmailSendingAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiEmailSendingSendEmailPost()` */
  static readonly ApiEmailSendingSendEmailPostPath = '/api/email-sending/SendEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailSendingSendEmailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmailSendingSendEmailPost$Response(params?: ApiEmailSendingSendEmailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExtEmailNotifyItemRes>> {
    return apiEmailSendingSendEmailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmailSendingSendEmailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiEmailSendingSendEmailPost(params?: ApiEmailSendingSendEmailPost$Params, context?: HttpContext): Observable<ExtEmailNotifyItemRes> {
    return this.apiEmailSendingSendEmailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExtEmailNotifyItemRes>): ExtEmailNotifyItemRes => r.body)
    );
  }

}
