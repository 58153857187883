/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiWorkstationContractGetContractAmendmentPost } from '../fn/contract-workstation/api-workstation-contract-get-contract-amendment-post';
import { ApiWorkstationContractGetContractAmendmentPost$Params } from '../fn/contract-workstation/api-workstation-contract-get-contract-amendment-post';
import { apiWorkstationContractGetContractRenewalPost } from '../fn/contract-workstation/api-workstation-contract-get-contract-renewal-post';
import { ApiWorkstationContractGetContractRenewalPost$Params } from '../fn/contract-workstation/api-workstation-contract-get-contract-renewal-post';
import { WorkstationContractAmendmentRequestResultItem } from '../models/workstation-contract-amendment-request-result-item';

@Injectable({ providedIn: 'root' })
export class ContractWorkstationAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiWorkstationContractGetContractAmendmentPost()` */
  static readonly ApiWorkstationContractGetContractAmendmentPostPath = '/api/workstation/contract/GetContractAmendment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkstationContractGetContractAmendmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWorkstationContractGetContractAmendmentPost$Response(params?: ApiWorkstationContractGetContractAmendmentPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<WorkstationContractAmendmentRequestResultItem>>> {
    return apiWorkstationContractGetContractAmendmentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWorkstationContractGetContractAmendmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWorkstationContractGetContractAmendmentPost(params?: ApiWorkstationContractGetContractAmendmentPost$Params, context?: HttpContext): Observable<Array<WorkstationContractAmendmentRequestResultItem>> {
    return this.apiWorkstationContractGetContractAmendmentPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<WorkstationContractAmendmentRequestResultItem>>): Array<WorkstationContractAmendmentRequestResultItem> => r.body)
    );
  }

  /** Path part for operation `apiWorkstationContractGetContractRenewalPost()` */
  static readonly ApiWorkstationContractGetContractRenewalPostPath = '/api/workstation/contract/GetContractRenewal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkstationContractGetContractRenewalPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWorkstationContractGetContractRenewalPost$Response(params?: ApiWorkstationContractGetContractRenewalPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<WorkstationContractAmendmentRequestResultItem>>> {
    return apiWorkstationContractGetContractRenewalPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWorkstationContractGetContractRenewalPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWorkstationContractGetContractRenewalPost(params?: ApiWorkstationContractGetContractRenewalPost$Params, context?: HttpContext): Observable<Array<WorkstationContractAmendmentRequestResultItem>> {
    return this.apiWorkstationContractGetContractRenewalPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<WorkstationContractAmendmentRequestResultItem>>): Array<WorkstationContractAmendmentRequestResultItem> => r.body)
    );
  }

}
