/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { ContractWorksheetItemModel } from '../../models/contract-worksheet-item-model';

export interface ApiRequestContractWorksheetImportItemAdminPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestContractWorksheetImportItemAdminPost(http: HttpClient, rootUrl: string, params?: ApiRequestContractWorksheetImportItemAdminPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestContractWorksheetImportItemAdminPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>;
    })
  );
}

apiRequestContractWorksheetImportItemAdminPost.PATH = '/api/request/contract-worksheet/ImportItemAdmin';
